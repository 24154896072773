@font-face {
  font-family: Favorit;
  src: url("ABCFavoritArabicVariable.7041dfdf.woff2") format("woff2"), url("ABCFavoritArabicVariable.e88fdb16.woff") format("woff");
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .file-cta, .file-name, .select select, .textarea, .input, .button {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  border: 1px solid #0000;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  height: 2.5em;
  padding: calc(.5em - 1px) calc(.75em - 1px);
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus, .pagination-ellipsis:focus, .file-cta:focus, .file-name:focus, .select select:focus, .textarea:focus, .input:focus, .button:focus, .is-focused.pagination-previous, .is-focused.pagination-next, .is-focused.pagination-link, .is-focused.pagination-ellipsis, .is-focused.file-cta, .is-focused.file-name, .select select.is-focused, .is-focused.textarea, .is-focused.input, .is-focused.button, .pagination-previous:active, .pagination-next:active, .pagination-link:active, .pagination-ellipsis:active, .file-cta:active, .file-name:active, .select select:active, .textarea:active, .input:active, .button:active, .is-active.pagination-previous, .is-active.pagination-next, .is-active.pagination-link, .is-active.pagination-ellipsis, .is-active.file-cta, .is-active.file-name, .select select.is-active, .is-active.textarea, .is-active.input, .is-active.button {
  outline: none;
}

[disabled].pagination-previous, [disabled].pagination-next, [disabled].pagination-link, [disabled].pagination-ellipsis, [disabled].file-cta, [disabled].file-name, .select select[disabled], [disabled].textarea, [disabled].input, [disabled].button, fieldset[disabled] .pagination-previous, fieldset[disabled] .pagination-next, fieldset[disabled] .pagination-link, fieldset[disabled] .pagination-ellipsis, fieldset[disabled] .file-cta, fieldset[disabled] .file-name, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input, fieldset[disabled] .button {
  cursor: not-allowed;
}

.is-unselectable, .tabs, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .breadcrumb, .file, .button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.navbar-link:not(.is-arrowless):after, .select:not(.is-multiple):not(.is-loading):after {
  content: " ";
  pointer-events: none;
  transform-origin: center;
  border-top: 0;
  border-bottom: 3px solid #0000;
  border-left: 3px solid #0000;
  border-right: 0;
  border-radius: 2px;
  width: .625em;
  height: .625em;
  margin-top: -.4375em;
  display: block;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
}

.tabs:not(:last-child), .pagination:not(:last-child), .message:not(:last-child), .level:not(:last-child), .breadcrumb:not(:last-child), .block:not(:last-child), .title:not(:last-child), .subtitle:not(:last-child), .table-container:not(:last-child), .table:not(:last-child), .progress:not(:last-child), .notification:not(:last-child), .content:not(:last-child), .box:not(:last-child) {
  margin-bottom: 1.5rem;
}

.modal-close, .delete {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  pointer-events: auto;
  vertical-align: top;
  background-color: #0003;
  border: none;
  border-radius: 9999px;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.modal-close:before, .delete:before, .modal-close:after, .delete:after {
  content: "";
  transform-origin: center;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.modal-close:before, .delete:before {
  width: 50%;
  height: 2px;
}

.modal-close:after, .delete:after {
  width: 2px;
  height: 50%;
}

.modal-close:hover, .delete:hover, .modal-close:focus, .delete:focus {
  background-color: #0000004d;
}

.modal-close:active, .delete:active {
  background-color: #0006;
}

.is-small.modal-close, .is-small.delete {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.is-medium.modal-close, .is-medium.delete {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.is-large.modal-close, .is-large.delete {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.control.is-loading:after, .select.is-loading:after, .loader, .button.is-loading:after {
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #0000 #dbdbdb #dbdbdb;
  border-radius: 9999px;
  width: 1em;
  height: 1em;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.hero-video, .is-overlay, .modal-background, .modal, .image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio {
  position: absolute;
  inset: 0;
}

.navbar-burger {
  appearance: none;
  color: currentColor;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1em;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: inherit;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fff;
  min-width: 300px;
  font-size: 20px;
  overflow: hidden scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, optgroup, select, textarea {
  font-family: Favorit, serif;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #485fc7;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #363636;
}

code {
  color: #da1039;
  background-color: #f5f5f5;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  background-color: #f5f5f5;
  border: none;
  height: 2px;
  margin: 1.5rem 0;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #363636;
  font-weight: 700;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #4a4a4a;
  white-space: pre;
  word-wrap: normal;
  background-color: #f5f5f5;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

table th {
  color: #363636;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.box {
  color: #4a4a4a;
  background-color: #fff;
  border-radius: 6px;
  padding: 1.25rem;
  display: block;
  box-shadow: 0 .5em 1em -.125em #0000001a, 0 0 0 1px #00000005;
}

a.box:hover, a.box:focus {
  box-shadow: 0 .5em 1em -.125em #0000001a, 0 0 0 1px #485fc7;
}

a.box:active {
  box-shadow: inset 0 1px 2px #0003, 0 0 0 1px #485fc7;
}

.button {
  color: #363636;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-width: 1px;
  border-color: #dbdbdb;
  justify-content: center;
  padding: calc(.5em - 1px) 1em;
}

.button strong {
  color: inherit;
}

.button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
  width: 1.5em;
  height: 1.5em;
}

.button .icon:first-child:not(:last-child) {
  margin-left: calc(-.5em - 1px);
  margin-right: .25em;
}

.button .icon:last-child:not(:first-child) {
  margin-left: .25em;
  margin-right: calc(-.5em - 1px);
}

.button .icon:first-child:last-child {
  margin-left: calc(-.5em - 1px);
  margin-right: calc(-.5em - 1px);
}

.button:hover, .button.is-hovered {
  color: #363636;
  border-color: #b5b5b5;
}

.button:focus, .button.is-focused {
  color: #363636;
  border-color: #485fc7;
}

.button:focus:not(:active), .button.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #485fc740;
}

.button:active, .button.is-active {
  color: #363636;
  border-color: #4a4a4a;
}

.button.is-text {
  color: #4a4a4a;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.button.is-text:hover, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text.is-focused {
  color: #363636;
  background-color: #f5f5f5;
}

.button.is-text:active, .button.is-text.is-active {
  color: #363636;
  background-color: #e8e8e8;
}

.button.is-text[disabled], fieldset[disabled] .button.is-text {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.button.is-ghost {
  color: #485fc7;
  background: none;
  border-color: #0000;
  text-decoration: none;
}

.button.is-ghost:hover, .button.is-ghost.is-hovered {
  color: #485fc7;
  text-decoration: underline;
}

.button.is-white {
  color: #000;
  background-color: #fff;
  border-color: #0000;
}

.button.is-white:hover, .button.is-white.is-hovered {
  color: #000;
  background-color: #f9f9f9;
  border-color: #0000;
}

.button.is-white:focus, .button.is-white.is-focused {
  color: #000;
  border-color: #0000;
}

.button.is-white:focus:not(:active), .button.is-white.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.button.is-white:active, .button.is-white.is-active {
  color: #000;
  background-color: #f2f2f2;
  border-color: #0000;
}

.button.is-white[disabled], fieldset[disabled] .button.is-white {
  box-shadow: none;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-inverted {
  color: #fff;
  background-color: #000;
}

.button.is-white.is-inverted:hover, .button.is-white.is-inverted.is-hovered {
  background-color: #000;
}

.button.is-white.is-inverted[disabled], fieldset[disabled] .button.is-white.is-inverted {
  box-shadow: none;
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-white.is-loading:after {
  border-color: #0000 #0000 #000 #000 !important;
}

.button.is-white.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-outlined:hover, .button.is-white.is-outlined.is-hovered, .button.is-white.is-outlined:focus, .button.is-white.is-outlined.is-focused {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-outlined.is-loading:hover:after, .button.is-white.is-outlined.is-loading.is-hovered:after, .button.is-white.is-outlined.is-loading:focus:after, .button.is-white.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000 #000 !important;
}

.button.is-white.is-outlined[disabled], fieldset[disabled] .button.is-white.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-inverted.is-outlined {
  color: #000;
  background-color: #0000;
  border-color: #000;
}

.button.is-white.is-inverted.is-outlined:hover, .button.is-white.is-inverted.is-outlined.is-hovered, .button.is-white.is-inverted.is-outlined:focus, .button.is-white.is-inverted.is-outlined.is-focused {
  color: #fff;
  background-color: #000;
}

.button.is-white.is-inverted.is-outlined.is-loading:hover:after, .button.is-white.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-white.is-inverted.is-outlined.is-loading:focus:after, .button.is-white.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-white.is-inverted.is-outlined {
  box-shadow: none;
  color: #000;
  background-color: #0000;
  border-color: #000;
}

.button.is-black, .button.is-black:hover, .button.is-black.is-hovered {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-black:focus, .button.is-black.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-black:focus:not(:active), .button.is-black.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #00000040;
}

.button.is-black:active, .button.is-black.is-active {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-black[disabled], fieldset[disabled] .button.is-black {
  box-shadow: none;
  background-color: #000;
  border-color: #000;
}

.button.is-black.is-inverted {
  color: #000;
  background-color: #fff;
}

.button.is-black.is-inverted:hover, .button.is-black.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-black.is-inverted[disabled], fieldset[disabled] .button.is-black.is-inverted {
  box-shadow: none;
  color: #000;
  background-color: #fff;
  border-color: #0000;
}

.button.is-black.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined {
  color: #000;
  background-color: #0000;
  border-color: #000;
}

.button.is-black.is-outlined:hover, .button.is-black.is-outlined.is-hovered, .button.is-black.is-outlined:focus, .button.is-black.is-outlined.is-focused {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.button.is-black.is-outlined.is-loading:after {
  border-color: #0000 #0000 #000 #000 !important;
}

.button.is-black.is-outlined.is-loading:hover:after, .button.is-black.is-outlined.is-loading.is-hovered:after, .button.is-black.is-outlined.is-loading:focus:after, .button.is-black.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined[disabled], fieldset[disabled] .button.is-black.is-outlined {
  box-shadow: none;
  color: #000;
  background-color: #0000;
  border-color: #000;
}

.button.is-black.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-black.is-inverted.is-outlined:hover, .button.is-black.is-inverted.is-outlined.is-hovered, .button.is-black.is-inverted.is-outlined:focus, .button.is-black.is-inverted.is-outlined.is-focused {
  color: #000;
  background-color: #fff;
}

.button.is-black.is-inverted.is-outlined.is-loading:hover:after, .button.is-black.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-black.is-inverted.is-outlined.is-loading:focus:after, .button.is-black.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000 #000 !important;
}

.button.is-black.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-black.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #0000;
}

.button.is-light:hover, .button.is-light.is-hovered {
  color: #000000b3;
  background-color: #eee;
  border-color: #0000;
}

.button.is-light:focus, .button.is-light.is-focused {
  color: #000000b3;
  border-color: #0000;
}

.button.is-light:focus:not(:active), .button.is-light.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.button.is-light:active, .button.is-light.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
  border-color: #0000;
}

.button.is-light[disabled], fieldset[disabled] .button.is-light {
  box-shadow: none;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button.is-light.is-inverted {
  color: #f5f5f5;
  background-color: #000000b3;
}

.button.is-light.is-inverted:hover, .button.is-light.is-inverted.is-hovered {
  background-color: #000000b3;
}

.button.is-light.is-inverted[disabled], fieldset[disabled] .button.is-light.is-inverted {
  box-shadow: none;
  color: #f5f5f5;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-light.is-loading:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined {
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-light.is-outlined:hover, .button.is-light.is-outlined.is-hovered, .button.is-light.is-outlined:focus, .button.is-light.is-outlined.is-focused {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.button.is-light.is-outlined.is-loading:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-outlined.is-loading:hover:after, .button.is-light.is-outlined.is-loading.is-hovered:after, .button.is-light.is-outlined.is-loading:focus:after, .button.is-light.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined[disabled], fieldset[disabled] .button.is-light.is-outlined {
  box-shadow: none;
  color: #f5f5f5;
  background-color: #0000;
  border-color: #f5f5f5;
}

.button.is-light.is-inverted.is-outlined {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined:hover, .button.is-light.is-inverted.is-outlined.is-hovered, .button.is-light.is-inverted.is-outlined:focus, .button.is-light.is-inverted.is-outlined.is-focused {
  color: #f5f5f5;
  background-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined.is-loading:hover:after, .button.is-light.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-light.is-inverted.is-outlined.is-loading:focus:after, .button.is-light.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #f5f5f5 #f5f5f5 !important;
}

.button.is-light.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-light.is-inverted.is-outlined {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-dark {
  color: #fff;
  background-color: #363636;
  border-color: #0000;
}

.button.is-dark:hover, .button.is-dark.is-hovered {
  color: #fff;
  background-color: #2f2f2f;
  border-color: #0000;
}

.button.is-dark:focus, .button.is-dark.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-dark:focus:not(:active), .button.is-dark.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #36363640;
}

.button.is-dark:active, .button.is-dark.is-active {
  color: #fff;
  background-color: #292929;
  border-color: #0000;
}

.button.is-dark[disabled], fieldset[disabled] .button.is-dark {
  box-shadow: none;
  background-color: #363636;
  border-color: #363636;
}

.button.is-dark.is-inverted {
  color: #363636;
  background-color: #fff;
}

.button.is-dark.is-inverted:hover, .button.is-dark.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-dark.is-inverted[disabled], fieldset[disabled] .button.is-dark.is-inverted {
  box-shadow: none;
  color: #363636;
  background-color: #fff;
  border-color: #0000;
}

.button.is-dark.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined {
  color: #363636;
  background-color: #0000;
  border-color: #363636;
}

.button.is-dark.is-outlined:hover, .button.is-dark.is-outlined.is-hovered, .button.is-dark.is-outlined:focus, .button.is-dark.is-outlined.is-focused {
  color: #fff;
  background-color: #363636;
  border-color: #363636;
}

.button.is-dark.is-outlined.is-loading:after {
  border-color: #0000 #0000 #363636 #363636 !important;
}

.button.is-dark.is-outlined.is-loading:hover:after, .button.is-dark.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-outlined.is-loading:focus:after, .button.is-dark.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-outlined {
  box-shadow: none;
  color: #363636;
  background-color: #0000;
  border-color: #363636;
}

.button.is-dark.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-dark.is-inverted.is-outlined:hover, .button.is-dark.is-inverted.is-outlined.is-hovered, .button.is-dark.is-inverted.is-outlined:focus, .button.is-dark.is-inverted.is-outlined.is-focused {
  color: #363636;
  background-color: #fff;
}

.button.is-dark.is-inverted.is-outlined.is-loading:hover:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-inverted.is-outlined.is-loading:focus:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #363636 #363636 !important;
}

.button.is-dark.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary {
  color: #fff;
  background-color: #00d1b2;
  border-color: #0000;
}

.button.is-primary:hover, .button.is-primary.is-hovered {
  color: #fff;
  background-color: #00c4a7;
  border-color: #0000;
}

.button.is-primary:focus, .button.is-primary.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #00d1b240;
}

.button.is-primary:active, .button.is-primary.is-active {
  color: #fff;
  background-color: #00b89c;
  border-color: #0000;
}

.button.is-primary[disabled], fieldset[disabled] .button.is-primary {
  box-shadow: none;
  background-color: #00d1b2;
  border-color: #00d1b2;
}

.button.is-primary.is-inverted {
  color: #00d1b2;
  background-color: #fff;
}

.button.is-primary.is-inverted:hover, .button.is-primary.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-primary.is-inverted[disabled], fieldset[disabled] .button.is-primary.is-inverted {
  box-shadow: none;
  color: #00d1b2;
  background-color: #fff;
  border-color: #0000;
}

.button.is-primary.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined {
  color: #00d1b2;
  background-color: #0000;
  border-color: #00d1b2;
}

.button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused {
  color: #fff;
  background-color: #00d1b2;
  border-color: #00d1b2;
}

.button.is-primary.is-outlined.is-loading:after {
  border-color: #0000 #0000 #00d1b2 #00d1b2 !important;
}

.button.is-primary.is-outlined.is-loading:hover:after, .button.is-primary.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-outlined.is-loading:focus:after, .button.is-primary.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-outlined {
  box-shadow: none;
  color: #00d1b2;
  background-color: #0000;
  border-color: #00d1b2;
}

.button.is-primary.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-inverted.is-outlined:hover, .button.is-primary.is-inverted.is-outlined.is-hovered, .button.is-primary.is-inverted.is-outlined:focus, .button.is-primary.is-inverted.is-outlined.is-focused {
  color: #00d1b2;
  background-color: #fff;
}

.button.is-primary.is-inverted.is-outlined.is-loading:hover:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-inverted.is-outlined.is-loading:focus:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #00d1b2 #00d1b2 !important;
}

.button.is-primary.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-light {
  color: #00947e;
  background-color: #ebfffc;
}

.button.is-primary.is-light:hover, .button.is-primary.is-light.is-hovered {
  color: #00947e;
  background-color: #defffa;
  border-color: #0000;
}

.button.is-primary.is-light:active, .button.is-primary.is-light.is-active {
  color: #00947e;
  background-color: #d1fff8;
  border-color: #0000;
}

.button.is-link {
  color: #fff;
  background-color: #485fc7;
  border-color: #0000;
}

.button.is-link:hover, .button.is-link.is-hovered {
  color: #fff;
  background-color: #3e56c4;
  border-color: #0000;
}

.button.is-link:focus, .button.is-link.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-link:focus:not(:active), .button.is-link.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #485fc740;
}

.button.is-link:active, .button.is-link.is-active {
  color: #fff;
  background-color: #3a51bb;
  border-color: #0000;
}

.button.is-link[disabled], fieldset[disabled] .button.is-link {
  box-shadow: none;
  background-color: #485fc7;
  border-color: #485fc7;
}

.button.is-link.is-inverted {
  color: #485fc7;
  background-color: #fff;
}

.button.is-link.is-inverted:hover, .button.is-link.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-link.is-inverted[disabled], fieldset[disabled] .button.is-link.is-inverted {
  box-shadow: none;
  color: #485fc7;
  background-color: #fff;
  border-color: #0000;
}

.button.is-link.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined {
  color: #485fc7;
  background-color: #0000;
  border-color: #485fc7;
}

.button.is-link.is-outlined:hover, .button.is-link.is-outlined.is-hovered, .button.is-link.is-outlined:focus, .button.is-link.is-outlined.is-focused {
  color: #fff;
  background-color: #485fc7;
  border-color: #485fc7;
}

.button.is-link.is-outlined.is-loading:after {
  border-color: #0000 #0000 #485fc7 #485fc7 !important;
}

.button.is-link.is-outlined.is-loading:hover:after, .button.is-link.is-outlined.is-loading.is-hovered:after, .button.is-link.is-outlined.is-loading:focus:after, .button.is-link.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined[disabled], fieldset[disabled] .button.is-link.is-outlined {
  box-shadow: none;
  color: #485fc7;
  background-color: #0000;
  border-color: #485fc7;
}

.button.is-link.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-inverted.is-outlined:hover, .button.is-link.is-inverted.is-outlined.is-hovered, .button.is-link.is-inverted.is-outlined:focus, .button.is-link.is-inverted.is-outlined.is-focused {
  color: #485fc7;
  background-color: #fff;
}

.button.is-link.is-inverted.is-outlined.is-loading:hover:after, .button.is-link.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-link.is-inverted.is-outlined.is-loading:focus:after, .button.is-link.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #485fc7 #485fc7 !important;
}

.button.is-link.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-link.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-light {
  color: #3850b7;
  background-color: #eff1fa;
}

.button.is-link.is-light:hover, .button.is-link.is-light.is-hovered {
  color: #3850b7;
  background-color: #e6e9f7;
  border-color: #0000;
}

.button.is-link.is-light:active, .button.is-link.is-light.is-active {
  color: #3850b7;
  background-color: #dce0f4;
  border-color: #0000;
}

.button.is-info {
  color: #fff;
  background-color: #3e8ed0;
  border-color: #0000;
}

.button.is-info:hover, .button.is-info.is-hovered {
  color: #fff;
  background-color: #3488ce;
  border-color: #0000;
}

.button.is-info:focus, .button.is-info.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-info:focus:not(:active), .button.is-info.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #3e8ed040;
}

.button.is-info:active, .button.is-info.is-active {
  color: #fff;
  background-color: #3082c5;
  border-color: #0000;
}

.button.is-info[disabled], fieldset[disabled] .button.is-info {
  box-shadow: none;
  background-color: #3e8ed0;
  border-color: #3e8ed0;
}

.button.is-info.is-inverted {
  color: #3e8ed0;
  background-color: #fff;
}

.button.is-info.is-inverted:hover, .button.is-info.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-info.is-inverted[disabled], fieldset[disabled] .button.is-info.is-inverted {
  box-shadow: none;
  color: #3e8ed0;
  background-color: #fff;
  border-color: #0000;
}

.button.is-info.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined {
  color: #3e8ed0;
  background-color: #0000;
  border-color: #3e8ed0;
}

.button.is-info.is-outlined:hover, .button.is-info.is-outlined.is-hovered, .button.is-info.is-outlined:focus, .button.is-info.is-outlined.is-focused {
  color: #fff;
  background-color: #3e8ed0;
  border-color: #3e8ed0;
}

.button.is-info.is-outlined.is-loading:after {
  border-color: #0000 #0000 #3e8ed0 #3e8ed0 !important;
}

.button.is-info.is-outlined.is-loading:hover:after, .button.is-info.is-outlined.is-loading.is-hovered:after, .button.is-info.is-outlined.is-loading:focus:after, .button.is-info.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined[disabled], fieldset[disabled] .button.is-info.is-outlined {
  box-shadow: none;
  color: #3e8ed0;
  background-color: #0000;
  border-color: #3e8ed0;
}

.button.is-info.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-inverted.is-outlined:hover, .button.is-info.is-inverted.is-outlined.is-hovered, .button.is-info.is-inverted.is-outlined:focus, .button.is-info.is-inverted.is-outlined.is-focused {
  color: #3e8ed0;
  background-color: #fff;
}

.button.is-info.is-inverted.is-outlined.is-loading:hover:after, .button.is-info.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-info.is-inverted.is-outlined.is-loading:focus:after, .button.is-info.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #3e8ed0 #3e8ed0 !important;
}

.button.is-info.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-info.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-light {
  color: #296fa8;
  background-color: #eff5fb;
}

.button.is-info.is-light:hover, .button.is-info.is-light.is-hovered {
  color: #296fa8;
  background-color: #e4eff9;
  border-color: #0000;
}

.button.is-info.is-light:active, .button.is-info.is-light.is-active {
  color: #296fa8;
  background-color: #dae9f6;
  border-color: #0000;
}

.button.is-success {
  color: #fff;
  background-color: #48c78e;
  border-color: #0000;
}

.button.is-success:hover, .button.is-success.is-hovered {
  color: #fff;
  background-color: #3ec487;
  border-color: #0000;
}

.button.is-success:focus, .button.is-success.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-success:focus:not(:active), .button.is-success.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #48c78e40;
}

.button.is-success:active, .button.is-success.is-active {
  color: #fff;
  background-color: #3abb81;
  border-color: #0000;
}

.button.is-success[disabled], fieldset[disabled] .button.is-success {
  box-shadow: none;
  background-color: #48c78e;
  border-color: #48c78e;
}

.button.is-success.is-inverted {
  color: #48c78e;
  background-color: #fff;
}

.button.is-success.is-inverted:hover, .button.is-success.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-success.is-inverted[disabled], fieldset[disabled] .button.is-success.is-inverted {
  box-shadow: none;
  color: #48c78e;
  background-color: #fff;
  border-color: #0000;
}

.button.is-success.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined {
  color: #48c78e;
  background-color: #0000;
  border-color: #48c78e;
}

.button.is-success.is-outlined:hover, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined.is-focused {
  color: #fff;
  background-color: #48c78e;
  border-color: #48c78e;
}

.button.is-success.is-outlined.is-loading:after {
  border-color: #0000 #0000 #48c78e #48c78e !important;
}

.button.is-success.is-outlined.is-loading:hover:after, .button.is-success.is-outlined.is-loading.is-hovered:after, .button.is-success.is-outlined.is-loading:focus:after, .button.is-success.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined[disabled], fieldset[disabled] .button.is-success.is-outlined {
  box-shadow: none;
  color: #48c78e;
  background-color: #0000;
  border-color: #48c78e;
}

.button.is-success.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success.is-inverted.is-outlined:hover, .button.is-success.is-inverted.is-outlined.is-hovered, .button.is-success.is-inverted.is-outlined:focus, .button.is-success.is-inverted.is-outlined.is-focused {
  color: #48c78e;
  background-color: #fff;
}

.button.is-success.is-inverted.is-outlined.is-loading:hover:after, .button.is-success.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-success.is-inverted.is-outlined.is-loading:focus:after, .button.is-success.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #48c78e #48c78e !important;
}

.button.is-success.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-success.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success.is-light {
  color: #257953;
  background-color: #effaf5;
}

.button.is-success.is-light:hover, .button.is-success.is-light.is-hovered {
  color: #257953;
  background-color: #e6f7ef;
  border-color: #0000;
}

.button.is-success.is-light:active, .button.is-success.is-light.is-active {
  color: #257953;
  background-color: #dcf4e9;
  border-color: #0000;
}

.button.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #0000;
}

.button.is-warning:hover, .button.is-warning.is-hovered {
  color: #000000b3;
  background-color: #ffdc7d;
  border-color: #0000;
}

.button.is-warning:focus, .button.is-warning.is-focused {
  color: #000000b3;
  border-color: #0000;
}

.button.is-warning:focus:not(:active), .button.is-warning.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #ffe08a40;
}

.button.is-warning:active, .button.is-warning.is-active {
  color: #000000b3;
  background-color: #ffd970;
  border-color: #0000;
}

.button.is-warning[disabled], fieldset[disabled] .button.is-warning {
  box-shadow: none;
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.button.is-warning.is-inverted {
  color: #ffe08a;
  background-color: #000000b3;
}

.button.is-warning.is-inverted:hover, .button.is-warning.is-inverted.is-hovered {
  background-color: #000000b3;
}

.button.is-warning.is-inverted[disabled], fieldset[disabled] .button.is-warning.is-inverted {
  box-shadow: none;
  color: #ffe08a;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-warning.is-loading:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined {
  color: #ffe08a;
  background-color: #0000;
  border-color: #ffe08a;
}

.button.is-warning.is-outlined:hover, .button.is-warning.is-outlined.is-hovered, .button.is-warning.is-outlined:focus, .button.is-warning.is-outlined.is-focused {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.button.is-warning.is-outlined.is-loading:after {
  border-color: #0000 #0000 #ffe08a #ffe08a !important;
}

.button.is-warning.is-outlined.is-loading:hover:after, .button.is-warning.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-outlined.is-loading:focus:after, .button.is-warning.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-warning.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-outlined {
  box-shadow: none;
  color: #ffe08a;
  background-color: #0000;
  border-color: #ffe08a;
}

.button.is-warning.is-inverted.is-outlined {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined:hover, .button.is-warning.is-inverted.is-outlined.is-hovered, .button.is-warning.is-inverted.is-outlined:focus, .button.is-warning.is-inverted.is-outlined.is-focused {
  color: #ffe08a;
  background-color: #000000b3;
}

.button.is-warning.is-inverted.is-outlined.is-loading:hover:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-inverted.is-outlined.is-loading:focus:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #ffe08a #ffe08a !important;
}

.button.is-warning.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-inverted.is-outlined {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.button.is-warning.is-light:hover, .button.is-warning.is-light.is-hovered {
  color: #946c00;
  background-color: #fff6de;
  border-color: #0000;
}

.button.is-warning.is-light:active, .button.is-warning.is-light.is-active {
  color: #946c00;
  background-color: #fff3d1;
  border-color: #0000;
}

.button.is-danger {
  color: #fff;
  background-color: #f14668;
  border-color: #0000;
}

.button.is-danger:hover, .button.is-danger.is-hovered {
  color: #fff;
  background-color: #f03a5f;
  border-color: #0000;
}

.button.is-danger:focus, .button.is-danger.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-danger:focus:not(:active), .button.is-danger.is-focused:not(:active) {
  box-shadow: 0 0 0 .125em #f1466840;
}

.button.is-danger:active, .button.is-danger.is-active {
  color: #fff;
  background-color: #ef2e55;
  border-color: #0000;
}

.button.is-danger[disabled], fieldset[disabled] .button.is-danger {
  box-shadow: none;
  background-color: #f14668;
  border-color: #f14668;
}

.button.is-danger.is-inverted {
  color: #f14668;
  background-color: #fff;
}

.button.is-danger.is-inverted:hover, .button.is-danger.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-danger.is-inverted[disabled], fieldset[disabled] .button.is-danger.is-inverted {
  box-shadow: none;
  color: #f14668;
  background-color: #fff;
  border-color: #0000;
}

.button.is-danger.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined {
  color: #f14668;
  background-color: #0000;
  border-color: #f14668;
}

.button.is-danger.is-outlined:hover, .button.is-danger.is-outlined.is-hovered, .button.is-danger.is-outlined:focus, .button.is-danger.is-outlined.is-focused {
  color: #fff;
  background-color: #f14668;
  border-color: #f14668;
}

.button.is-danger.is-outlined.is-loading:after {
  border-color: #0000 #0000 #f14668 #f14668 !important;
}

.button.is-danger.is-outlined.is-loading:hover:after, .button.is-danger.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-outlined.is-loading:focus:after, .button.is-danger.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-outlined {
  box-shadow: none;
  color: #f14668;
  background-color: #0000;
  border-color: #f14668;
}

.button.is-danger.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-inverted.is-outlined:hover, .button.is-danger.is-inverted.is-outlined.is-hovered, .button.is-danger.is-inverted.is-outlined:focus, .button.is-danger.is-inverted.is-outlined.is-focused {
  color: #f14668;
  background-color: #fff;
}

.button.is-danger.is-inverted.is-outlined.is-loading:hover:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-inverted.is-outlined.is-loading:focus:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #f14668 #f14668 !important;
}

.button.is-danger.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-light {
  color: #cc0f35;
  background-color: #feecf0;
}

.button.is-danger.is-light:hover, .button.is-danger.is-light.is-hovered {
  color: #cc0f35;
  background-color: #fde0e6;
  border-color: #0000;
}

.button.is-danger.is-light:active, .button.is-danger.is-light.is-active {
  color: #cc0f35;
  background-color: #fcd4dc;
  border-color: #0000;
}

.button.is-small {
  font-size: .75rem;
}

.button.is-small:not(.is-rounded) {
  border-radius: 2px;
}

.button.is-normal {
  font-size: 1rem;
}

.button.is-medium {
  font-size: 1.25rem;
}

.button.is-large {
  font-size: 1.5rem;
}

.button[disabled], fieldset[disabled] .button {
  box-shadow: none;
  opacity: .5;
  background-color: #fff;
  border-color: #dbdbdb;
}

.button.is-fullwidth {
  width: 100%;
  display: flex;
}

.button.is-loading {
  pointer-events: none;
  color: #0000 !important;
}

.button.is-loading:after {
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  position: absolute !important;
}

.button.is-static {
  color: #7a7a7a;
  box-shadow: none;
  pointer-events: none;
  background-color: #f5f5f5;
  border-color: #dbdbdb;
}

.button.is-rounded {
  border-radius: 9999px;
  padding-left: 1.25em;
  padding-right: 1.25em;
}

.buttons {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.buttons .button {
  margin-bottom: .5rem;
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
  margin-right: .5rem;
}

.buttons:last-child {
  margin-bottom: -.5rem;
}

.buttons:not(:last-child) {
  margin-bottom: 1rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large) {
  font-size: .75rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large):not(.is-rounded) {
  border-radius: 2px;
}

.buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large) {
  font-size: 1.25rem;
}

.buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium) {
  font-size: 1.5rem;
}

.buttons.has-addons .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttons.has-addons .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.buttons.has-addons .button:last-child {
  margin-right: 0;
}

.buttons.has-addons .button:hover, .buttons.has-addons .button.is-hovered {
  z-index: 2;
}

.buttons.has-addons .button:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .button:active, .buttons.has-addons .button.is-active, .buttons.has-addons .button.is-selected {
  z-index: 3;
}

.buttons.has-addons .button:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-selected:hover {
  z-index: 4;
}

.buttons.has-addons .button.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.buttons.is-centered {
  justify-content: center;
}

.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

.buttons.is-right {
  justify-content: flex-end;
}

.buttons.is-right:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

@media screen and (width <= 768px) {
  .button.is-responsive.is-small {
    font-size: .5625rem;
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: .65625rem;
  }

  .button.is-responsive.is-medium {
    font-size: .75rem;
  }

  .button.is-responsive.is-large {
    font-size: 1rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .button.is-responsive.is-small {
    font-size: .65625rem;
  }

  .button.is-responsive, .button.is-responsive.is-normal {
    font-size: .75rem;
  }

  .button.is-responsive.is-medium {
    font-size: 1rem;
  }

  .button.is-responsive.is-large {
    font-size: 1.25rem;
  }
}

.container {
  flex-grow: 1;
  width: auto;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

@media screen and (width >= 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (width <= 1215px) {
  .container.is-widescreen:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width <= 1407px) {
  .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

@media screen and (width >= 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (width >= 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

.content li + li {
  margin-top: .25em;
}

.content p:not(:last-child), .content dl:not(:last-child), .content ol:not(:last-child), .content ul:not(:last-child), .content blockquote:not(:last-child), .content pre:not(:last-child), .content table:not(:last-child) {
  margin-bottom: 1em;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
}

.content h1 {
  margin-bottom: .5em;
  font-size: 2em;
}

.content h1:not(:first-child) {
  margin-top: 1em;
}

.content h2 {
  margin-bottom: .5714em;
  font-size: 1.75em;
}

.content h2:not(:first-child) {
  margin-top: 1.1428em;
}

.content h3 {
  margin-bottom: .6666em;
  font-size: 1.5em;
}

.content h3:not(:first-child) {
  margin-top: 1.3333em;
}

.content h4 {
  margin-bottom: .8em;
  font-size: 1.25em;
}

.content h5 {
  margin-bottom: .8888em;
  font-size: 1.125em;
}

.content h6 {
  margin-bottom: 1em;
  font-size: 1em;
}

.content blockquote {
  background-color: #f5f5f5;
  border-left: 5px solid #dbdbdb;
  padding: 1.25em 1.5em;
}

.content ol {
  margin-top: 1em;
  margin-left: 2em;
  list-style-position: outside;
}

.content ol:not([type]) {
  list-style-type: decimal;
}

.content ol:not([type]).is-lower-alpha {
  list-style-type: lower-alpha;
}

.content ol:not([type]).is-lower-roman {
  list-style-type: lower-roman;
}

.content ol:not([type]).is-upper-alpha {
  list-style-type: upper-alpha;
}

.content ol:not([type]).is-upper-roman {
  list-style-type: upper-roman;
}

.content ul {
  margin-top: 1em;
  margin-left: 2em;
  list-style: disc;
}

.content ul ul {
  margin-top: .5em;
  list-style-type: circle;
}

.content ul ul ul {
  list-style-type: square;
}

.content dd {
  margin-left: 2em;
}

.content figure {
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

.content figure:not(:first-child) {
  margin-top: 2em;
}

.content figure:not(:last-child) {
  margin-bottom: 2em;
}

.content figure img {
  display: inline-block;
}

.content figure figcaption {
  font-style: italic;
}

.content pre {
  -webkit-overflow-scrolling: touch;
  white-space: pre;
  word-wrap: normal;
  padding: 1.25em 1.5em;
  overflow-x: auto;
}

.content sup, .content sub {
  font-size: 75%;
}

.content table {
  width: 100%;
}

.content table td, .content table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.content table th {
  color: #363636;
}

.content table th:not([align]) {
  text-align: inherit;
}

.content table thead td, .content table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.content table tfoot td, .content table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.content table tbody tr:last-child td, .content table tbody tr:last-child th {
  border-bottom-width: 0;
}

.content .tabs li + li {
  margin-top: 0;
}

.content.is-small {
  font-size: .75rem;
}

.content.is-normal {
  font-size: 1rem;
}

.content.is-medium {
  font-size: 1.25rem;
}

.content.is-large {
  font-size: 1.5rem;
}

.icon {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
}

.icon.is-small {
  width: 1rem;
  height: 1rem;
}

.icon.is-medium {
  width: 2rem;
  height: 2rem;
}

.icon.is-large {
  width: 3rem;
  height: 3rem;
}

.icon-text {
  color: inherit;
  vertical-align: top;
  flex-wrap: wrap;
  align-items: flex-start;
  line-height: 1.5rem;
  display: inline-flex;
}

.icon-text .icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.icon-text .icon:not(:last-child) {
  margin-right: .25em;
}

.icon-text .icon:not(:first-child) {
  margin-left: .25em;
}

div.icon-text {
  display: flex;
}

.image {
  display: block;
  position: relative;
}

.image img {
  width: 100%;
  height: auto;
  display: block;
}

.image img.is-rounded {
  border-radius: 9999px;
}

.image.is-fullwidth {
  width: 100%;
}

.image.is-square img, .image.is-square .has-ratio, .image.is-1by1 img, .image.is-1by1 .has-ratio, .image.is-5by4 img, .image.is-5by4 .has-ratio, .image.is-4by3 img, .image.is-4by3 .has-ratio, .image.is-3by2 img, .image.is-3by2 .has-ratio, .image.is-5by3 img, .image.is-5by3 .has-ratio, .image.is-16by9 img, .image.is-16by9 .has-ratio, .image.is-2by1 img, .image.is-2by1 .has-ratio, .image.is-3by1 img, .image.is-3by1 .has-ratio, .image.is-4by5 img, .image.is-4by5 .has-ratio, .image.is-3by4 img, .image.is-3by4 .has-ratio, .image.is-2by3 img, .image.is-2by3 .has-ratio, .image.is-3by5 img, .image.is-3by5 .has-ratio, .image.is-9by16 img, .image.is-9by16 .has-ratio, .image.is-1by2 img, .image.is-1by2 .has-ratio, .image.is-1by3 img, .image.is-1by3 .has-ratio {
  width: 100%;
  height: 100%;
}

.image.is-square, .image.is-1by1 {
  padding-top: 100%;
}

.image.is-5by4 {
  padding-top: 80%;
}

.image.is-4by3 {
  padding-top: 75%;
}

.image.is-3by2 {
  padding-top: 66.6666%;
}

.image.is-5by3 {
  padding-top: 60%;
}

.image.is-16by9 {
  padding-top: 56.25%;
}

.image.is-2by1 {
  padding-top: 50%;
}

.image.is-3by1 {
  padding-top: 33.3333%;
}

.image.is-4by5 {
  padding-top: 125%;
}

.image.is-3by4 {
  padding-top: 133.333%;
}

.image.is-2by3 {
  padding-top: 150%;
}

.image.is-3by5 {
  padding-top: 166.667%;
}

.image.is-9by16 {
  padding-top: 177.778%;
}

.image.is-1by2 {
  padding-top: 200%;
}

.image.is-1by3 {
  padding-top: 300%;
}

.image.is-16x16 {
  width: 16px;
  height: 16px;
}

.image.is-24x24 {
  width: 24px;
  height: 24px;
}

.image.is-32x32 {
  width: 32px;
  height: 32px;
}

.image.is-48x48 {
  width: 48px;
  height: 48px;
}

.image.is-64x64 {
  width: 64px;
  height: 64px;
}

.image.is-96x96 {
  width: 96px;
  height: 96px;
}

.image.is-128x128 {
  width: 128px;
  height: 128px;
}

.notification {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
}

.notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.notification strong {
  color: currentColor;
}

.notification code, .notification pre {
  background: #fff;
}

.notification pre code {
  background: none;
}

.notification > .delete {
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.notification .title, .notification .subtitle, .notification .content {
  color: currentColor;
}

.notification.is-white {
  color: #000;
  background-color: #fff;
}

.notification.is-black {
  color: #fff;
  background-color: #000;
}

.notification.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.notification.is-dark {
  color: #fff;
  background-color: #363636;
}

.notification.is-primary {
  color: #fff;
  background-color: #00d1b2;
}

.notification.is-primary.is-light {
  color: #00947e;
  background-color: #ebfffc;
}

.notification.is-link {
  color: #fff;
  background-color: #485fc7;
}

.notification.is-link.is-light {
  color: #3850b7;
  background-color: #eff1fa;
}

.notification.is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.notification.is-info.is-light {
  color: #296fa8;
  background-color: #eff5fb;
}

.notification.is-success {
  color: #fff;
  background-color: #48c78e;
}

.notification.is-success.is-light {
  color: #257953;
  background-color: #effaf5;
}

.notification.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.notification.is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.notification.is-danger {
  color: #fff;
  background-color: #f14668;
}

.notification.is-danger.is-light {
  color: #cc0f35;
  background-color: #feecf0;
}

.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 9999px;
  width: 100%;
  height: 1rem;
  padding: 0;
  display: block;
  overflow: hidden;
}

.progress::-webkit-progress-bar {
  background-color: #ededed;
}

.progress::-webkit-progress-value {
  background-color: #4a4a4a;
}

.progress::-moz-progress-bar {
  background-color: #4a4a4a;
}

.progress::-ms-fill {
  background-color: #4a4a4a;
  border: none;
}

.progress.is-white::-webkit-progress-value {
  background-color: #fff;
}

.progress.is-white::-moz-progress-bar {
  background-color: #fff;
}

.progress.is-white::-ms-fill {
  background-color: #fff;
}

.progress.is-white:indeterminate {
  background-image: linear-gradient(to right, #fff 30%, #ededed 30%);
}

.progress.is-black::-webkit-progress-value {
  background-color: #000;
}

.progress.is-black::-moz-progress-bar {
  background-color: #000;
}

.progress.is-black::-ms-fill {
  background-color: #000;
}

.progress.is-black:indeterminate {
  background-image: linear-gradient(to right, #000 30%, #ededed 30%);
}

.progress.is-light::-webkit-progress-value {
  background-color: #f5f5f5;
}

.progress.is-light::-moz-progress-bar {
  background-color: #f5f5f5;
}

.progress.is-light::-ms-fill {
  background-color: #f5f5f5;
}

.progress.is-light:indeterminate {
  background-image: linear-gradient(to right, #f5f5f5 30%, #ededed 30%);
}

.progress.is-dark::-webkit-progress-value {
  background-color: #363636;
}

.progress.is-dark::-moz-progress-bar {
  background-color: #363636;
}

.progress.is-dark::-ms-fill {
  background-color: #363636;
}

.progress.is-dark:indeterminate {
  background-image: linear-gradient(to right, #363636 30%, #ededed 30%);
}

.progress.is-primary::-webkit-progress-value {
  background-color: #00d1b2;
}

.progress.is-primary::-moz-progress-bar {
  background-color: #00d1b2;
}

.progress.is-primary::-ms-fill {
  background-color: #00d1b2;
}

.progress.is-primary:indeterminate {
  background-image: linear-gradient(to right, #00d1b2 30%, #ededed 30%);
}

.progress.is-link::-webkit-progress-value {
  background-color: #485fc7;
}

.progress.is-link::-moz-progress-bar {
  background-color: #485fc7;
}

.progress.is-link::-ms-fill {
  background-color: #485fc7;
}

.progress.is-link:indeterminate {
  background-image: linear-gradient(to right, #485fc7 30%, #ededed 30%);
}

.progress.is-info::-webkit-progress-value {
  background-color: #3e8ed0;
}

.progress.is-info::-moz-progress-bar {
  background-color: #3e8ed0;
}

.progress.is-info::-ms-fill {
  background-color: #3e8ed0;
}

.progress.is-info:indeterminate {
  background-image: linear-gradient(to right, #3e8ed0 30%, #ededed 30%);
}

.progress.is-success::-webkit-progress-value {
  background-color: #48c78e;
}

.progress.is-success::-moz-progress-bar {
  background-color: #48c78e;
}

.progress.is-success::-ms-fill {
  background-color: #48c78e;
}

.progress.is-success:indeterminate {
  background-image: linear-gradient(to right, #48c78e 30%, #ededed 30%);
}

.progress.is-warning::-webkit-progress-value {
  background-color: #ffe08a;
}

.progress.is-warning::-moz-progress-bar {
  background-color: #ffe08a;
}

.progress.is-warning::-ms-fill {
  background-color: #ffe08a;
}

.progress.is-warning:indeterminate {
  background-image: linear-gradient(to right, #ffe08a 30%, #ededed 30%);
}

.progress.is-danger::-webkit-progress-value {
  background-color: #f14668;
}

.progress.is-danger::-moz-progress-bar {
  background-color: #f14668;
}

.progress.is-danger::-ms-fill {
  background-color: #f14668;
}

.progress.is-danger:indeterminate {
  background-image: linear-gradient(to right, #f14668 30%, #ededed 30%);
}

.progress:indeterminate {
  background-color: #ededed;
  background-image: linear-gradient(to right, #4a4a4a 30%, #ededed 30%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 150% 150%;
  animation-name: moveIndeterminate;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.progress:indeterminate::-webkit-progress-bar {
  background-color: #0000;
}

.progress:indeterminate::-moz-progress-bar {
  background-color: #0000;
}

.progress:indeterminate::-ms-fill {
  animation-name: none;
}

.progress.is-small {
  height: .75rem;
}

.progress.is-medium {
  height: 1.25rem;
}

.progress.is-large {
  height: 1.5rem;
}

@keyframes moveIndeterminate {
  from {
    background-position: 200% 0;
  }

  to {
    background-position: -200% 0;
  }
}

.table {
  color: #363636;
  background-color: #fff;
}

.table td, .table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.table td.is-white, .table th.is-white {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.table td.is-black, .table th.is-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.table td.is-light, .table th.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.table td.is-dark, .table th.is-dark {
  color: #fff;
  background-color: #363636;
  border-color: #363636;
}

.table td.is-primary, .table th.is-primary {
  color: #fff;
  background-color: #00d1b2;
  border-color: #00d1b2;
}

.table td.is-link, .table th.is-link {
  color: #fff;
  background-color: #485fc7;
  border-color: #485fc7;
}

.table td.is-info, .table th.is-info {
  color: #fff;
  background-color: #3e8ed0;
  border-color: #3e8ed0;
}

.table td.is-success, .table th.is-success {
  color: #fff;
  background-color: #48c78e;
  border-color: #48c78e;
}

.table td.is-warning, .table th.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.table td.is-danger, .table th.is-danger {
  color: #fff;
  background-color: #f14668;
  border-color: #f14668;
}

.table td.is-narrow, .table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}

.table td.is-selected, .table th.is-selected {
  color: #fff;
  background-color: #00d1b2;
}

.table td.is-selected a, .table td.is-selected strong, .table th.is-selected a, .table th.is-selected strong {
  color: currentColor;
}

.table td.is-vcentered, .table th.is-vcentered {
  vertical-align: middle;
}

.table th {
  color: #363636;
}

.table th:not([align]) {
  text-align: left;
}

.table tr.is-selected {
  color: #fff;
  background-color: #00d1b2;
}

.table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor;
}

.table tr.is-selected td, .table tr.is-selected th {
  color: currentColor;
  border-color: #fff;
}

.table thead {
  background-color: #0000;
}

.table thead td, .table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.table tfoot {
  background-color: #0000;
}

.table tfoot td, .table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.table tbody {
  background-color: #0000;
}

.table tbody tr:last-child td, .table tbody tr:last-child th {
  border-bottom-width: 0;
}

.table.is-bordered td, .table.is-bordered th {
  border-width: 1px;
}

.table.is-bordered tr:last-child td, .table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}

.table.is-fullwidth {
  width: 100%;
}

.table.is-hoverable tbody tr:not(.is-selected):hover, .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: #fafafa;
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(2n) {
  background-color: #f5f5f5;
}

.table.is-narrow td, .table.is-narrow th {
  padding: .25em .5em;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
  background-color: #fafafa;
}

.table-container {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow: auto hidden;
}

.tags {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tags .tag {
  margin-bottom: .5rem;
}

.tags .tag:not(:last-child) {
  margin-right: .5rem;
}

.tags:last-child {
  margin-bottom: -.5rem;
}

.tags:not(:last-child) {
  margin-bottom: 1rem;
}

.tags.are-medium .tag:not(.is-normal):not(.is-large) {
  font-size: 1rem;
}

.tags.are-large .tag:not(.is-normal):not(.is-medium) {
  font-size: 1.25rem;
}

.tags.is-centered {
  justify-content: center;
}

.tags.is-centered .tag {
  margin-left: .25rem;
  margin-right: .25rem;
}

.tags.is-right {
  justify-content: flex-end;
}

.tags.is-right .tag:not(:first-child) {
  margin-left: .5rem;
}

.tags.is-right .tag:not(:last-child), .tags.has-addons .tag {
  margin-right: 0;
}

.tags.has-addons .tag:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.tags.has-addons .tag:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tag:not(body) {
  color: #4a4a4a;
  white-space: nowrap;
  background-color: #f5f5f5;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 2em;
  padding-left: .75em;
  padding-right: .75em;
  font-size: .75rem;
  line-height: 1.5;
  display: inline-flex;
}

.tag:not(body) .delete {
  margin-left: .25rem;
  margin-right: -.375rem;
}

.tag:not(body).is-white {
  color: #000;
  background-color: #fff;
}

.tag:not(body).is-black {
  color: #fff;
  background-color: #000;
}

.tag:not(body).is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.tag:not(body).is-dark {
  color: #fff;
  background-color: #363636;
}

.tag:not(body).is-primary {
  color: #fff;
  background-color: #00d1b2;
}

.tag:not(body).is-primary.is-light {
  color: #00947e;
  background-color: #ebfffc;
}

.tag:not(body).is-link {
  color: #fff;
  background-color: #485fc7;
}

.tag:not(body).is-link.is-light {
  color: #3850b7;
  background-color: #eff1fa;
}

.tag:not(body).is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.tag:not(body).is-info.is-light {
  color: #296fa8;
  background-color: #eff5fb;
}

.tag:not(body).is-success {
  color: #fff;
  background-color: #48c78e;
}

.tag:not(body).is-success.is-light {
  color: #257953;
  background-color: #effaf5;
}

.tag:not(body).is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.tag:not(body).is-warning.is-light {
  color: #946c00;
  background-color: #fffaeb;
}

.tag:not(body).is-danger {
  color: #fff;
  background-color: #f14668;
}

.tag:not(body).is-danger.is-light {
  color: #cc0f35;
  background-color: #feecf0;
}

.tag:not(body).is-normal {
  font-size: .75rem;
}

.tag:not(body).is-medium {
  font-size: 1rem;
}

.tag:not(body).is-large {
  font-size: 1.25rem;
}

.tag:not(body) .icon:first-child:not(:last-child) {
  margin-left: -.375em;
  margin-right: .1875em;
}

.tag:not(body) .icon:last-child:not(:first-child) {
  margin-left: .1875em;
  margin-right: -.375em;
}

.tag:not(body) .icon:first-child:last-child {
  margin-left: -.375em;
  margin-right: -.375em;
}

.tag:not(body).is-delete {
  width: 2em;
  margin-left: 1px;
  padding: 0;
  position: relative;
}

.tag:not(body).is-delete:before, .tag:not(body).is-delete:after {
  content: "";
  transform-origin: center;
  background-color: currentColor;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.tag:not(body).is-delete:before {
  width: 50%;
  height: 1px;
}

.tag:not(body).is-delete:after {
  width: 1px;
  height: 50%;
}

.tag:not(body).is-delete:hover, .tag:not(body).is-delete:focus {
  background-color: #e8e8e8;
}

.tag:not(body).is-delete:active {
  background-color: #dbdbdb;
}

.tag:not(body).is-rounded {
  border-radius: 9999px;
}

a.tag:hover {
  text-decoration: underline;
}

.title, .subtitle {
  word-break: break-word;
}

.title em, .title span, .subtitle em, .subtitle span {
  font-weight: inherit;
}

.title sub, .subtitle sub, .title sup, .subtitle sup {
  font-size: .75em;
}

.title .tag, .subtitle .tag {
  vertical-align: middle;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}

.title strong {
  color: inherit;
  font-weight: inherit;
}

.title:not(.is-spaced) + .subtitle {
  margin-top: -1.25rem;
}

.title.is-1 {
  font-size: 3rem;
}

.title.is-2 {
  font-size: 2.5rem;
}

.title.is-3 {
  font-size: 2rem;
}

.title.is-4 {
  font-size: 1.5rem;
}

.title.is-5 {
  font-size: 1.25rem;
}

.title.is-6 {
  font-size: 1rem;
}

.title.is-7 {
  font-size: .75rem;
}

.subtitle {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
  font-weight: 600;
}

.subtitle:not(.is-spaced) + .title {
  margin-top: -1.25rem;
}

.subtitle.is-1 {
  font-size: 3rem;
}

.subtitle.is-2 {
  font-size: 2.5rem;
}

.subtitle.is-3 {
  font-size: 2rem;
}

.subtitle.is-4 {
  font-size: 1.5rem;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle.is-6 {
  font-size: 1rem;
}

.subtitle.is-7 {
  font-size: .75rem;
}

.heading {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 11px;
  display: block;
}

.number {
  text-align: center;
  vertical-align: top;
  background-color: #f5f5f5;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  min-width: 2.5em;
  height: 2em;
  margin-right: 1.5rem;
  padding: .25rem .5rem;
  font-size: 1.25rem;
  display: inline-flex;
}

.select select, .textarea, .input {
  color: #363636;
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 4px;
}

.select select::-moz-placeholder {
  color: #3636364d;
}

.textarea::-moz-placeholder {
  color: #3636364d;
}

.input::-moz-placeholder {
  color: #3636364d;
}

.select select::-webkit-input-placeholder {
  color: #3636364d;
}

.textarea::-webkit-input-placeholder {
  color: #3636364d;
}

.input::-webkit-input-placeholder {
  color: #3636364d;
}

.select select:-moz-placeholder {
  color: #3636364d;
}

.textarea:-moz-placeholder {
  color: #3636364d;
}

.input:-moz-placeholder {
  color: #3636364d;
}

.select select:-ms-input-placeholder {
  color: #3636364d;
}

.textarea:-ms-input-placeholder {
  color: #3636364d;
}

.input:-ms-input-placeholder {
  color: #3636364d;
}

.select select:hover, .textarea:hover, .input:hover, .select select.is-hovered, .is-hovered.textarea, .is-hovered.input {
  border-color: #b5b5b5;
}

.select select:focus, .textarea:focus, .input:focus, .select select.is-focused, .is-focused.textarea, .is-focused.input, .select select:active, .textarea:active, .input:active, .select select.is-active, .is-active.textarea, .is-active.input {
  border-color: #485fc7;
  box-shadow: 0 0 0 .125em #485fc740;
}

.select select[disabled], [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input {
  box-shadow: none;
  color: #7a7a7a;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.select select[disabled]::-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea::-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].input::-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select::-moz-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select::-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea::-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input::-moz-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].input::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]:-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea:-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].input:-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select:-moz-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select:-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea:-moz-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input:-moz-placeholder {
  color: #7a7a7a4d;
}

.select select[disabled]:-ms-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea:-ms-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].input:-ms-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .select select:-ms-input-placeholder {
  color: #7a7a7a4d;
}

.select fieldset[disabled] select:-ms-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .textarea:-ms-input-placeholder {
  color: #7a7a7a4d;
}

fieldset[disabled] .input:-ms-input-placeholder {
  color: #7a7a7a4d;
}

.textarea, .input {
  width: 100%;
  max-width: 100%;
  box-shadow: inset 0 .0625em .125em #0000000d;
}

[readonly].textarea, [readonly].input {
  box-shadow: none;
}

.is-white.textarea, .is-white.input {
  border-color: #fff;
}

.is-white.textarea:focus, .is-white.input:focus, .is-white.is-focused.textarea, .is-white.is-focused.input, .is-white.textarea:active, .is-white.input:active, .is-white.is-active.textarea, .is-white.is-active.input {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.is-black.textarea, .is-black.input {
  border-color: #000;
}

.is-black.textarea:focus, .is-black.input:focus, .is-black.is-focused.textarea, .is-black.is-focused.input, .is-black.textarea:active, .is-black.input:active, .is-black.is-active.textarea, .is-black.is-active.input {
  box-shadow: 0 0 0 .125em #00000040;
}

.is-light.textarea, .is-light.input {
  border-color: #f5f5f5;
}

.is-light.textarea:focus, .is-light.input:focus, .is-light.is-focused.textarea, .is-light.is-focused.input, .is-light.textarea:active, .is-light.input:active, .is-light.is-active.textarea, .is-light.is-active.input {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.is-dark.textarea, .is-dark.input {
  border-color: #363636;
}

.is-dark.textarea:focus, .is-dark.input:focus, .is-dark.is-focused.textarea, .is-dark.is-focused.input, .is-dark.textarea:active, .is-dark.input:active, .is-dark.is-active.textarea, .is-dark.is-active.input {
  box-shadow: 0 0 0 .125em #36363640;
}

.is-primary.textarea, .is-primary.input {
  border-color: #00d1b2;
}

.is-primary.textarea:focus, .is-primary.input:focus, .is-primary.is-focused.textarea, .is-primary.is-focused.input, .is-primary.textarea:active, .is-primary.input:active, .is-primary.is-active.textarea, .is-primary.is-active.input {
  box-shadow: 0 0 0 .125em #00d1b240;
}

.is-link.textarea, .is-link.input {
  border-color: #485fc7;
}

.is-link.textarea:focus, .is-link.input:focus, .is-link.is-focused.textarea, .is-link.is-focused.input, .is-link.textarea:active, .is-link.input:active, .is-link.is-active.textarea, .is-link.is-active.input {
  box-shadow: 0 0 0 .125em #485fc740;
}

.is-info.textarea, .is-info.input {
  border-color: #3e8ed0;
}

.is-info.textarea:focus, .is-info.input:focus, .is-info.is-focused.textarea, .is-info.is-focused.input, .is-info.textarea:active, .is-info.input:active, .is-info.is-active.textarea, .is-info.is-active.input {
  box-shadow: 0 0 0 .125em #3e8ed040;
}

.is-success.textarea, .is-success.input {
  border-color: #48c78e;
}

.is-success.textarea:focus, .is-success.input:focus, .is-success.is-focused.textarea, .is-success.is-focused.input, .is-success.textarea:active, .is-success.input:active, .is-success.is-active.textarea, .is-success.is-active.input {
  box-shadow: 0 0 0 .125em #48c78e40;
}

.is-warning.textarea, .is-warning.input {
  border-color: #ffe08a;
}

.is-warning.textarea:focus, .is-warning.input:focus, .is-warning.is-focused.textarea, .is-warning.is-focused.input, .is-warning.textarea:active, .is-warning.input:active, .is-warning.is-active.textarea, .is-warning.is-active.input {
  box-shadow: 0 0 0 .125em #ffe08a40;
}

.is-danger.textarea, .is-danger.input {
  border-color: #f14668;
}

.is-danger.textarea:focus, .is-danger.input:focus, .is-danger.is-focused.textarea, .is-danger.is-focused.input, .is-danger.textarea:active, .is-danger.input:active, .is-danger.is-active.textarea, .is-danger.is-active.input {
  box-shadow: 0 0 0 .125em #f1466840;
}

.is-small.textarea, .is-small.input {
  border-radius: 2px;
  font-size: .75rem;
}

.is-medium.textarea, .is-medium.input {
  font-size: 1.25rem;
}

.is-large.textarea, .is-large.input {
  font-size: 1.5rem;
}

.is-fullwidth.textarea, .is-fullwidth.input {
  width: 100%;
  display: block;
}

.is-inline.textarea, .is-inline.input {
  width: auto;
  display: inline;
}

.input.is-rounded {
  border-radius: 9999px;
  padding-left: calc(1.125em - 1px);
  padding-right: calc(1.125em - 1px);
}

.input.is-static {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  resize: vertical;
  min-width: 100%;
  max-width: 100%;
  padding: calc(.75em - 1px);
  display: block;
}

.textarea:not([rows]) {
  min-height: 8em;
  max-height: 40em;
}

.textarea[rows] {
  height: initial;
}

.textarea.has-fixed-size {
  resize: none;
}

.radio, .checkbox {
  cursor: pointer;
  line-height: 1.25;
  display: inline-block;
  position: relative;
}

.radio input, .checkbox input {
  cursor: pointer;
}

.radio:hover, .checkbox:hover {
  color: #363636;
}

[disabled].radio, [disabled].checkbox, fieldset[disabled] .radio, fieldset[disabled] .checkbox, .radio input[disabled], .checkbox input[disabled] {
  color: #7a7a7a;
  cursor: not-allowed;
}

.radio + .radio {
  margin-left: .5em;
}

.select {
  vertical-align: top;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.select:not(.is-multiple) {
  height: 2.5em;
}

.select:not(.is-multiple):not(.is-loading):after {
  z-index: 4;
  border-color: #485fc7;
  right: 1.125em;
}

.select.is-rounded select {
  border-radius: 9999px;
  padding-left: 1em;
}

.select select {
  cursor: pointer;
  outline: none;
  max-width: 100%;
  font-size: 1em;
  display: block;
}

.select select::-ms-expand {
  display: none;
}

.select select[disabled]:hover, fieldset[disabled] .select select:hover {
  border-color: #f5f5f5;
}

.select select:not([multiple]) {
  padding-right: 2.5em;
}

.select select[multiple] {
  height: auto;
  padding: 0;
}

.select select[multiple] option {
  padding: .5em 1em;
}

.select:not(.is-multiple):not(.is-loading):hover:after {
  border-color: #363636;
}

.select.is-white:not(:hover):after, .select.is-white select {
  border-color: #fff;
}

.select.is-white select:hover, .select.is-white select.is-hovered {
  border-color: #f2f2f2;
}

.select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.select.is-black:not(:hover):after, .select.is-black select, .select.is-black select:hover, .select.is-black select.is-hovered {
  border-color: #000;
}

.select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
  box-shadow: 0 0 0 .125em #00000040;
}

.select.is-light:not(:hover):after, .select.is-light select {
  border-color: #f5f5f5;
}

.select.is-light select:hover, .select.is-light select.is-hovered {
  border-color: #e8e8e8;
}

.select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.select.is-dark:not(:hover):after, .select.is-dark select {
  border-color: #363636;
}

.select.is-dark select:hover, .select.is-dark select.is-hovered {
  border-color: #292929;
}

.select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
  box-shadow: 0 0 0 .125em #36363640;
}

.select.is-primary:not(:hover):after, .select.is-primary select {
  border-color: #00d1b2;
}

.select.is-primary select:hover, .select.is-primary select.is-hovered {
  border-color: #00b89c;
}

.select.is-primary select:focus, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select.is-active {
  box-shadow: 0 0 0 .125em #00d1b240;
}

.select.is-link:not(:hover):after, .select.is-link select {
  border-color: #485fc7;
}

.select.is-link select:hover, .select.is-link select.is-hovered {
  border-color: #3a51bb;
}

.select.is-link select:focus, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select.is-active {
  box-shadow: 0 0 0 .125em #485fc740;
}

.select.is-info:not(:hover):after, .select.is-info select {
  border-color: #3e8ed0;
}

.select.is-info select:hover, .select.is-info select.is-hovered {
  border-color: #3082c5;
}

.select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
  box-shadow: 0 0 0 .125em #3e8ed040;
}

.select.is-success:not(:hover):after, .select.is-success select {
  border-color: #48c78e;
}

.select.is-success select:hover, .select.is-success select.is-hovered {
  border-color: #3abb81;
}

.select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
  box-shadow: 0 0 0 .125em #48c78e40;
}

.select.is-warning:not(:hover):after, .select.is-warning select {
  border-color: #ffe08a;
}

.select.is-warning select:hover, .select.is-warning select.is-hovered {
  border-color: #ffd970;
}

.select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
  box-shadow: 0 0 0 .125em #ffe08a40;
}

.select.is-danger:not(:hover):after, .select.is-danger select {
  border-color: #f14668;
}

.select.is-danger select:hover, .select.is-danger select.is-hovered {
  border-color: #ef2e55;
}

.select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
  box-shadow: 0 0 0 .125em #f1466840;
}

.select.is-small {
  border-radius: 2px;
  font-size: .75rem;
}

.select.is-medium {
  font-size: 1.25rem;
}

.select.is-large {
  font-size: 1.5rem;
}

.select.is-disabled:after {
  opacity: .5;
  border-color: #7a7a7a !important;
}

.select.is-fullwidth, .select.is-fullwidth select {
  width: 100%;
}

.select.is-loading:after {
  margin-top: 0;
  position: absolute;
  top: .625em;
  right: .625em;
  transform: none;
}

.select.is-loading.is-small:after {
  font-size: .75rem;
}

.select.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.select.is-loading.is-large:after {
  font-size: 1.5rem;
}

.file {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.file.is-white .file-cta {
  color: #000;
  background-color: #fff;
  border-color: #0000;
}

.file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
  color: #000;
  background-color: #f9f9f9;
  border-color: #0000;
}

.file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
  color: #000;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffffff40;
}

.file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
  color: #000;
  background-color: #f2f2f2;
  border-color: #0000;
}

.file.is-black .file-cta, .file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #00000040;
}

.file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.file.is-light .file-cta {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #0000;
}

.file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
  color: #000000b3;
  background-color: #eee;
  border-color: #0000;
}

.file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #f5f5f540;
}

.file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
  color: #000000b3;
  background-color: #e8e8e8;
  border-color: #0000;
}

.file.is-dark .file-cta {
  color: #fff;
  background-color: #363636;
  border-color: #0000;
}

.file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
  color: #fff;
  background-color: #2f2f2f;
  border-color: #0000;
}

.file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #36363640;
}

.file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
  color: #fff;
  background-color: #292929;
  border-color: #0000;
}

.file.is-primary .file-cta {
  color: #fff;
  background-color: #00d1b2;
  border-color: #0000;
}

.file.is-primary:hover .file-cta, .file.is-primary.is-hovered .file-cta {
  color: #fff;
  background-color: #00c4a7;
  border-color: #0000;
}

.file.is-primary:focus .file-cta, .file.is-primary.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #00d1b240;
}

.file.is-primary:active .file-cta, .file.is-primary.is-active .file-cta {
  color: #fff;
  background-color: #00b89c;
  border-color: #0000;
}

.file.is-link .file-cta {
  color: #fff;
  background-color: #485fc7;
  border-color: #0000;
}

.file.is-link:hover .file-cta, .file.is-link.is-hovered .file-cta {
  color: #fff;
  background-color: #3e56c4;
  border-color: #0000;
}

.file.is-link:focus .file-cta, .file.is-link.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #485fc740;
}

.file.is-link:active .file-cta, .file.is-link.is-active .file-cta {
  color: #fff;
  background-color: #3a51bb;
  border-color: #0000;
}

.file.is-info .file-cta {
  color: #fff;
  background-color: #3e8ed0;
  border-color: #0000;
}

.file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
  color: #fff;
  background-color: #3488ce;
  border-color: #0000;
}

.file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #3e8ed040;
}

.file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
  color: #fff;
  background-color: #3082c5;
  border-color: #0000;
}

.file.is-success .file-cta {
  color: #fff;
  background-color: #48c78e;
  border-color: #0000;
}

.file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
  color: #fff;
  background-color: #3ec487;
  border-color: #0000;
}

.file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #48c78e40;
}

.file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
  color: #fff;
  background-color: #3abb81;
  border-color: #0000;
}

.file.is-warning .file-cta {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #0000;
}

.file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
  color: #000000b3;
  background-color: #ffdc7d;
  border-color: #0000;
}

.file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffe08a40;
}

.file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
  color: #000000b3;
  background-color: #ffd970;
  border-color: #0000;
}

.file.is-danger .file-cta {
  color: #fff;
  background-color: #f14668;
  border-color: #0000;
}

.file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
  color: #fff;
  background-color: #f03a5f;
  border-color: #0000;
}

.file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #f1466840;
}

.file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
  color: #fff;
  background-color: #ef2e55;
  border-color: #0000;
}

.file.is-small {
  font-size: .75rem;
}

.file.is-normal {
  font-size: 1rem;
}

.file.is-medium {
  font-size: 1.25rem;
}

.file.is-medium .file-icon .fa {
  font-size: 21px;
}

.file.is-large {
  font-size: 1.5rem;
}

.file.is-large .file-icon .fa {
  font-size: 28px;
}

.file.has-name .file-cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.file.has-name .file-name {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.file.has-name.is-empty .file-cta {
  border-radius: 4px;
}

.file.has-name.is-empty .file-name {
  display: none;
}

.file.is-boxed .file-label {
  flex-direction: column;
}

.file.is-boxed .file-cta {
  flex-direction: column;
  height: auto;
  padding: 1em 3em;
}

.file.is-boxed .file-name {
  border-width: 0 1px 1px;
}

.file.is-boxed .file-icon {
  width: 1.5em;
  height: 1.5em;
}

.file.is-boxed .file-icon .fa {
  font-size: 21px;
}

.file.is-boxed.is-small .file-icon .fa {
  font-size: 14px;
}

.file.is-boxed.is-medium .file-icon .fa {
  font-size: 28px;
}

.file.is-boxed.is-large .file-icon .fa {
  font-size: 35px;
}

.file.is-boxed.has-name .file-cta {
  border-radius: 4px 4px 0 0;
}

.file.is-boxed.has-name .file-name {
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
}

.file.is-centered {
  justify-content: center;
}

.file.is-fullwidth .file-label {
  width: 100%;
}

.file.is-fullwidth .file-name {
  flex-grow: 1;
  max-width: none;
}

.file.is-right {
  justify-content: flex-end;
}

.file.is-right .file-cta {
  border-radius: 0 4px 4px 0;
}

.file.is-right .file-name {
  border-width: 1px 0 1px 1px;
  border-radius: 4px 0 0 4px;
  order: -1;
}

.file-label {
  cursor: pointer;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.file-label:hover .file-cta {
  color: #363636;
  background-color: #eee;
}

.file-label:hover .file-name {
  border-color: #d5d5d5;
}

.file-label:active .file-cta {
  color: #363636;
  background-color: #e8e8e8;
}

.file-label:active .file-name {
  border-color: #cfcfcf;
}

.file-input {
  opacity: 0;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.file-cta, .file-name {
  white-space: nowrap;
  border-color: #dbdbdb;
  border-radius: 4px;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1em;
}

.file-cta {
  color: #4a4a4a;
  background-color: #f5f5f5;
}

.file-name {
  text-align: inherit;
  text-overflow: ellipsis;
  border: 1px solid #dbdbdb;
  border-left-width: 0;
  max-width: 16em;
  display: block;
  overflow: hidden;
}

.file-icon {
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-right: .5em;
  display: flex;
}

.file-icon .fa {
  font-size: 14px;
}

.label {
  color: #363636;
  font-size: 1rem;
  font-weight: 700;
  display: block;
}

.label:not(:last-child) {
  margin-bottom: .5em;
}

.label.is-small {
  font-size: .75rem;
}

.label.is-medium {
  font-size: 1.25rem;
}

.label.is-large {
  font-size: 1.5rem;
}

.help {
  margin-top: .25rem;
  font-size: .75rem;
  display: block;
}

.help.is-white {
  color: #fff;
}

.help.is-black {
  color: #000;
}

.help.is-light {
  color: #f5f5f5;
}

.help.is-dark {
  color: #363636;
}

.help.is-primary {
  color: #00d1b2;
}

.help.is-link {
  color: #485fc7;
}

.help.is-info {
  color: #3e8ed0;
}

.help.is-success {
  color: #48c78e;
}

.help.is-warning {
  color: #ffe08a;
}

.help.is-danger {
  color: #f14668;
}

.field:not(:last-child) {
  margin-bottom: .75rem;
}

.field.has-addons {
  justify-content: flex-start;
  display: flex;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}

.field.has-addons .control:not(:first-child):not(:last-child) .button, .field.has-addons .control:not(:first-child):not(:last-child) .input, .field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}

.field.has-addons .control:first-child:not(:only-child) .button, .field.has-addons .control:first-child:not(:only-child) .input, .field.has-addons .control:first-child:not(:only-child) .select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field.has-addons .control:last-child:not(:only-child) .button, .field.has-addons .control:last-child:not(:only-child) .input, .field.has-addons .control:last-child:not(:only-child) .select select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered, .field.has-addons .control .input:not([disabled]):hover, .field.has-addons .control .input:not([disabled]).is-hovered, .field.has-addons .control .select select:not([disabled]):hover, .field.has-addons .control .select select:not([disabled]).is-hovered {
  z-index: 2;
}

.field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active, .field.has-addons .control .input:not([disabled]):focus, .field.has-addons .control .input:not([disabled]).is-focused, .field.has-addons .control .input:not([disabled]):active, .field.has-addons .control .input:not([disabled]).is-active, .field.has-addons .control .select select:not([disabled]):focus, .field.has-addons .control .select select:not([disabled]).is-focused, .field.has-addons .control .select select:not([disabled]):active, .field.has-addons .control .select select:not([disabled]).is-active {
  z-index: 3;
}

.field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover, .field.has-addons .control .input:not([disabled]):focus:hover, .field.has-addons .control .input:not([disabled]).is-focused:hover, .field.has-addons .control .input:not([disabled]):active:hover, .field.has-addons .control .input:not([disabled]).is-active:hover, .field.has-addons .control .select select:not([disabled]):focus:hover, .field.has-addons .control .select select:not([disabled]).is-focused:hover, .field.has-addons .control .select select:not([disabled]):active:hover, .field.has-addons .control .select select:not([disabled]).is-active:hover {
  z-index: 4;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.has-addons.has-addons-centered {
  justify-content: center;
}

.field.has-addons.has-addons-right {
  justify-content: flex-end;
}

.field.has-addons.has-addons-fullwidth .control {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.is-grouped {
  justify-content: flex-start;
  display: flex;
}

.field.is-grouped > .control {
  flex-shrink: 0;
}

.field.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.is-grouped.is-grouped-centered {
  justify-content: center;
}

.field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}

.field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
  margin-bottom: .75rem;
}

.field.is-grouped.is-grouped-multiline:last-child {
  margin-bottom: -.75rem;
}

.field.is-grouped.is-grouped-multiline:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (width >= 769px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}

@media screen and (width <= 768px) {
  .field-label {
    margin-bottom: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .field-label {
    text-align: right;
    flex: 1 0 0;
    margin-right: 1.5rem;
  }

  .field-label.is-small {
    padding-top: .375em;
    font-size: .75rem;
  }

  .field-label.is-normal {
    padding-top: .375em;
  }

  .field-label.is-medium {
    padding-top: .375em;
    font-size: 1.25rem;
  }

  .field-label.is-large {
    padding-top: .375em;
    font-size: 1.5rem;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}

@media screen and (width >= 769px), print {
  .field-body {
    flex: 5 1 0;
    display: flex;
  }

  .field-body .field {
    margin-bottom: 0;
  }

  .field-body > .field {
    flex-shrink: 1;
  }

  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }

  .field-body > .field:not(:last-child) {
    margin-right: .75rem;
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  text-align: inherit;
  font-size: 1rem;
  position: relative;
}

.control.has-icons-left .input:focus ~ .icon, .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon, .control.has-icons-right .select:focus ~ .icon {
  color: #4a4a4a;
}

.control.has-icons-left .input.is-small ~ .icon, .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon, .control.has-icons-right .select.is-small ~ .icon {
  font-size: .75rem;
}

.control.has-icons-left .input.is-medium ~ .icon, .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon, .control.has-icons-right .select.is-medium ~ .icon {
  font-size: 1.25rem;
}

.control.has-icons-left .input.is-large ~ .icon, .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon, .control.has-icons-right .select.is-large ~ .icon {
  font-size: 1.5rem;
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #dbdbdb;
  pointer-events: none;
  z-index: 4;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  top: 0;
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 2.5em;
}

.control.has-icons-left .icon.is-left {
  left: 0;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
  padding-right: 2.5em;
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.is-loading:after {
  z-index: 4;
  top: .625em;
  right: .625em;
  position: absolute !important;
}

.control.is-loading.is-small:after {
  font-size: .75rem;
}

.control.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.control.is-loading.is-large:after {
  font-size: 1.5rem;
}

.breadcrumb {
  white-space: nowrap;
  font-size: 1rem;
}

.breadcrumb a {
  color: #485fc7;
  justify-content: center;
  align-items: center;
  padding: 0 .75em;
  display: flex;
}

.breadcrumb a:hover {
  color: #363636;
}

.breadcrumb li {
  align-items: center;
  display: flex;
}

.breadcrumb li:first-child a {
  padding-left: 0;
}

.breadcrumb li.is-active a {
  color: #363636;
  cursor: default;
  pointer-events: none;
}

.breadcrumb li + li:before {
  color: #b5b5b5;
  content: "/";
}

.breadcrumb ul, .breadcrumb ol {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.breadcrumb .icon:first-child {
  margin-right: .5em;
}

.breadcrumb .icon:last-child {
  margin-left: .5em;
}

.breadcrumb.is-centered ol, .breadcrumb.is-centered ul {
  justify-content: center;
}

.breadcrumb.is-right ol, .breadcrumb.is-right ul {
  justify-content: flex-end;
}

.breadcrumb.is-small {
  font-size: .75rem;
}

.breadcrumb.is-medium {
  font-size: 1.25rem;
}

.breadcrumb.is-large {
  font-size: 1.5rem;
}

.breadcrumb.has-arrow-separator li + li:before {
  content: "→";
}

.breadcrumb.has-bullet-separator li + li:before {
  content: "•";
}

.breadcrumb.has-dot-separator li + li:before {
  content: "·";
}

.breadcrumb.has-succeeds-separator li + li:before {
  content: "≻";
}

.card {
  color: #4a4a4a;
  background-color: #fff;
  border-radius: .25rem;
  max-width: 100%;
  position: relative;
  box-shadow: 0 .5em 1em -.125em #0000001a, 0 0 0 1px #00000005;
}

.card-footer:first-child, .card-content:first-child, .card-header:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-footer:last-child, .card-content:last-child, .card-header:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-header {
  background-color: #0000;
  align-items: stretch;
  display: flex;
  box-shadow: 0 .125em .25em #0000001a;
}

.card-header-title {
  color: #363636;
  flex-grow: 1;
  align-items: center;
  padding: .75rem 1rem;
  font-weight: 700;
  display: flex;
}

.card-header-title.is-centered {
  justify-content: center;
}

.card-header-icon {
  appearance: none;
  color: currentColor;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .75rem 1rem;
  font-family: inherit;
  font-size: 1em;
  display: flex;
}

.card-image {
  display: block;
  position: relative;
}

.card-image:first-child img {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-image:last-child img {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-content {
  background-color: #0000;
  padding: 1.5rem;
}

.card-footer {
  background-color: #0000;
  border-top: 1px solid #ededed;
  align-items: stretch;
  display: flex;
}

.card-footer-item {
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.card-footer-item:not(:last-child) {
  border-right: 1px solid #ededed;
}

.card .media:not(:last-child) {
  margin-bottom: 1.5rem;
}

.dropdown {
  vertical-align: top;
  display: inline-flex;
  position: relative;
}

.dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
}

.dropdown.is-right .dropdown-menu {
  left: auto;
  right: 0;
}

.dropdown.is-up .dropdown-menu {
  padding-bottom: 4px;
  padding-top: initial;
  top: auto;
  bottom: 100%;
}

.dropdown-menu {
  z-index: 20;
  min-width: 12rem;
  padding-top: 4px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-content {
  background-color: #fff;
  border-radius: 4px;
  padding-top: .5rem;
  padding-bottom: .5rem;
  box-shadow: 0 .5em 1em -.125em #0000001a, 0 0 0 1px #00000005;
}

.dropdown-item {
  color: #4a4a4a;
  padding: .375rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

a.dropdown-item, button.dropdown-item {
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  padding-right: 3rem;
}

a.dropdown-item:hover, button.dropdown-item:hover {
  color: #000;
  background-color: #f5f5f5;
}

a.dropdown-item.is-active, button.dropdown-item.is-active {
  color: #fff;
  background-color: #485fc7;
}

.dropdown-divider {
  background-color: #ededed;
  border: none;
  height: 1px;
  margin: .5rem 0;
  display: block;
}

.level {
  justify-content: space-between;
  align-items: center;
}

.level code {
  border-radius: 4px;
}

.level img {
  vertical-align: top;
  display: inline-block;
}

.level.is-mobile, .level.is-mobile .level-left, .level.is-mobile .level-right {
  display: flex;
}

.level.is-mobile .level-left + .level-right {
  margin-top: 0;
}

.level.is-mobile .level-item:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.level.is-mobile .level-item:not(.is-narrow) {
  flex-grow: 1;
}

@media screen and (width >= 769px), print {
  .level {
    display: flex;
  }

  .level > .level-item:not(.is-narrow) {
    flex-grow: 1;
  }
}

.level-item {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.level-item .title, .level-item .subtitle {
  margin-bottom: 0;
}

@media screen and (width <= 768px) {
  .level-item:not(:last-child) {
    margin-bottom: .75rem;
  }
}

.level-left, .level-right {
  flex: none;
}

.level-left .level-item.is-flexible, .level-right .level-item.is-flexible {
  flex-grow: 1;
}

@media screen and (width >= 769px), print {
  .level-left .level-item:not(:last-child), .level-right .level-item:not(:last-child) {
    margin-right: .75rem;
  }
}

.level-left {
  justify-content: flex-start;
  align-items: center;
}

@media screen and (width <= 768px) {
  .level-left + .level-right {
    margin-top: 1.5rem;
  }
}

@media screen and (width >= 769px), print {
  .level-left {
    display: flex;
  }
}

.level-right {
  justify-content: flex-end;
  align-items: center;
}

@media screen and (width >= 769px), print {
  .level-right {
    display: flex;
  }
}

.media {
  text-align: inherit;
  align-items: flex-start;
  display: flex;
}

.media .content:not(:last-child) {
  margin-bottom: .75rem;
}

.media .media {
  border-top: 1px solid #dbdbdb80;
  padding-top: .75rem;
  display: flex;
}

.media .media .content:not(:last-child), .media .media .control:not(:last-child) {
  margin-bottom: .5rem;
}

.media .media .media {
  padding-top: .5rem;
}

.media .media .media + .media {
  margin-top: .5rem;
}

.media + .media {
  border-top: 1px solid #dbdbdb80;
  margin-top: 1rem;
  padding-top: 1rem;
}

.media.is-large + .media {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.media-left, .media-right {
  flex: none;
}

.media-left {
  margin-right: 1rem;
}

.media-right {
  margin-left: 1rem;
}

.media-content {
  text-align: inherit;
  flex: auto;
}

@media screen and (width <= 768px) {
  .media-content {
    overflow-x: auto;
  }
}

.menu {
  font-size: 1rem;
}

.menu.is-small {
  font-size: .75rem;
}

.menu.is-medium {
  font-size: 1.25rem;
}

.menu.is-large {
  font-size: 1.5rem;
}

.menu-list {
  line-height: 1.25;
}

.menu-list a {
  color: #4a4a4a;
  border-radius: 2px;
  padding: .5em .75em;
  display: block;
}

.menu-list a:hover {
  color: #363636;
  background-color: #f5f5f5;
}

.menu-list a.is-active {
  color: #fff;
  background-color: #485fc7;
}

.menu-list li ul {
  border-left: 1px solid #dbdbdb;
  margin: .75em;
  padding-left: .75em;
}

.menu-label {
  color: #7a7a7a;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: .75em;
}

.menu-label:not(:first-child) {
  margin-top: 1em;
}

.menu-label:not(:last-child) {
  margin-bottom: 1em;
}

.message {
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 1rem;
}

.message strong {
  color: currentColor;
}

.message a:not(.button):not(.tag):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.message.is-small {
  font-size: .75rem;
}

.message.is-medium {
  font-size: 1.25rem;
}

.message.is-large {
  font-size: 1.5rem;
}

.message.is-white {
  background-color: #fff;
}

.message.is-white .message-header {
  color: #000;
  background-color: #fff;
}

.message.is-white .message-body {
  border-color: #fff;
}

.message.is-black {
  background-color: #fafafa;
}

.message.is-black .message-header {
  color: #fff;
  background-color: #000;
}

.message.is-black .message-body {
  border-color: #000;
}

.message.is-light {
  background-color: #fafafa;
}

.message.is-light .message-header {
  color: #000000b3;
  background-color: #f5f5f5;
}

.message.is-light .message-body {
  border-color: #f5f5f5;
}

.message.is-dark {
  background-color: #fafafa;
}

.message.is-dark .message-header {
  color: #fff;
  background-color: #363636;
}

.message.is-dark .message-body {
  border-color: #363636;
}

.message.is-primary {
  background-color: #ebfffc;
}

.message.is-primary .message-header {
  color: #fff;
  background-color: #00d1b2;
}

.message.is-primary .message-body {
  color: #00947e;
  border-color: #00d1b2;
}

.message.is-link {
  background-color: #eff1fa;
}

.message.is-link .message-header {
  color: #fff;
  background-color: #485fc7;
}

.message.is-link .message-body {
  color: #3850b7;
  border-color: #485fc7;
}

.message.is-info {
  background-color: #eff5fb;
}

.message.is-info .message-header {
  color: #fff;
  background-color: #3e8ed0;
}

.message.is-info .message-body {
  color: #296fa8;
  border-color: #3e8ed0;
}

.message.is-success {
  background-color: #effaf5;
}

.message.is-success .message-header {
  color: #fff;
  background-color: #48c78e;
}

.message.is-success .message-body {
  color: #257953;
  border-color: #48c78e;
}

.message.is-warning {
  background-color: #fffaeb;
}

.message.is-warning .message-header {
  color: #000000b3;
  background-color: #ffe08a;
}

.message.is-warning .message-body {
  color: #946c00;
  border-color: #ffe08a;
}

.message.is-danger {
  background-color: #feecf0;
}

.message.is-danger .message-header {
  color: #fff;
  background-color: #f14668;
}

.message.is-danger .message-body {
  color: #cc0f35;
  border-color: #f14668;
}

.message-header {
  color: #fff;
  background-color: #4a4a4a;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: .75em 1em;
  font-weight: 700;
  line-height: 1.25;
  display: flex;
  position: relative;
}

.message-header .delete {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: .75em;
}

.message-header + .message-body {
  border-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.message-body {
  color: #4a4a4a;
  border: 0 solid #dbdbdb;
  border-left-width: 4px;
  border-radius: 4px;
  padding: 1.25em 1.5em;
}

.message-body code, .message-body pre {
  background-color: #fff;
}

.message-body pre code {
  background-color: #0000;
}

.modal {
  z-index: 40;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  overflow: hidden;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: #000000db;
}

.modal-content, .modal-card {
  width: 100%;
  max-height: calc(100vh - 160px);
  margin: 0 20px;
  position: relative;
  overflow: auto;
}

@media screen and (width >= 769px) {
  .modal-content, .modal-card {
    width: 640px;
    max-height: calc(100vh - 40px);
    margin: 0 auto;
  }
}

.modal-close {
  background: none;
  width: 40px;
  height: 40px;
  position: fixed;
  top: 20px;
  right: 20px;
}

.modal-card {
  -ms-overflow-y: visible;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  display: flex;
  overflow: hidden;
}

.modal-card-head, .modal-card-foot {
  background-color: #f5f5f5;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  display: flex;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-top: 1px solid #dbdbdb;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: .5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px;
  overflow: auto;
}

.navbar {
  z-index: 30;
  background-color: #fff;
  min-height: 6rem;
  position: relative;
}

.navbar.is-white {
  color: #000;
  background-color: #fff;
}

.navbar.is-white .navbar-brand > .navbar-item, .navbar.is-white .navbar-brand .navbar-link {
  color: #000;
}

.navbar.is-white .navbar-brand > a.navbar-item:focus, .navbar.is-white .navbar-brand > a.navbar-item:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active, .navbar.is-white .navbar-brand .navbar-link:focus, .navbar.is-white .navbar-brand .navbar-link:hover, .navbar.is-white .navbar-brand .navbar-link.is-active {
  color: #000;
  background-color: #f2f2f2;
}

.navbar.is-white .navbar-brand .navbar-link:after {
  border-color: #000;
}

.navbar.is-white .navbar-burger {
  color: #000;
}

@media screen and (width >= 1024px) {
  .navbar.is-white .navbar-start > .navbar-item, .navbar.is-white .navbar-start .navbar-link, .navbar.is-white .navbar-end > .navbar-item, .navbar.is-white .navbar-end .navbar-link {
    color: #000;
  }

  .navbar.is-white .navbar-start > a.navbar-item:focus, .navbar.is-white .navbar-start > a.navbar-item:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active, .navbar.is-white .navbar-start .navbar-link:focus, .navbar.is-white .navbar-start .navbar-link:hover, .navbar.is-white .navbar-start .navbar-link.is-active, .navbar.is-white .navbar-end > a.navbar-item:focus, .navbar.is-white .navbar-end > a.navbar-item:hover, .navbar.is-white .navbar-end > a.navbar-item.is-active, .navbar.is-white .navbar-end .navbar-link:focus, .navbar.is-white .navbar-end .navbar-link:hover, .navbar.is-white .navbar-end .navbar-link.is-active {
    color: #000;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-start .navbar-link:after, .navbar.is-white .navbar-end .navbar-link:after {
    border-color: #000;
  }

  .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
    color: #000;
    background-color: #fff;
  }
}

.navbar.is-black {
  color: #fff;
  background-color: #000;
}

.navbar.is-black .navbar-brand > .navbar-item, .navbar.is-black .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-black .navbar-brand > a.navbar-item:focus, .navbar.is-black .navbar-brand > a.navbar-item:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active, .navbar.is-black .navbar-brand .navbar-link:focus, .navbar.is-black .navbar-brand .navbar-link:hover, .navbar.is-black .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.navbar.is-black .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-black .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-black .navbar-start > .navbar-item, .navbar.is-black .navbar-start .navbar-link, .navbar.is-black .navbar-end > .navbar-item, .navbar.is-black .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-black .navbar-start > a.navbar-item:focus, .navbar.is-black .navbar-start > a.navbar-item:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active, .navbar.is-black .navbar-start .navbar-link:focus, .navbar.is-black .navbar-start .navbar-link:hover, .navbar.is-black .navbar-start .navbar-link.is-active, .navbar.is-black .navbar-end > a.navbar-item:focus, .navbar.is-black .navbar-end > a.navbar-item:hover, .navbar.is-black .navbar-end > a.navbar-item.is-active, .navbar.is-black .navbar-end .navbar-link:focus, .navbar.is-black .navbar-end .navbar-link:hover, .navbar.is-black .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-black .navbar-start .navbar-link:after, .navbar.is-black .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-black .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #000;
  }
}

.navbar.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.navbar.is-light .navbar-brand > .navbar-item, .navbar.is-light .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-light .navbar-brand > a.navbar-item:focus, .navbar.is-light .navbar-brand > a.navbar-item:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active, .navbar.is-light .navbar-brand .navbar-link:focus, .navbar.is-light .navbar-brand .navbar-link:hover, .navbar.is-light .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
}

.navbar.is-light .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-light .navbar-burger {
  color: #000000b3;
}

@media screen and (width >= 1024px) {
  .navbar.is-light .navbar-start > .navbar-item, .navbar.is-light .navbar-start .navbar-link, .navbar.is-light .navbar-end > .navbar-item, .navbar.is-light .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-light .navbar-start > a.navbar-item:focus, .navbar.is-light .navbar-start > a.navbar-item:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active, .navbar.is-light .navbar-start .navbar-link:focus, .navbar.is-light .navbar-start .navbar-link:hover, .navbar.is-light .navbar-start .navbar-link.is-active, .navbar.is-light .navbar-end > a.navbar-item:focus, .navbar.is-light .navbar-end > a.navbar-item:hover, .navbar.is-light .navbar-end > a.navbar-item.is-active, .navbar.is-light .navbar-end .navbar-link:focus, .navbar.is-light .navbar-end .navbar-link:hover, .navbar.is-light .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #e8e8e8;
  }

  .navbar.is-light .navbar-start .navbar-link:after, .navbar.is-light .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-light .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #e8e8e8;
  }

  .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #f5f5f5;
  }
}

.navbar.is-dark {
  color: #fff;
  background-color: #363636;
}

.navbar.is-dark .navbar-brand > .navbar-item, .navbar.is-dark .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-dark .navbar-brand > a.navbar-item:focus, .navbar.is-dark .navbar-brand > a.navbar-item:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-dark .navbar-brand .navbar-link:focus, .navbar.is-dark .navbar-brand .navbar-link:hover, .navbar.is-dark .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #292929;
}

.navbar.is-dark .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-dark .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-dark .navbar-start > .navbar-item, .navbar.is-dark .navbar-start .navbar-link, .navbar.is-dark .navbar-end > .navbar-item, .navbar.is-dark .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-dark .navbar-start > a.navbar-item:focus, .navbar.is-dark .navbar-start > a.navbar-item:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active, .navbar.is-dark .navbar-start .navbar-link:focus, .navbar.is-dark .navbar-start .navbar-link:hover, .navbar.is-dark .navbar-start .navbar-link.is-active, .navbar.is-dark .navbar-end > a.navbar-item:focus, .navbar.is-dark .navbar-end > a.navbar-item:hover, .navbar.is-dark .navbar-end > a.navbar-item.is-active, .navbar.is-dark .navbar-end .navbar-link:focus, .navbar.is-dark .navbar-end .navbar-link:hover, .navbar.is-dark .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #292929;
  }

  .navbar.is-dark .navbar-start .navbar-link:after, .navbar.is-dark .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-dark .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #292929;
  }

  .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #363636;
  }
}

.navbar.is-primary {
  color: #fff;
  background-color: #00d1b2;
}

.navbar.is-primary .navbar-brand > .navbar-item, .navbar.is-primary .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-primary .navbar-brand > a.navbar-item:focus, .navbar.is-primary .navbar-brand > a.navbar-item:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active, .navbar.is-primary .navbar-brand .navbar-link:focus, .navbar.is-primary .navbar-brand .navbar-link:hover, .navbar.is-primary .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #00b89c;
}

.navbar.is-primary .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-primary .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-primary .navbar-start > .navbar-item, .navbar.is-primary .navbar-start .navbar-link, .navbar.is-primary .navbar-end > .navbar-item, .navbar.is-primary .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-primary .navbar-start > a.navbar-item:focus, .navbar.is-primary .navbar-start > a.navbar-item:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active, .navbar.is-primary .navbar-start .navbar-link:focus, .navbar.is-primary .navbar-start .navbar-link:hover, .navbar.is-primary .navbar-start .navbar-link.is-active, .navbar.is-primary .navbar-end > a.navbar-item:focus, .navbar.is-primary .navbar-end > a.navbar-item:hover, .navbar.is-primary .navbar-end > a.navbar-item.is-active, .navbar.is-primary .navbar-end .navbar-link:focus, .navbar.is-primary .navbar-end .navbar-link:hover, .navbar.is-primary .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #00b89c;
  }

  .navbar.is-primary .navbar-start .navbar-link:after, .navbar.is-primary .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-primary .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #00b89c;
  }

  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #00d1b2;
  }
}

.navbar.is-link {
  color: #fff;
  background-color: #485fc7;
}

.navbar.is-link .navbar-brand > .navbar-item, .navbar.is-link .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-link .navbar-brand > a.navbar-item:focus, .navbar.is-link .navbar-brand > a.navbar-item:hover, .navbar.is-link .navbar-brand > a.navbar-item.is-active, .navbar.is-link .navbar-brand .navbar-link:focus, .navbar.is-link .navbar-brand .navbar-link:hover, .navbar.is-link .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #3a51bb;
}

.navbar.is-link .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-link .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-link .navbar-start > .navbar-item, .navbar.is-link .navbar-start .navbar-link, .navbar.is-link .navbar-end > .navbar-item, .navbar.is-link .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-link .navbar-start > a.navbar-item:focus, .navbar.is-link .navbar-start > a.navbar-item:hover, .navbar.is-link .navbar-start > a.navbar-item.is-active, .navbar.is-link .navbar-start .navbar-link:focus, .navbar.is-link .navbar-start .navbar-link:hover, .navbar.is-link .navbar-start .navbar-link.is-active, .navbar.is-link .navbar-end > a.navbar-item:focus, .navbar.is-link .navbar-end > a.navbar-item:hover, .navbar.is-link .navbar-end > a.navbar-item.is-active, .navbar.is-link .navbar-end .navbar-link:focus, .navbar.is-link .navbar-end .navbar-link:hover, .navbar.is-link .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #3a51bb;
  }

  .navbar.is-link .navbar-start .navbar-link:after, .navbar.is-link .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-link .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #3a51bb;
  }

  .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #485fc7;
  }
}

.navbar.is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.navbar.is-info .navbar-brand > .navbar-item, .navbar.is-info .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-info .navbar-brand > a.navbar-item:focus, .navbar.is-info .navbar-brand > a.navbar-item:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active, .navbar.is-info .navbar-brand .navbar-link:focus, .navbar.is-info .navbar-brand .navbar-link:hover, .navbar.is-info .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #3082c5;
}

.navbar.is-info .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-info .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-info .navbar-start > .navbar-item, .navbar.is-info .navbar-start .navbar-link, .navbar.is-info .navbar-end > .navbar-item, .navbar.is-info .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-info .navbar-start > a.navbar-item:focus, .navbar.is-info .navbar-start > a.navbar-item:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active, .navbar.is-info .navbar-start .navbar-link:focus, .navbar.is-info .navbar-start .navbar-link:hover, .navbar.is-info .navbar-start .navbar-link.is-active, .navbar.is-info .navbar-end > a.navbar-item:focus, .navbar.is-info .navbar-end > a.navbar-item:hover, .navbar.is-info .navbar-end > a.navbar-item.is-active, .navbar.is-info .navbar-end .navbar-link:focus, .navbar.is-info .navbar-end .navbar-link:hover, .navbar.is-info .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #3082c5;
  }

  .navbar.is-info .navbar-start .navbar-link:after, .navbar.is-info .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-info .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #3082c5;
  }

  .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #3e8ed0;
  }
}

.navbar.is-success {
  color: #fff;
  background-color: #48c78e;
}

.navbar.is-success .navbar-brand > .navbar-item, .navbar.is-success .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-success .navbar-brand > a.navbar-item:focus, .navbar.is-success .navbar-brand > a.navbar-item:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active, .navbar.is-success .navbar-brand .navbar-link:focus, .navbar.is-success .navbar-brand .navbar-link:hover, .navbar.is-success .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #3abb81;
}

.navbar.is-success .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-success .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-success .navbar-start > .navbar-item, .navbar.is-success .navbar-start .navbar-link, .navbar.is-success .navbar-end > .navbar-item, .navbar.is-success .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-success .navbar-start > a.navbar-item:focus, .navbar.is-success .navbar-start > a.navbar-item:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active, .navbar.is-success .navbar-start .navbar-link:focus, .navbar.is-success .navbar-start .navbar-link:hover, .navbar.is-success .navbar-start .navbar-link.is-active, .navbar.is-success .navbar-end > a.navbar-item:focus, .navbar.is-success .navbar-end > a.navbar-item:hover, .navbar.is-success .navbar-end > a.navbar-item.is-active, .navbar.is-success .navbar-end .navbar-link:focus, .navbar.is-success .navbar-end .navbar-link:hover, .navbar.is-success .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #3abb81;
  }

  .navbar.is-success .navbar-start .navbar-link:after, .navbar.is-success .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-success .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #3abb81;
  }

  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #48c78e;
  }
}

.navbar.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.navbar.is-warning .navbar-brand > .navbar-item, .navbar.is-warning .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-warning .navbar-brand > a.navbar-item:focus, .navbar.is-warning .navbar-brand > a.navbar-item:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active, .navbar.is-warning .navbar-brand .navbar-link:focus, .navbar.is-warning .navbar-brand .navbar-link:hover, .navbar.is-warning .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #ffd970;
}

.navbar.is-warning .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-warning .navbar-burger {
  color: #000000b3;
}

@media screen and (width >= 1024px) {
  .navbar.is-warning .navbar-start > .navbar-item, .navbar.is-warning .navbar-start .navbar-link, .navbar.is-warning .navbar-end > .navbar-item, .navbar.is-warning .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-warning .navbar-start > a.navbar-item:focus, .navbar.is-warning .navbar-start > a.navbar-item:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active, .navbar.is-warning .navbar-start .navbar-link:focus, .navbar.is-warning .navbar-start .navbar-link:hover, .navbar.is-warning .navbar-start .navbar-link.is-active, .navbar.is-warning .navbar-end > a.navbar-item:focus, .navbar.is-warning .navbar-end > a.navbar-item:hover, .navbar.is-warning .navbar-end > a.navbar-item.is-active, .navbar.is-warning .navbar-end .navbar-link:focus, .navbar.is-warning .navbar-end .navbar-link:hover, .navbar.is-warning .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #ffd970;
  }

  .navbar.is-warning .navbar-start .navbar-link:after, .navbar.is-warning .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-warning .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #ffd970;
  }

  .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #ffe08a;
  }
}

.navbar.is-danger {
  color: #fff;
  background-color: #f14668;
}

.navbar.is-danger .navbar-brand > .navbar-item, .navbar.is-danger .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-danger .navbar-brand > a.navbar-item:focus, .navbar.is-danger .navbar-brand > a.navbar-item:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active, .navbar.is-danger .navbar-brand .navbar-link:focus, .navbar.is-danger .navbar-brand .navbar-link:hover, .navbar.is-danger .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #ef2e55;
}

.navbar.is-danger .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-danger .navbar-burger {
  color: #fff;
}

@media screen and (width >= 1024px) {
  .navbar.is-danger .navbar-start > .navbar-item, .navbar.is-danger .navbar-start .navbar-link, .navbar.is-danger .navbar-end > .navbar-item, .navbar.is-danger .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-danger .navbar-start > a.navbar-item:focus, .navbar.is-danger .navbar-start > a.navbar-item:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active, .navbar.is-danger .navbar-start .navbar-link:focus, .navbar.is-danger .navbar-start .navbar-link:hover, .navbar.is-danger .navbar-start .navbar-link.is-active, .navbar.is-danger .navbar-end > a.navbar-item:focus, .navbar.is-danger .navbar-end > a.navbar-item:hover, .navbar.is-danger .navbar-end > a.navbar-item.is-active, .navbar.is-danger .navbar-end .navbar-link:focus, .navbar.is-danger .navbar-end .navbar-link:hover, .navbar.is-danger .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #ef2e55;
  }

  .navbar.is-danger .navbar-start .navbar-link:after, .navbar.is-danger .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-danger .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #ef2e55;
  }

  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #f14668;
  }
}

.navbar > .container {
  align-items: stretch;
  width: 100%;
  min-height: 6rem;
  display: flex;
}

.navbar.has-shadow {
  box-shadow: 0 2px #f5f5f5;
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: 30;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar.is-fixed-bottom {
  bottom: 0;
}

.navbar.is-fixed-bottom.has-shadow {
  box-shadow: 0 -2px #f5f5f5;
}

.navbar.is-fixed-top {
  top: 0;
}

html.has-navbar-fixed-top, body.has-navbar-fixed-top {
  padding-top: 6rem;
}

html.has-navbar-fixed-bottom, body.has-navbar-fixed-bottom {
  padding-bottom: 6rem;
}

.navbar-brand, .navbar-tabs {
  flex-shrink: 0;
  align-items: stretch;
  min-height: 6rem;
  display: flex;
}

.navbar-brand a.navbar-item:focus, .navbar-brand a.navbar-item:hover {
  background-color: #0000;
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow: auto hidden;
}

.navbar-burger {
  color: #4a4a4a;
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  width: 6rem;
  height: 6rem;
  margin-left: auto;
  display: block;
  position: relative;
}

.navbar-burger span {
  transform-origin: center;
  background-color: currentColor;
  width: 16px;
  height: 1px;
  transition-property: background-color, opacity, transform;
  transition-duration: 86ms;
  transition-timing-function: ease-out;
  display: block;
  position: absolute;
  left: calc(50% - 8px);
}

.navbar-burger span:first-child {
  top: calc(50% - 6px);
}

.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
}

.navbar-burger span:nth-child(3) {
  top: calc(50% + 4px);
}

.navbar-burger:hover {
  background-color: #0000000d;
}

.navbar-burger.is-active span:first-child {
  transform: translateY(5px)rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  transform: translateY(-5px)rotate(-45deg);
}

.navbar-menu {
  display: none;
}

.navbar-item, .navbar-link {
  color: #4a4a4a;
  padding: .5rem .75rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

.navbar-item .icon:only-child, .navbar-link .icon:only-child {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

a.navbar-item, .navbar-link {
  cursor: pointer;
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  color: #485fc7;
  background-color: #fafafa;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.navbar-item img {
  max-height: 4rem;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-item.is-tab {
  border-bottom: 1px solid #0000;
  min-height: 6rem;
  padding-bottom: calc(.5rem - 1px);
}

.navbar-item.is-tab:focus, .navbar-item.is-tab:hover {
  background-color: #0000;
  border-bottom-color: #485fc7;
}

.navbar-item.is-tab.is-active {
  color: #485fc7;
  background-color: #0000;
  border-bottom: 3px solid #485fc7;
  padding-bottom: calc(.5rem - 3px);
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em;
}

.navbar-link:not(.is-arrowless):after {
  border-color: #485fc7;
  margin-top: -.375em;
  right: 1.125em;
}

.navbar-dropdown {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  background-color: #f5f5f5;
  border: none;
  height: 2px;
  margin: .5rem 0;
  display: none;
}

@media screen and (width <= 1023px) {
  .navbar > .container {
    display: block;
  }

  .navbar-brand .navbar-item, .navbar-tabs .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar-link:after {
    display: none;
  }

  .navbar-menu {
    background-color: #fff;
    padding: .5rem 0;
    box-shadow: 0 8px 16px #0000001a;
  }

  .navbar-menu.is-active {
    display: block;
  }

  .navbar.is-fixed-bottom-touch, .navbar.is-fixed-top-touch {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-touch {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-touch.has-shadow {
    box-shadow: 0 -2px 3px #0000001a;
  }

  .navbar.is-fixed-top-touch {
    top: 0;
  }

  .navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 6rem);
    overflow: auto;
  }

  html.has-navbar-fixed-top-touch, body.has-navbar-fixed-top-touch {
    padding-top: 6rem;
  }

  html.has-navbar-fixed-bottom-touch, body.has-navbar-fixed-bottom-touch {
    padding-bottom: 6rem;
  }
}

@media screen and (width >= 1024px) {
  .navbar, .navbar-menu, .navbar-start, .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar {
    min-height: 6rem;
  }

  .navbar.is-spaced {
    padding: 1rem 2rem;
  }

  .navbar.is-spaced .navbar-start, .navbar.is-spaced .navbar-end {
    align-items: center;
  }

  .navbar.is-spaced a.navbar-item, .navbar.is-spaced .navbar-link {
    border-radius: 4px;
  }

  .navbar.is-transparent a.navbar-item:focus, .navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent a.navbar-item.is-active, .navbar.is-transparent .navbar-link:focus, .navbar.is-transparent .navbar-link:hover, .navbar.is-transparent .navbar-link.is-active, .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus-within .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link {
    background-color: #0000 !important;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item:focus, .navbar.is-transparent .navbar-dropdown a.navbar-item:hover {
    color: #000;
    background-color: #f5f5f5;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
    color: #485fc7;
    background-color: #f5f5f5;
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item, .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.has-dropdown-up .navbar-link:after {
    transform: rotate(135deg)translate(.25em, -.25em);
  }

  .navbar-item.has-dropdown-up .navbar-dropdown {
    border-top: none;
    border-bottom: 2px solid #dbdbdb;
    border-radius: 6px 6px 0 0;
    top: auto;
    bottom: 100%;
    box-shadow: 0 -8px 8px #0000001a;
  }

  .navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar.is-spaced .navbar-item.is-active .navbar-dropdown, .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    z-index: 20;
    background-color: #fff;
    border-top: 2px solid #dbdbdb;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    min-width: 100%;
    font-size: .875rem;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 8px 8px #0000001a;
  }

  .navbar-dropdown .navbar-item {
    white-space: nowrap;
    padding: .375rem 1rem;
  }

  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }

  .navbar-dropdown a.navbar-item:focus, .navbar-dropdown a.navbar-item:hover {
    color: #000;
    background-color: #f5f5f5;
  }

  .navbar-dropdown a.navbar-item.is-active {
    color: #485fc7;
    background-color: #f5f5f5;
  }

  .navbar.is-spaced .navbar-dropdown, .navbar-dropdown.is-boxed {
    opacity: 0;
    pointer-events: none;
    border-top: none;
    border-radius: 6px;
    transition-property: opacity, transform;
    transition-duration: 86ms;
    display: block;
    top: calc(100% - 4px);
    transform: translateY(-5px);
    box-shadow: 0 8px 8px #0000001a, 0 0 0 1px #0000001a;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-divider {
    display: block;
  }

  .navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
    margin-left: -.75rem;
  }

  .navbar > .container .navbar-menu, .container > .navbar .navbar-menu {
    margin-right: -.75rem;
  }

  .navbar.is-fixed-bottom-desktop, .navbar.is-fixed-top-desktop {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-desktop {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-desktop.has-shadow {
    box-shadow: 0 -2px 3px #0000001a;
  }

  .navbar.is-fixed-top-desktop {
    top: 0;
  }

  html.has-navbar-fixed-top-desktop, body.has-navbar-fixed-top-desktop {
    padding-top: 6rem;
  }

  html.has-navbar-fixed-bottom-desktop, body.has-navbar-fixed-bottom-desktop {
    padding-bottom: 6rem;
  }

  html.has-spaced-navbar-fixed-top, body.has-spaced-navbar-fixed-top {
    padding-top: 8rem;
  }

  html.has-spaced-navbar-fixed-bottom, body.has-spaced-navbar-fixed-bottom {
    padding-bottom: 8rem;
  }

  a.navbar-item.is-active, .navbar-link.is-active {
    color: #000;
  }

  a.navbar-item.is-active:not(:focus):not(:hover), .navbar-link.is-active:not(:focus):not(:hover) {
    background-color: #0000;
  }

  .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #fafafa;
  }
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 6rem);
}

.pagination {
  margin: -.25rem;
  font-size: 1rem;
}

.pagination.is-small {
  font-size: .75rem;
}

.pagination.is-medium {
  font-size: 1.25rem;
}

.pagination.is-large {
  font-size: 1.5rem;
}

.pagination.is-rounded .pagination-previous, .pagination.is-rounded .pagination-next {
  border-radius: 9999px;
  padding-left: 1em;
  padding-right: 1em;
}

.pagination.is-rounded .pagination-link {
  border-radius: 9999px;
}

.pagination, .pagination-list {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
  text-align: center;
  justify-content: center;
  margin: .25rem;
  padding-left: .5em;
  padding-right: .5em;
  font-size: 1em;
}

.pagination-previous, .pagination-next, .pagination-link {
  color: #363636;
  border-color: #dbdbdb;
  min-width: 2.5em;
}

.pagination-previous:hover, .pagination-next:hover, .pagination-link:hover {
  color: #363636;
  border-color: #b5b5b5;
}

.pagination-previous:focus, .pagination-next:focus, .pagination-link:focus {
  border-color: #485fc7;
}

.pagination-previous:active, .pagination-next:active, .pagination-link:active {
  box-shadow: inset 0 1px 2px #0003;
}

.pagination-previous[disabled], .pagination-previous.is-disabled, .pagination-next[disabled], .pagination-next.is-disabled, .pagination-link[disabled], .pagination-link.is-disabled {
  box-shadow: none;
  color: #7a7a7a;
  opacity: .5;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}

.pagination-previous, .pagination-next {
  white-space: nowrap;
  padding-left: .75em;
  padding-right: .75em;
}

.pagination-link.is-current {
  color: #fff;
  background-color: #485fc7;
  border-color: #485fc7;
}

.pagination-ellipsis {
  color: #b5b5b5;
  pointer-events: none;
}

.pagination-list {
  flex-wrap: wrap;
}

.pagination-list li {
  list-style: none;
}

@media screen and (width <= 768px) {
  .pagination {
    flex-wrap: wrap;
  }

  .pagination-previous, .pagination-next, .pagination-list li {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media screen and (width >= 769px), print {
  .pagination-list {
    flex-grow: 1;
    flex-shrink: 1;
    order: 1;
    justify-content: flex-start;
  }

  .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination-previous {
    order: 2;
  }

  .pagination-next {
    order: 3;
  }

  .pagination {
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
  }

  .pagination.is-centered .pagination-previous {
    order: 1;
  }

  .pagination.is-centered .pagination-list {
    order: 2;
    justify-content: center;
  }

  .pagination.is-centered .pagination-next {
    order: 3;
  }

  .pagination.is-right .pagination-previous {
    order: 1;
  }

  .pagination.is-right .pagination-next {
    order: 2;
  }

  .pagination.is-right .pagination-list {
    order: 3;
    justify-content: flex-end;
  }
}

.panel {
  border-radius: 6px;
  font-size: 1rem;
  box-shadow: 0 .5em 1em -.125em #0000001a, 0 0 0 1px #00000005;
}

.panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.panel.is-white .panel-heading {
  color: #000;
  background-color: #fff;
}

.panel.is-white .panel-tabs a.is-active {
  border-bottom-color: #fff;
}

.panel.is-white .panel-block.is-active .panel-icon {
  color: #fff;
}

.panel.is-black .panel-heading {
  color: #fff;
  background-color: #000;
}

.panel.is-black .panel-tabs a.is-active {
  border-bottom-color: #000;
}

.panel.is-black .panel-block.is-active .panel-icon {
  color: #000;
}

.panel.is-light .panel-heading {
  color: #000000b3;
  background-color: #f5f5f5;
}

.panel.is-light .panel-tabs a.is-active {
  border-bottom-color: #f5f5f5;
}

.panel.is-light .panel-block.is-active .panel-icon {
  color: #f5f5f5;
}

.panel.is-dark .panel-heading {
  color: #fff;
  background-color: #363636;
}

.panel.is-dark .panel-tabs a.is-active {
  border-bottom-color: #363636;
}

.panel.is-dark .panel-block.is-active .panel-icon {
  color: #363636;
}

.panel.is-primary .panel-heading {
  color: #fff;
  background-color: #00d1b2;
}

.panel.is-primary .panel-tabs a.is-active {
  border-bottom-color: #00d1b2;
}

.panel.is-primary .panel-block.is-active .panel-icon {
  color: #00d1b2;
}

.panel.is-link .panel-heading {
  color: #fff;
  background-color: #485fc7;
}

.panel.is-link .panel-tabs a.is-active {
  border-bottom-color: #485fc7;
}

.panel.is-link .panel-block.is-active .panel-icon {
  color: #485fc7;
}

.panel.is-info .panel-heading {
  color: #fff;
  background-color: #3e8ed0;
}

.panel.is-info .panel-tabs a.is-active {
  border-bottom-color: #3e8ed0;
}

.panel.is-info .panel-block.is-active .panel-icon {
  color: #3e8ed0;
}

.panel.is-success .panel-heading {
  color: #fff;
  background-color: #48c78e;
}

.panel.is-success .panel-tabs a.is-active {
  border-bottom-color: #48c78e;
}

.panel.is-success .panel-block.is-active .panel-icon {
  color: #48c78e;
}

.panel.is-warning .panel-heading {
  color: #000000b3;
  background-color: #ffe08a;
}

.panel.is-warning .panel-tabs a.is-active {
  border-bottom-color: #ffe08a;
}

.panel.is-warning .panel-block.is-active .panel-icon {
  color: #ffe08a;
}

.panel.is-danger .panel-heading {
  color: #fff;
  background-color: #f14668;
}

.panel.is-danger .panel-tabs a.is-active {
  border-bottom-color: #f14668;
}

.panel.is-danger .panel-block.is-active .panel-icon {
  color: #f14668;
}

.panel-tabs:not(:last-child), .panel-block:not(:last-child) {
  border-bottom: 1px solid #ededed;
}

.panel-heading {
  color: #363636;
  background-color: #ededed;
  border-radius: 6px 6px 0 0;
  padding: .75em 1em;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
}

.panel-tabs {
  justify-content: center;
  align-items: flex-end;
  font-size: .875em;
  display: flex;
}

.panel-tabs a {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: -1px;
  padding: .5em;
}

.panel-tabs a.is-active {
  color: #363636;
  border-bottom-color: #4a4a4a;
}

.panel-list a {
  color: #4a4a4a;
}

.panel-list a:hover {
  color: #485fc7;
}

.panel-block {
  color: #363636;
  justify-content: flex-start;
  align-items: center;
  padding: .5em .75em;
  display: flex;
}

.panel-block input[type="checkbox"] {
  margin-right: .75em;
}

.panel-block > .control {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}

.panel-block.is-wrapped {
  flex-wrap: wrap;
}

.panel-block.is-active {
  color: #363636;
  border-left-color: #485fc7;
}

.panel-block.is-active .panel-icon {
  color: #485fc7;
}

.panel-block:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

a.panel-block, label.panel-block {
  cursor: pointer;
}

a.panel-block:hover, label.panel-block:hover {
  background-color: #f5f5f5;
}

.panel-icon {
  text-align: center;
  vertical-align: top;
  color: #7a7a7a;
  width: 1em;
  height: 1em;
  margin-right: .75em;
  font-size: 14px;
  line-height: 1em;
  display: inline-block;
}

.panel-icon .fa {
  font-size: inherit;
  line-height: inherit;
}

.tabs {
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  justify-content: space-between;
  align-items: stretch;
  font-size: 1rem;
  display: flex;
  overflow: auto hidden;
}

.tabs a {
  color: #4a4a4a;
  vertical-align: top;
  border-bottom: 1px solid #dbdbdb;
  justify-content: center;
  align-items: center;
  margin-bottom: -1px;
  padding: .5em 1em;
  display: flex;
}

.tabs a:hover {
  color: #363636;
  border-bottom-color: #363636;
}

.tabs li {
  display: block;
}

.tabs li.is-active a {
  color: #485fc7;
  border-bottom-color: #485fc7;
}

.tabs ul {
  border-bottom: 1px solid #dbdbdb;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.tabs ul.is-left {
  padding-right: .75em;
}

.tabs ul.is-center {
  flex: none;
  justify-content: center;
  padding-left: .75em;
  padding-right: .75em;
}

.tabs ul.is-right {
  justify-content: flex-end;
  padding-left: .75em;
}

.tabs .icon:first-child {
  margin-right: .5em;
}

.tabs .icon:last-child {
  margin-left: .5em;
}

.tabs.is-centered ul {
  justify-content: center;
}

.tabs.is-right ul {
  justify-content: flex-end;
}

.tabs.is-boxed a {
  border: 1px solid #0000;
  border-radius: 4px 4px 0 0;
}

.tabs.is-boxed a:hover {
  background-color: #f5f5f5;
  border-bottom-color: #dbdbdb;
}

.tabs.is-boxed li.is-active a {
  background-color: #fff;
  border-color: #dbdbdb;
  border-bottom-color: #0000 !important;
}

.tabs.is-fullwidth li {
  flex-grow: 1;
  flex-shrink: 0;
}

.tabs.is-toggle a {
  border: 1px solid #dbdbdb;
  margin-bottom: 0;
  position: relative;
}

.tabs.is-toggle a:hover {
  z-index: 2;
  background-color: #f5f5f5;
  border-color: #b5b5b5;
}

.tabs.is-toggle li + li {
  margin-left: -1px;
}

.tabs.is-toggle li:first-child a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tabs.is-toggle li:last-child a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tabs.is-toggle li.is-active a {
  color: #fff;
  z-index: 1;
  background-color: #485fc7;
  border-color: #485fc7;
}

.tabs.is-toggle ul {
  border-bottom: none;
}

.tabs.is-toggle.is-toggle-rounded li:first-child a {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
  padding-left: 1.25em;
}

.tabs.is-toggle.is-toggle-rounded li:last-child a {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  padding-right: 1.25em;
}

.tabs.is-small {
  font-size: .75rem;
}

.tabs.is-medium {
  font-size: 1.25rem;
}

.tabs.is-large {
  font-size: 1.5rem;
}

.column {
  flex: 1 1 0;
  padding: .75rem;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  width: unset;
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (width <= 768px) {
  .column.is-narrow-mobile {
    width: unset;
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-mobile {
    margin-left: 0%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (width >= 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    width: unset;
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (width <= 1023px) {
  .column.is-narrow-touch {
    width: unset;
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-0-touch {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-touch {
    margin-left: 0%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (width >= 1024px) {
  .column.is-narrow-desktop {
    width: unset;
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-desktop {
    margin-left: 0%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (width >= 1216px) {
  .column.is-narrow-widescreen {
    width: unset;
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (width >= 1408px) {
  .column.is-narrow-fullhd {
    width: unset;
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.columns:last-child {
  margin-bottom: -.75rem;
}

.columns:not(:last-child) {
  margin-bottom: .75rem;
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (width >= 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable > .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: .25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: .25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: .25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: .5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: .5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: .5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: .75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: .75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: .75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (width <= 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.tile {
  flex: 1 1 0;
  align-items: stretch;
  min-height: min-content;
  display: block;
}

.tile.is-ancestor {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.tile.is-ancestor:last-child {
  margin-bottom: -.75rem;
}

.tile.is-ancestor:not(:last-child) {
  margin-bottom: .75rem;
}

.tile.is-child {
  margin: 0 !important;
}

.tile.is-parent {
  padding: .75rem;
}

.tile.is-vertical {
  flex-direction: column;
}

.tile.is-vertical > .tile.is-child:not(:last-child) {
  margin-bottom: 1.5rem !important;
}

@media screen and (width >= 769px), print {
  .tile:not(.is-child) {
    display: flex;
  }

  .tile.is-1 {
    flex: none;
    width: 8.33333%;
  }

  .tile.is-2 {
    flex: none;
    width: 16.6667%;
  }

  .tile.is-3 {
    flex: none;
    width: 25%;
  }

  .tile.is-4 {
    flex: none;
    width: 33.3333%;
  }

  .tile.is-5 {
    flex: none;
    width: 41.6667%;
  }

  .tile.is-6 {
    flex: none;
    width: 50%;
  }

  .tile.is-7 {
    flex: none;
    width: 58.3333%;
  }

  .tile.is-8 {
    flex: none;
    width: 66.6667%;
  }

  .tile.is-9 {
    flex: none;
    width: 75%;
  }

  .tile.is-10 {
    flex: none;
    width: 83.3333%;
  }

  .tile.is-11 {
    flex: none;
    width: 91.6667%;
  }

  .tile.is-12 {
    flex: none;
    width: 100%;
  }
}

.has-text-white {
  color: #fff !important;
}

a.has-text-white:hover, a.has-text-white:focus {
  color: #e6e6e6 !important;
}

.has-background-white {
  background-color: #fff !important;
}

.has-text-black, a.has-text-black:hover, a.has-text-black:focus {
  color: #000 !important;
}

.has-background-black {
  background-color: #000 !important;
}

.has-text-light {
  color: #f5f5f5 !important;
}

a.has-text-light:hover, a.has-text-light:focus {
  color: #dbdbdb !important;
}

.has-background-light {
  background-color: #f5f5f5 !important;
}

.has-text-dark {
  color: #363636 !important;
}

a.has-text-dark:hover, a.has-text-dark:focus {
  color: #1c1c1c !important;
}

.has-background-dark {
  background-color: #363636 !important;
}

.has-text-primary {
  color: #00d1b2 !important;
}

a.has-text-primary:hover, a.has-text-primary:focus {
  color: #009e86 !important;
}

.has-background-primary {
  background-color: #00d1b2 !important;
}

.has-text-primary-light {
  color: #ebfffc !important;
}

a.has-text-primary-light:hover, a.has-text-primary-light:focus {
  color: #b8fff4 !important;
}

.has-background-primary-light {
  background-color: #ebfffc !important;
}

.has-text-primary-dark {
  color: #00947e !important;
}

a.has-text-primary-dark:hover, a.has-text-primary-dark:focus {
  color: #00c7a9 !important;
}

.has-background-primary-dark {
  background-color: #00947e !important;
}

.has-text-link {
  color: #485fc7 !important;
}

a.has-text-link:hover, a.has-text-link:focus {
  color: #3449a8 !important;
}

.has-background-link {
  background-color: #485fc7 !important;
}

.has-text-link-light {
  color: #eff1fa !important;
}

a.has-text-link-light:hover, a.has-text-link-light:focus {
  color: #c8cfee !important;
}

.has-background-link-light {
  background-color: #eff1fa !important;
}

.has-text-link-dark {
  color: #3850b7 !important;
}

a.has-text-link-dark:hover, a.has-text-link-dark:focus {
  color: #576dcb !important;
}

.has-background-link-dark {
  background-color: #3850b7 !important;
}

.has-text-info {
  color: #3e8ed0 !important;
}

a.has-text-info:hover, a.has-text-info:focus {
  color: #2b74b1 !important;
}

.has-background-info {
  background-color: #3e8ed0 !important;
}

.has-text-info-light {
  color: #eff5fb !important;
}

a.has-text-info-light:hover, a.has-text-info-light:focus {
  color: #c6ddf1 !important;
}

.has-background-info-light {
  background-color: #eff5fb !important;
}

.has-text-info-dark {
  color: #296fa8 !important;
}

a.has-text-info-dark:hover, a.has-text-info-dark:focus {
  color: #368ace !important;
}

.has-background-info-dark {
  background-color: #296fa8 !important;
}

.has-text-success {
  color: #48c78e !important;
}

a.has-text-success:hover, a.has-text-success:focus {
  color: #34a873 !important;
}

.has-background-success {
  background-color: #48c78e !important;
}

.has-text-success-light {
  color: #effaf5 !important;
}

a.has-text-success-light:hover, a.has-text-success-light:focus {
  color: #c8eedd !important;
}

.has-background-success-light {
  background-color: #effaf5 !important;
}

.has-text-success-dark {
  color: #257953 !important;
}

a.has-text-success-dark:hover, a.has-text-success-dark:focus {
  color: #31a06e !important;
}

.has-background-success-dark {
  background-color: #257953 !important;
}

.has-text-warning {
  color: #ffe08a !important;
}

a.has-text-warning:hover, a.has-text-warning:focus {
  color: #ffd257 !important;
}

.has-background-warning {
  background-color: #ffe08a !important;
}

.has-text-warning-light {
  color: #fffaeb !important;
}

a.has-text-warning-light:hover, a.has-text-warning-light:focus {
  color: #ffecb8 !important;
}

.has-background-warning-light {
  background-color: #fffaeb !important;
}

.has-text-warning-dark {
  color: #946c00 !important;
}

a.has-text-warning-dark:hover, a.has-text-warning-dark:focus {
  color: #c79200 !important;
}

.has-background-warning-dark {
  background-color: #946c00 !important;
}

.has-text-danger {
  color: #f14668 !important;
}

a.has-text-danger:hover, a.has-text-danger:focus {
  color: #ee1742 !important;
}

.has-background-danger {
  background-color: #f14668 !important;
}

.has-text-danger-light {
  color: #feecf0 !important;
}

a.has-text-danger-light:hover, a.has-text-danger-light:focus {
  color: #fabdc9 !important;
}

.has-background-danger-light {
  background-color: #feecf0 !important;
}

.has-text-danger-dark {
  color: #cc0f35 !important;
}

a.has-text-danger-dark:hover, a.has-text-danger-dark:focus {
  color: #ee2049 !important;
}

.has-background-danger-dark {
  background-color: #cc0f35 !important;
}

.has-text-black-bis {
  color: #121212 !important;
}

.has-background-black-bis {
  background-color: #121212 !important;
}

.has-text-black-ter {
  color: #242424 !important;
}

.has-background-black-ter {
  background-color: #242424 !important;
}

.has-text-grey-darker {
  color: #363636 !important;
}

.has-background-grey-darker {
  background-color: #363636 !important;
}

.has-text-grey-dark {
  color: #4a4a4a !important;
}

.has-background-grey-dark {
  background-color: #4a4a4a !important;
}

.has-text-grey {
  color: #7a7a7a !important;
}

.has-background-grey {
  background-color: #7a7a7a !important;
}

.has-text-grey-light {
  color: #b5b5b5 !important;
}

.has-background-grey-light {
  background-color: #b5b5b5 !important;
}

.has-text-grey-lighter {
  color: #dbdbdb !important;
}

.has-background-grey-lighter {
  background-color: #dbdbdb !important;
}

.has-text-white-ter {
  color: #f5f5f5 !important;
}

.has-background-white-ter {
  background-color: #f5f5f5 !important;
}

.has-text-white-bis {
  color: #fafafa !important;
}

.has-background-white-bis {
  background-color: #fafafa !important;
}

.is-flex-direction-row {
  flex-direction: row !important;
}

.is-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.is-flex-direction-column {
  flex-direction: column !important;
}

.is-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}

.is-flex-wrap-wrap {
  flex-wrap: wrap !important;
}

.is-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.is-justify-content-flex-start {
  justify-content: flex-start !important;
}

.is-justify-content-flex-end {
  justify-content: flex-end !important;
}

.is-justify-content-center {
  justify-content: center !important;
}

.is-justify-content-space-between {
  justify-content: space-between !important;
}

.is-justify-content-space-around {
  justify-content: space-around !important;
}

.is-justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.is-justify-content-start {
  justify-content: start !important;
}

.is-justify-content-end {
  justify-content: end !important;
}

.is-justify-content-left {
  justify-content: left !important;
}

.is-justify-content-right {
  justify-content: right !important;
}

.is-align-content-flex-start {
  align-content: flex-start !important;
}

.is-align-content-flex-end {
  align-content: flex-end !important;
}

.is-align-content-center {
  align-content: center !important;
}

.is-align-content-space-between {
  align-content: space-between !important;
}

.is-align-content-space-around {
  align-content: space-around !important;
}

.is-align-content-space-evenly {
  align-content: space-evenly !important;
}

.is-align-content-stretch {
  align-content: stretch !important;
}

.is-align-content-start {
  align-content: start !important;
}

.is-align-content-end {
  align-content: end !important;
}

.is-align-content-baseline {
  align-content: baseline !important;
}

.is-align-items-stretch {
  align-items: stretch !important;
}

.is-align-items-flex-start {
  align-items: flex-start !important;
}

.is-align-items-flex-end {
  align-items: flex-end !important;
}

.is-align-items-center {
  align-items: center !important;
}

.is-align-items-baseline {
  align-items: baseline !important;
}

.is-align-items-start {
  align-items: start !important;
}

.is-align-items-end {
  align-items: end !important;
}

.is-align-items-self-start {
  align-items: self-start !important;
}

.is-align-items-self-end {
  align-items: self-end !important;
}

.is-align-self-auto {
  align-self: auto !important;
}

.is-align-self-flex-start {
  align-self: flex-start !important;
}

.is-align-self-flex-end {
  align-self: flex-end !important;
}

.is-align-self-center {
  align-self: center !important;
}

.is-align-self-baseline {
  align-self: baseline !important;
}

.is-align-self-stretch {
  align-self: stretch !important;
}

.is-flex-grow-0 {
  flex-grow: 0 !important;
}

.is-flex-grow-1 {
  flex-grow: 1 !important;
}

.is-flex-grow-2 {
  flex-grow: 2 !important;
}

.is-flex-grow-3 {
  flex-grow: 3 !important;
}

.is-flex-grow-4 {
  flex-grow: 4 !important;
}

.is-flex-grow-5 {
  flex-grow: 5 !important;
}

.is-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.is-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.is-flex-shrink-2 {
  flex-shrink: 2 !important;
}

.is-flex-shrink-3 {
  flex-shrink: 3 !important;
}

.is-flex-shrink-4 {
  flex-shrink: 4 !important;
}

.is-flex-shrink-5 {
  flex-shrink: 5 !important;
}

.is-clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-clickable {
  cursor: pointer !important;
  pointer-events: all !important;
}

.is-clipped {
  overflow: hidden !important;
}

.is-relative {
  position: relative !important;
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.m-3 {
  margin: .75rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.mr-3 {
  margin-right: .75rem !important;
}

.mb-3 {
  margin-bottom: .75rem !important;
}

.ml-3 {
  margin-left: .75rem !important;
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.mt-5 {
  margin-top: 1.5rem !important;
}

.mr-5 {
  margin-right: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 1.5rem !important;
}

.ml-5 {
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.pt-3 {
  padding-top: .75rem !important;
}

.pr-3 {
  padding-right: .75rem !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.pl-3 {
  padding-left: .75rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-5 {
  padding-top: 1.5rem !important;
}

.pr-5 {
  padding-right: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 1.5rem !important;
}

.pl-5 {
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.p-auto {
  padding: auto !important;
}

.pt-auto {
  padding-top: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.is-size-1 {
  font-size: 3rem !important;
}

.is-size-2 {
  font-size: 2.5rem !important;
}

.is-size-3 {
  font-size: 2rem !important;
}

.is-size-4 {
  font-size: 1.5rem !important;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.is-size-6 {
  font-size: 1rem !important;
}

.is-size-7 {
  font-size: .75rem !important;
}

@media screen and (width <= 768px) {
  .is-size-1-mobile {
    font-size: 3rem !important;
  }

  .is-size-2-mobile {
    font-size: 2.5rem !important;
  }

  .is-size-3-mobile {
    font-size: 2rem !important;
  }

  .is-size-4-mobile {
    font-size: 1.5rem !important;
  }

  .is-size-5-mobile {
    font-size: 1.25rem !important;
  }

  .is-size-6-mobile {
    font-size: 1rem !important;
  }

  .is-size-7-mobile {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 769px), print {
  .is-size-1-tablet {
    font-size: 3rem !important;
  }

  .is-size-2-tablet {
    font-size: 2.5rem !important;
  }

  .is-size-3-tablet {
    font-size: 2rem !important;
  }

  .is-size-4-tablet {
    font-size: 1.5rem !important;
  }

  .is-size-5-tablet {
    font-size: 1.25rem !important;
  }

  .is-size-6-tablet {
    font-size: 1rem !important;
  }

  .is-size-7-tablet {
    font-size: .75rem !important;
  }
}

@media screen and (width <= 1023px) {
  .is-size-1-touch {
    font-size: 3rem !important;
  }

  .is-size-2-touch {
    font-size: 2.5rem !important;
  }

  .is-size-3-touch {
    font-size: 2rem !important;
  }

  .is-size-4-touch {
    font-size: 1.5rem !important;
  }

  .is-size-5-touch {
    font-size: 1.25rem !important;
  }

  .is-size-6-touch {
    font-size: 1rem !important;
  }

  .is-size-7-touch {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1024px) {
  .is-size-1-desktop {
    font-size: 3rem !important;
  }

  .is-size-2-desktop {
    font-size: 2.5rem !important;
  }

  .is-size-3-desktop {
    font-size: 2rem !important;
  }

  .is-size-4-desktop {
    font-size: 1.5rem !important;
  }

  .is-size-5-desktop {
    font-size: 1.25rem !important;
  }

  .is-size-6-desktop {
    font-size: 1rem !important;
  }

  .is-size-7-desktop {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1216px) {
  .is-size-1-widescreen {
    font-size: 3rem !important;
  }

  .is-size-2-widescreen {
    font-size: 2.5rem !important;
  }

  .is-size-3-widescreen {
    font-size: 2rem !important;
  }

  .is-size-4-widescreen {
    font-size: 1.5rem !important;
  }

  .is-size-5-widescreen {
    font-size: 1.25rem !important;
  }

  .is-size-6-widescreen {
    font-size: 1rem !important;
  }

  .is-size-7-widescreen {
    font-size: .75rem !important;
  }
}

@media screen and (width >= 1408px) {
  .is-size-1-fullhd {
    font-size: 3rem !important;
  }

  .is-size-2-fullhd {
    font-size: 2.5rem !important;
  }

  .is-size-3-fullhd {
    font-size: 2rem !important;
  }

  .is-size-4-fullhd {
    font-size: 1.5rem !important;
  }

  .is-size-5-fullhd {
    font-size: 1.25rem !important;
  }

  .is-size-6-fullhd {
    font-size: 1rem !important;
  }

  .is-size-7-fullhd {
    font-size: .75rem !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (width <= 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (width <= 1023px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (width >= 1024px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1216px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1408px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary {
  font-family: Favorit, serif !important;
}

.is-family-secondary, .is-family-sans-serif {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-monospace, .is-family-code {
  font-family: monospace !important;
}

.is-block {
  display: block !important;
}

@media screen and (width <= 768px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (width <= 1023px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (width >= 1024px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (width >= 1216px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (width >= 1408px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (width <= 768px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (width <= 1023px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (width >= 1408px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (width <= 768px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (width <= 768px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (width <= 768px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (width <= 1023px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1024px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1216px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1408px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: none !important;
  width: .01em !important;
  height: .01em !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media screen and (width <= 768px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media screen and (width >= 769px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (width <= 1023px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (width >= 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (width >= 1216px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (width >= 1408px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-invisible {
  visibility: hidden !important;
}

@media screen and (width <= 768px) {
  .is-invisible-mobile {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px), print {
  .is-invisible-tablet {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px) and (width <= 1023px) {
  .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}

@media screen and (width <= 1023px) {
  .is-invisible-touch {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1024px) {
  .is-invisible-desktop {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1024px) and (width <= 1215px) {
  .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1216px) {
  .is-invisible-widescreen {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1216px) and (width <= 1407px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1408px) {
  .is-invisible-fullhd {
    visibility: hidden !important;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .navbar {
  background: none;
}

.hero .tabs ul {
  border-bottom: none;
}

.hero.is-white {
  color: #000;
  background-color: #fff;
}

.hero.is-white a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-white strong {
  color: inherit;
}

.hero.is-white .title {
  color: #000;
}

.hero.is-white .subtitle {
  color: #000000e6;
}

.hero.is-white .subtitle a:not(.button), .hero.is-white .subtitle strong {
  color: #000;
}

@media screen and (width <= 1023px) {
  .hero.is-white .navbar-menu {
    background-color: #fff;
  }
}

.hero.is-white .navbar-item, .hero.is-white .navbar-link {
  color: #000000b3;
}

.hero.is-white a.navbar-item:hover, .hero.is-white a.navbar-item.is-active, .hero.is-white .navbar-link:hover, .hero.is-white .navbar-link.is-active {
  color: #000;
  background-color: #f2f2f2;
}

.hero.is-white .tabs a {
  color: #000;
  opacity: .9;
}

.hero.is-white .tabs a:hover {
  opacity: 1;
}

.hero.is-white .tabs li.is-active a {
  opacity: 1;
  color: #fff !important;
}

.hero.is-white .tabs.is-boxed a, .hero.is-white .tabs.is-toggle a {
  color: #000;
}

.hero.is-white .tabs.is-boxed a:hover, .hero.is-white .tabs.is-toggle a:hover {
  background-color: #0000001a;
}

.hero.is-white .tabs.is-boxed li.is-active a, .hero.is-white .tabs.is-boxed li.is-active a:hover, .hero.is-white .tabs.is-toggle li.is-active a, .hero.is-white .tabs.is-toggle li.is-active a:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
}

@media screen and (width <= 768px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
  }
}

.hero.is-black {
  color: #fff;
  background-color: #000;
}

.hero.is-black a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-black strong {
  color: inherit;
}

.hero.is-black .title {
  color: #fff;
}

.hero.is-black .subtitle {
  color: #ffffffe6;
}

.hero.is-black .subtitle a:not(.button), .hero.is-black .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-black .navbar-menu {
    background-color: #000;
  }
}

.hero.is-black .navbar-item, .hero.is-black .navbar-link {
  color: #ffffffb3;
}

.hero.is-black a.navbar-item:hover, .hero.is-black a.navbar-item.is-active, .hero.is-black .navbar-link:hover, .hero.is-black .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.hero.is-black .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-black .tabs a:hover {
  opacity: 1;
}

.hero.is-black .tabs li.is-active a {
  opacity: 1;
  color: #000 !important;
}

.hero.is-black .tabs.is-boxed a, .hero.is-black .tabs.is-toggle a {
  color: #fff;
}

.hero.is-black .tabs.is-boxed a:hover, .hero.is-black .tabs.is-toggle a:hover {
  background-color: #0000001a;
}

.hero.is-black .tabs.is-boxed li.is-active a, .hero.is-black .tabs.is-boxed li.is-active a:hover, .hero.is-black .tabs.is-toggle li.is-active a, .hero.is-black .tabs.is-toggle li.is-active a:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, #000 0% 71%, #0d0c0c 100%);
}

@media screen and (width <= 768px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #000 0% 71%, #0d0c0c 100%);
  }
}

.hero.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.hero.is-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-light strong {
  color: inherit;
}

.hero.is-light .title {
  color: #000000b3;
}

.hero.is-light .subtitle {
  color: #000000e6;
}

.hero.is-light .subtitle a:not(.button), .hero.is-light .subtitle strong {
  color: #000000b3;
}

@media screen and (width <= 1023px) {
  .hero.is-light .navbar-menu {
    background-color: #f5f5f5;
  }
}

.hero.is-light .navbar-item, .hero.is-light .navbar-link {
  color: #000000b3;
}

.hero.is-light a.navbar-item:hover, .hero.is-light a.navbar-item.is-active, .hero.is-light .navbar-link:hover, .hero.is-light .navbar-link.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
}

.hero.is-light .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-light .tabs a:hover {
  opacity: 1;
}

.hero.is-light .tabs li.is-active a {
  opacity: 1;
  color: #f5f5f5 !important;
}

.hero.is-light .tabs.is-boxed a, .hero.is-light .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-light .tabs.is-boxed a:hover, .hero.is-light .tabs.is-toggle a:hover {
  background-color: #0000001a;
}

.hero.is-light .tabs.is-boxed li.is-active a, .hero.is-light .tabs.is-boxed li.is-active a:hover, .hero.is-light .tabs.is-toggle li.is-active a, .hero.is-light .tabs.is-toggle li.is-active a:hover {
  color: #f5f5f5;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
}

@media screen and (width <= 768px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
  }
}

.hero.is-dark {
  color: #fff;
  background-color: #363636;
}

.hero.is-dark a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-dark strong {
  color: inherit;
}

.hero.is-dark .title {
  color: #fff;
}

.hero.is-dark .subtitle {
  color: #ffffffe6;
}

.hero.is-dark .subtitle a:not(.button), .hero.is-dark .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-dark .navbar-menu {
    background-color: #363636;
  }
}

.hero.is-dark .navbar-item, .hero.is-dark .navbar-link {
  color: #ffffffb3;
}

.hero.is-dark a.navbar-item:hover, .hero.is-dark a.navbar-item.is-active, .hero.is-dark .navbar-link:hover, .hero.is-dark .navbar-link.is-active {
  color: #fff;
  background-color: #292929;
}

.hero.is-dark .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-dark .tabs a:hover {
  opacity: 1;
}

.hero.is-dark .tabs li.is-active a {
  opacity: 1;
  color: #363636 !important;
}

.hero.is-dark .tabs.is-boxed a, .hero.is-dark .tabs.is-toggle a {
  color: #fff;
}

.hero.is-dark .tabs.is-boxed a:hover, .hero.is-dark .tabs.is-toggle a:hover {
  background-color: #0000001a;
}

.hero.is-dark .tabs.is-boxed li.is-active a, .hero.is-dark .tabs.is-boxed li.is-active a:hover, .hero.is-dark .tabs.is-toggle li.is-active a, .hero.is-dark .tabs.is-toggle li.is-active a:hover {
  color: #363636;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
}

@media screen and (width <= 768px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
  }
}

.hero.is-primary {
  color: #fff;
  background-color: #00d1b2;
}

.hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-primary strong {
  color: inherit;
}

.hero.is-primary .title {
  color: #fff;
}

.hero.is-primary .subtitle {
  color: #ffffffe6;
}

.hero.is-primary .subtitle a:not(.button), .hero.is-primary .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-primary .navbar-menu {
    background-color: #00d1b2;
  }
}

.hero.is-primary .navbar-item, .hero.is-primary .navbar-link {
  color: #ffffffb3;
}

.hero.is-primary a.navbar-item:hover, .hero.is-primary a.navbar-item.is-active, .hero.is-primary .navbar-link:hover, .hero.is-primary .navbar-link.is-active {
  color: #fff;
  background-color: #00b89c;
}

.hero.is-primary .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-primary .tabs a:hover {
  opacity: 1;
}

.hero.is-primary .tabs li.is-active a {
  opacity: 1;
  color: #00d1b2 !important;
}

.hero.is-primary .tabs.is-boxed a, .hero.is-primary .tabs.is-toggle a {
  color: #fff;
}

.hero.is-primary .tabs.is-boxed a:hover, .hero.is-primary .tabs.is-toggle a:hover {
  background-color: #0000001a;
}

.hero.is-primary .tabs.is-boxed li.is-active a, .hero.is-primary .tabs.is-boxed li.is-active a:hover, .hero.is-primary .tabs.is-toggle li.is-active a, .hero.is-primary .tabs.is-toggle li.is-active a:hover {
  color: #00d1b2;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-primary.is-bold {
  background-image: linear-gradient(141deg, #009e6c 0%, #00d1b2 71%, #00e7eb 100%);
}

@media screen and (width <= 768px) {
  .hero.is-primary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #009e6c 0%, #00d1b2 71%, #00e7eb 100%);
  }
}

.hero.is-link {
  color: #fff;
  background-color: #485fc7;
}

.hero.is-link a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-link strong {
  color: inherit;
}

.hero.is-link .title {
  color: #fff;
}

.hero.is-link .subtitle {
  color: #ffffffe6;
}

.hero.is-link .subtitle a:not(.button), .hero.is-link .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-link .navbar-menu {
    background-color: #485fc7;
  }
}

.hero.is-link .navbar-item, .hero.is-link .navbar-link {
  color: #ffffffb3;
}

.hero.is-link a.navbar-item:hover, .hero.is-link a.navbar-item.is-active, .hero.is-link .navbar-link:hover, .hero.is-link .navbar-link.is-active {
  color: #fff;
  background-color: #3a51bb;
}

.hero.is-link .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-link .tabs a:hover {
  opacity: 1;
}

.hero.is-link .tabs li.is-active a {
  opacity: 1;
  color: #485fc7 !important;
}

.hero.is-link .tabs.is-boxed a, .hero.is-link .tabs.is-toggle a {
  color: #fff;
}

.hero.is-link .tabs.is-boxed a:hover, .hero.is-link .tabs.is-toggle a:hover {
  background-color: #0000001a;
}

.hero.is-link .tabs.is-boxed li.is-active a, .hero.is-link .tabs.is-boxed li.is-active a:hover, .hero.is-link .tabs.is-toggle li.is-active a, .hero.is-link .tabs.is-toggle li.is-active a:hover {
  color: #485fc7;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-link.is-bold {
  background-image: linear-gradient(141deg, #2959b3 0%, #485fc7 71%, #5658d2 100%);
}

@media screen and (width <= 768px) {
  .hero.is-link.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #2959b3 0%, #485fc7 71%, #5658d2 100%);
  }
}

.hero.is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.hero.is-info a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-info strong {
  color: inherit;
}

.hero.is-info .title {
  color: #fff;
}

.hero.is-info .subtitle {
  color: #ffffffe6;
}

.hero.is-info .subtitle a:not(.button), .hero.is-info .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-info .navbar-menu {
    background-color: #3e8ed0;
  }
}

.hero.is-info .navbar-item, .hero.is-info .navbar-link {
  color: #ffffffb3;
}

.hero.is-info a.navbar-item:hover, .hero.is-info a.navbar-item.is-active, .hero.is-info .navbar-link:hover, .hero.is-info .navbar-link.is-active {
  color: #fff;
  background-color: #3082c5;
}

.hero.is-info .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-info .tabs a:hover {
  opacity: 1;
}

.hero.is-info .tabs li.is-active a {
  opacity: 1;
  color: #3e8ed0 !important;
}

.hero.is-info .tabs.is-boxed a, .hero.is-info .tabs.is-toggle a {
  color: #fff;
}

.hero.is-info .tabs.is-boxed a:hover, .hero.is-info .tabs.is-toggle a:hover {
  background-color: #0000001a;
}

.hero.is-info .tabs.is-boxed li.is-active a, .hero.is-info .tabs.is-boxed li.is-active a:hover, .hero.is-info .tabs.is-toggle li.is-active a, .hero.is-info .tabs.is-toggle li.is-active a:hover {
  color: #3e8ed0;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, #208fbc 0%, #3e8ed0 71%, #4d83db 100%);
}

@media screen and (width <= 768px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #208fbc 0%, #3e8ed0 71%, #4d83db 100%);
  }
}

.hero.is-success {
  color: #fff;
  background-color: #48c78e;
}

.hero.is-success a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-success strong {
  color: inherit;
}

.hero.is-success .title {
  color: #fff;
}

.hero.is-success .subtitle {
  color: #ffffffe6;
}

.hero.is-success .subtitle a:not(.button), .hero.is-success .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-success .navbar-menu {
    background-color: #48c78e;
  }
}

.hero.is-success .navbar-item, .hero.is-success .navbar-link {
  color: #ffffffb3;
}

.hero.is-success a.navbar-item:hover, .hero.is-success a.navbar-item.is-active, .hero.is-success .navbar-link:hover, .hero.is-success .navbar-link.is-active {
  color: #fff;
  background-color: #3abb81;
}

.hero.is-success .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-success .tabs a:hover {
  opacity: 1;
}

.hero.is-success .tabs li.is-active a {
  opacity: 1;
  color: #48c78e !important;
}

.hero.is-success .tabs.is-boxed a, .hero.is-success .tabs.is-toggle a {
  color: #fff;
}

.hero.is-success .tabs.is-boxed a:hover, .hero.is-success .tabs.is-toggle a:hover {
  background-color: #0000001a;
}

.hero.is-success .tabs.is-boxed li.is-active a, .hero.is-success .tabs.is-boxed li.is-active a:hover, .hero.is-success .tabs.is-toggle li.is-active a, .hero.is-success .tabs.is-toggle li.is-active a:hover {
  color: #48c78e;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
}

@media screen and (width <= 768px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
  }
}

.hero.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.hero.is-warning a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-warning strong {
  color: inherit;
}

.hero.is-warning .title {
  color: #000000b3;
}

.hero.is-warning .subtitle {
  color: #000000e6;
}

.hero.is-warning .subtitle a:not(.button), .hero.is-warning .subtitle strong {
  color: #000000b3;
}

@media screen and (width <= 1023px) {
  .hero.is-warning .navbar-menu {
    background-color: #ffe08a;
  }
}

.hero.is-warning .navbar-item, .hero.is-warning .navbar-link {
  color: #000000b3;
}

.hero.is-warning a.navbar-item:hover, .hero.is-warning a.navbar-item.is-active, .hero.is-warning .navbar-link:hover, .hero.is-warning .navbar-link.is-active {
  color: #000000b3;
  background-color: #ffd970;
}

.hero.is-warning .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-warning .tabs a:hover {
  opacity: 1;
}

.hero.is-warning .tabs li.is-active a {
  opacity: 1;
  color: #ffe08a !important;
}

.hero.is-warning .tabs.is-boxed a, .hero.is-warning .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-warning .tabs.is-boxed a:hover, .hero.is-warning .tabs.is-toggle a:hover {
  background-color: #0000001a;
}

.hero.is-warning .tabs.is-boxed li.is-active a, .hero.is-warning .tabs.is-boxed li.is-active a:hover, .hero.is-warning .tabs.is-toggle li.is-active a, .hero.is-warning .tabs.is-toggle li.is-active a:hover {
  color: #ffe08a;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
}

@media screen and (width <= 768px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
  }
}

.hero.is-danger {
  color: #fff;
  background-color: #f14668;
}

.hero.is-danger a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-danger strong {
  color: inherit;
}

.hero.is-danger .title {
  color: #fff;
}

.hero.is-danger .subtitle {
  color: #ffffffe6;
}

.hero.is-danger .subtitle a:not(.button), .hero.is-danger .subtitle strong {
  color: #fff;
}

@media screen and (width <= 1023px) {
  .hero.is-danger .navbar-menu {
    background-color: #f14668;
  }
}

.hero.is-danger .navbar-item, .hero.is-danger .navbar-link {
  color: #ffffffb3;
}

.hero.is-danger a.navbar-item:hover, .hero.is-danger a.navbar-item.is-active, .hero.is-danger .navbar-link:hover, .hero.is-danger .navbar-link.is-active {
  color: #fff;
  background-color: #ef2e55;
}

.hero.is-danger .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-danger .tabs a:hover {
  opacity: 1;
}

.hero.is-danger .tabs li.is-active a {
  opacity: 1;
  color: #f14668 !important;
}

.hero.is-danger .tabs.is-boxed a, .hero.is-danger .tabs.is-toggle a {
  color: #fff;
}

.hero.is-danger .tabs.is-boxed a:hover, .hero.is-danger .tabs.is-toggle a:hover {
  background-color: #0000001a;
}

.hero.is-danger .tabs.is-boxed li.is-active a, .hero.is-danger .tabs.is-boxed li.is-active a:hover, .hero.is-danger .tabs.is-toggle li.is-active a, .hero.is-danger .tabs.is-toggle li.is-active a:hover {
  color: #f14668;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, #fa0a62 0%, #f14668 71%, #f7595f 100%);
}

@media screen and (width <= 768px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #fa0a62 0%, #f14668 71%, #f7595f 100%);
  }
}

.hero.is-small .hero-body {
  padding: 1.5rem;
}

@media screen and (width >= 769px), print {
  .hero.is-medium .hero-body {
    padding: 9rem 4.5rem;
  }

  .hero.is-large .hero-body {
    padding: 18rem 6rem;
  }
}

.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-halfheight .hero-body > .container, .hero.is-fullheight .hero-body > .container, .hero.is-fullheight-with-navbar .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.hero.is-halfheight {
  min-height: 50vh;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  overflow: hidden;
}

.hero-video video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.hero-video.is-transparent {
  opacity: .3;
}

@media screen and (width <= 768px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}

@media screen and (width <= 768px) {
  .hero-buttons .button {
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-bottom: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .hero-buttons {
    justify-content: center;
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.hero-head, .hero-foot {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}

@media screen and (width >= 769px), print {
  .hero-body {
    padding: 3rem;
  }
}

.section {
  padding: 3rem 1.5rem;
}

@media screen and (width >= 1024px) {
  .section {
    padding: 3rem;
  }

  .section.is-medium {
    padding: 9rem 4.5rem;
  }

  .section.is-large {
    padding: 18rem 6rem;
  }
}

.footer {
  color: #fff;
  background-color: #000;
  padding: 3rem 1.5rem 6rem;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
}

.fa, .fa-classic, .fa-sharp, .fas, .fa-solid, .far, .fa-regular, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fas, .fa-classic, .fa-solid, .far, .fa-regular {
  font-family: "Font Awesome 6 Free";
}

.fab, .fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .0833333em;
}

.fa-sm {
  vertical-align: .0535714em;
  font-size: .875em;
  line-height: .0714286em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .0416667em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, 100% {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0));
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  z-index: var(--fa-stack-z-index, auto);
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0:before {
  content: "0";
}

.fa-1:before {
  content: "1";
}

.fa-2:before {
  content: "2";
}

.fa-3:before {
  content: "3";
}

.fa-4:before {
  content: "4";
}

.fa-5:before {
  content: "5";
}

.fa-6:before {
  content: "6";
}

.fa-7:before {
  content: "7";
}

.fa-8:before {
  content: "8";
}

.fa-9:before {
  content: "9";
}

.fa-fill-drip:before {
  content: "";
}

.fa-arrows-to-circle:before {
  content: "";
}

.fa-circle-chevron-right:before, .fa-chevron-circle-right:before {
  content: "";
}

.fa-at:before {
  content: "@";
}

.fa-trash-can:before, .fa-trash-alt:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-user-xmark:before, .fa-user-times:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-message:before, .fa-comment-alt:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-down-left-and-up-right-to-center:before, .fa-compress-alt:before {
  content: "";
}

.fa-explosion:before {
  content: "";
}

.fa-file-lines:before, .fa-file-alt:before, .fa-file-text:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-building-un:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-calendar-days:before, .fa-calendar-alt:before {
  content: "";
}

.fa-anchor-circle-check:before {
  content: "";
}

.fa-building-circle-arrow-right:before {
  content: "";
}

.fa-volleyball:before, .fa-volleyball-ball:before {
  content: "";
}

.fa-arrows-up-to-line:before {
  content: "";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "";
}

.fa-circle-minus:before, .fa-minus-circle:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-right-from-bracket:before, .fa-sign-out-alt:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-icons:before, .fa-heart-music-camera-bolt:before {
  content: "";
}

.fa-microphone-lines-slash:before, .fa-microphone-alt-slash:before {
  content: "";
}

.fa-bridge-circle-check:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-magnifying-glass-location:before, .fa-search-location:before {
  content: "";
}

.fa-forward-step:before, .fa-step-forward:before {
  content: "";
}

.fa-face-smile-beam:before, .fa-smile-beam:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-football:before, .fa-football-ball:before {
  content: "";
}

.fa-school-circle-exclamation:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-angles-down:before, .fa-angle-double-down:before {
  content: "";
}

.fa-users-rectangle:before {
  content: "";
}

.fa-people-roof:before {
  content: "";
}

.fa-people-line:before {
  content: "";
}

.fa-beer-mug-empty:before, .fa-beer:before {
  content: "";
}

.fa-diagram-predecessor:before {
  content: "";
}

.fa-arrow-up-long:before, .fa-long-arrow-up:before {
  content: "";
}

.fa-fire-flame-simple:before, .fa-burn:before {
  content: "";
}

.fa-person:before, .fa-male:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-truck-plane:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-face-grin-stars:before, .fa-grin-stars:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-spaghetti-monster-flying:before, .fa-pastafarianism:before {
  content: "";
}

.fa-arrow-down-up-across-line:before {
  content: "";
}

.fa-spoon:before, .fa-utensil-spoon:before {
  content: "";
}

.fa-jar-wheat:before {
  content: "";
}

.fa-envelopes-bulk:before, .fa-mail-bulk:before {
  content: "";
}

.fa-file-circle-exclamation:before {
  content: "";
}

.fa-circle-h:before, .fa-hospital-symbol:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-address-book:before, .fa-contact-book:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-k:before {
  content: "K";
}

.fa-landmark-flag:before {
  content: "";
}

.fa-pencil:before, .fa-pencil-alt:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-paste:before, .fa-file-clipboard:before {
  content: "";
}

.fa-code-pull-request:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-truck-ramp-box:before, .fa-truck-loading:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-vial-virus:before {
  content: "";
}

.fa-sheet-plastic:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-person-arrow-up-from-line:before {
  content: "";
}

.fa-scroll-torah:before, .fa-torah:before {
  content: "";
}

.fa-broom-ball:before, .fa-quidditch:before, .fa-quidditch-broom-ball:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-box-archive:before, .fa-archive:before {
  content: "";
}

.fa-person-drowning:before {
  content: "";
}

.fa-arrow-down-9-1:before, .fa-sort-numeric-desc:before, .fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-face-grin-tongue-squint:before, .fa-grin-tongue-squint:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-w:before {
  content: "W";
}

.fa-earth-africa:before, .fa-globe-africa:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-tablet-screen-button:before, .fa-tablet-alt:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-trowel-bricks:before {
  content: "";
}

.fa-face-flushed:before, .fa-flushed:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-tent-arrow-left-right:before {
  content: "";
}

.fa-gavel:before, .fa-legal:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-bell-concierge:before, .fa-concierge-bell:before {
  content: "";
}

.fa-pen-ruler:before, .fa-pencil-ruler:before {
  content: "";
}

.fa-people-arrows:before, .fa-people-arrows-left-right:before {
  content: "";
}

.fa-mars-and-venus-burst:before {
  content: "";
}

.fa-square-caret-right:before, .fa-caret-square-right:before {
  content: "";
}

.fa-scissors:before, .fa-cut:before {
  content: "";
}

.fa-sun-plant-wilt:before {
  content: "";
}

.fa-toilets-portable:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magnifying-glass-arrow-right:before {
  content: "";
}

.fa-tachograph-digital:before, .fa-digital-tachograph:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-clover:before {
  content: "";
}

.fa-reply:before, .fa-mail-reply:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-house-fire:before {
  content: "";
}

.fa-square-minus:before, .fa-minus-square:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-square-caret-down:before, .fa-caret-square-down:before {
  content: "";
}

.fa-file-circle-question:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-bars:before, .fa-navicon:before {
  content: "";
}

.fa-people-group:before {
  content: "";
}

.fa-hourglass-end:before, .fa-hourglass-3:before {
  content: "";
}

.fa-heart-crack:before, .fa-heart-broken:before {
  content: "";
}

.fa-square-up-right:before, .fa-external-link-square-alt:before {
  content: "";
}

.fa-face-kiss-beam:before, .fa-kiss-beam:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-people-robbery:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-circle-exclamation:before, .fa-exclamation-circle:before {
  content: "";
}

.fa-school-circle-xmark:before {
  content: "";
}

.fa-arrow-right-from-bracket:before, .fa-sign-out:before {
  content: "";
}

.fa-circle-chevron-down:before, .fa-chevron-circle-down:before {
  content: "";
}

.fa-unlock-keyhole:before, .fa-unlock-alt:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-headphones-simple:before, .fa-headphones-alt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-circle-dollar-to-slot:before, .fa-donate:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-road-spikes:before {
  content: "";
}

.fa-fire-burner:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-volume-low:before, .fa-volume-down:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-wheat-awn:before, .fa-wheat-alt:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-hands-holding-child:before {
  content: "";
}

.fa-asterisk:before {
  content: "*";
}

.fa-square-check:before, .fa-check-square:before {
  content: "";
}

.fa-peseta-sign:before {
  content: "";
}

.fa-heading:before, .fa-header:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-list:before, .fa-list-squares:before {
  content: "";
}

.fa-square-phone-flip:before, .fa-phone-square-alt:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-circle-dot:before, .fa-dot-circle:before {
  content: "";
}

.fa-face-dizzy:before, .fa-dizzy:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-house-medical-circle-xmark:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-futbol:before, .fa-futbol-ball:before, .fa-soccer-ball:before {
  content: "";
}

.fa-paintbrush:before, .fa-paint-brush:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-hot-tub-person:before, .fa-hot-tub:before {
  content: "";
}

.fa-map-location:before, .fa-map-marked:before {
  content: "";
}

.fa-house-flood-water:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-bridge-lock:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-pen-to-square:before, .fa-edit:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-share-nodes:before, .fa-share-alt:before {
  content: "";
}

.fa-heart-circle-minus:before {
  content: "";
}

.fa-hourglass-half:before, .fa-hourglass-2:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-bag-shopping:before, .fa-shopping-bag:before {
  content: "";
}

.fa-arrow-down-z-a:before, .fa-sort-alpha-desc:before, .fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-person-rays:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-flask-vial:before {
  content: "";
}

.fa-hand:before, .fa-hand-paper:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-worm:before {
  content: "";
}

.fa-house-circle-xmark:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-face-kiss:before, .fa-kiss:before {
  content: "";
}

.fa-bridge-circle-xmark:before {
  content: "";
}

.fa-face-grin-tongue:before, .fa-grin-tongue:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-face-grin-wink:before, .fa-grin-wink:before {
  content: "";
}

.fa-ear-deaf:before, .fa-deaf:before, .fa-deafness:before, .fa-hard-of-hearing:before {
  content: "";
}

.fa-road-circle-check:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-square-rss:before, .fa-rss-square:before {
  content: "";
}

.fa-land-mine-on:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-stairs:before {
  content: "";
}

.fa-i:before {
  content: "I";
}

.fa-hryvnia-sign:before, .fa-hryvnia:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-face-grin-wide:before, .fa-grin-alt:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-v:before {
  content: "V";
}

.fa-bangladeshi-taka-sign:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-staff-snake:before, .fa-rod-asclepius:before, .fa-rod-snake:before, .fa-staff-aesculapius:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-truck-medical:before, .fa-ambulance:before {
  content: "";
}

.fa-wheat-awn-circle-exclamation:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-road-barrier:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-q:before {
  content: "Q";
}

.fa-g:before {
  content: "G";
}

.fa-notes-medical:before {
  content: "";
}

.fa-temperature-half:before, .fa-temperature-2:before, .fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-dong-sign:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-poo-storm:before, .fa-poo-bolt:before {
  content: "";
}

.fa-face-frown-open:before, .fa-frown-open:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-helmet-un:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-arrow-up-from-bracket:before {
  content: "";
}

.fa-folder:before, .fa-folder-blank:before {
  content: "";
}

.fa-file-waveform:before, .fa-file-medical-alt:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-chart-simple:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-gauge:before, .fa-dashboard:before, .fa-gauge-med:before, .fa-tachometer-alt-average:before {
  content: "";
}

.fa-wand-magic-sparkles:before, .fa-magic-wand-sparkles:before {
  content: "";
}

.fa-e:before {
  content: "E";
}

.fa-pen-clip:before, .fa-pen-alt:before {
  content: "";
}

.fa-bridge-circle-exclamation:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-school-circle-check:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-van-shuttle:before, .fa-shuttle-van:before {
  content: "";
}

.fa-building-user:before {
  content: "";
}

.fa-square-caret-left:before, .fa-caret-square-left:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-person-half-dress:before {
  content: "";
}

.fa-road-bridge:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-c:before {
  content: "C";
}

.fa-tablet-button:before {
  content: "";
}

.fa-building-lock:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-chart-area:before, .fa-area-chart:before {
  content: "";
}

.fa-house-flag:before {
  content: "";
}

.fa-person-circle-minus:before {
  content: "";
}

.fa-ban:before, .fa-cancel:before {
  content: "";
}

.fa-camera-rotate:before {
  content: "";
}

.fa-spray-can-sparkles:before, .fa-air-freshener:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-arrow-pointer:before, .fa-mouse-pointer:before {
  content: "";
}

.fa-maximize:before, .fa-expand-arrows-alt:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-shapes:before, .fa-triangle-circle-square:before {
  content: "";
}

.fa-shuffle:before, .fa-random:before {
  content: "";
}

.fa-person-running:before, .fa-running:before {
  content: "";
}

.fa-mobile-retro:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-hands-bound:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-plane-circle-exclamation:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-computer-mouse:before, .fa-mouse:before {
  content: "";
}

.fa-arrow-right-to-bracket:before, .fa-sign-in:before {
  content: "";
}

.fa-shop-slash:before, .fa-store-alt-slash:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-virus-covid-slash:before {
  content: "";
}

.fa-shop-lock:before {
  content: "";
}

.fa-hourglass-start:before, .fa-hourglass-1:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-building-wheat:before {
  content: "";
}

.fa-person-breastfeeding:before {
  content: "";
}

.fa-right-to-bracket:before, .fa-sign-in-alt:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-heart-pulse:before, .fa-heartbeat:before {
  content: "";
}

.fa-people-carry-box:before, .fa-people-carry:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-xmarks-lines:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-weight-scale:before, .fa-weight:before {
  content: "";
}

.fa-user-group:before, .fa-user-friends:before {
  content: "";
}

.fa-arrow-up-a-z:before, .fa-sort-alpha-up:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-face-laugh-squint:before, .fa-laugh-squint:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-circle-arrow-up:before, .fa-arrow-circle-up:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-person-walking:before, .fa-walking:before {
  content: "";
}

.fa-l:before {
  content: "L";
}

.fa-fire:before {
  content: "";
}

.fa-bed-pulse:before, .fa-procedures:before {
  content: "";
}

.fa-shuttle-space:before, .fa-space-shuttle:before {
  content: "";
}

.fa-face-laugh:before, .fa-laugh:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-heart-circle-plus:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-microphone-lines:before, .fa-microphone-alt:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-colon-sign:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-road-circle-xmark:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-mars-stroke-up:before, .fa-mars-stroke-v:before {
  content: "";
}

.fa-champagne-glasses:before, .fa-glass-cheers:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-house-circle-exclamation:before {
  content: "";
}

.fa-file-arrow-up:before, .fa-file-upload:before {
  content: "";
}

.fa-wifi:before, .fa-wifi-3:before, .fa-wifi-strong:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-user-pen:before, .fa-user-edit:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-anchor-lock:before {
  content: "";
}

.fa-building-ngo:before {
  content: "";
}

.fa-manat-sign:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-border-top-left:before, .fa-border-style:before {
  content: "";
}

.fa-map-location-dot:before, .fa-map-marked-alt:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-square-poll-vertical:before, .fa-poll:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-car-battery:before, .fa-battery-car:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-building-circle-check:before {
  content: "";
}

.fa-person-chalkboard:before {
  content: "";
}

.fa-mars-stroke-right:before, .fa-mars-stroke-h:before {
  content: "";
}

.fa-hand-back-fist:before, .fa-hand-rock:before {
  content: "";
}

.fa-square-caret-up:before, .fa-caret-square-up:before {
  content: "";
}

.fa-cloud-showers-water:before {
  content: "";
}

.fa-chart-bar:before, .fa-bar-chart:before {
  content: "";
}

.fa-hands-bubbles:before, .fa-hands-wash:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-eye-low-vision:before, .fa-low-vision:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-sailboat:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-square-plus:before, .fa-plus-square:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-bucket:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-folder-closed:before {
  content: "";
}

.fa-house-tsunami:before {
  content: "";
}

.fa-square-nfi:before {
  content: "";
}

.fa-arrow-up-from-ground-water:before {
  content: "";
}

.fa-martini-glass:before, .fa-glass-martini-alt:before {
  content: "";
}

.fa-rotate-left:before, .fa-rotate-back:before, .fa-rotate-backward:before, .fa-undo-alt:before {
  content: "";
}

.fa-table-columns:before, .fa-columns:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-dolly:before, .fa-dolly-box:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-minimize:before, .fa-compress-arrows-alt:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-angles-right:before, .fa-angle-double-right:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-circle-play:before, .fa-play-circle:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-euro-sign:before, .fa-eur:before, .fa-euro:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-circle-check:before, .fa-check-circle:before {
  content: "";
}

.fa-circle-stop:before, .fa-stop-circle:before {
  content: "";
}

.fa-compass-drafting:before, .fa-drafting-compass:before {
  content: "";
}

.fa-plate-wheat:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-person-shelter:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-face-laugh-beam:before, .fa-laugh-beam:before {
  content: "";
}

.fa-helicopter-symbol:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-circle-chevron-up:before, .fa-chevron-circle-up:before {
  content: "";
}

.fa-lari-sign:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right:before {
  content: "";
}

.fa-sterling-sign:before, .fa-gbp:before, .fa-pound-sign:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-square-person-confined:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-arrow-down-long:before, .fa-long-arrow-down:before {
  content: "";
}

.fa-tent-arrow-down-to-line:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-reply-all:before, .fa-mail-reply-all:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-person-skating:before, .fa-skating:before {
  content: "";
}

.fa-filter-circle-dollar:before, .fa-funnel-dollar:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-circle-arrow-down:before, .fa-arrow-circle-down:before {
  content: "";
}

.fa-file-import:before, .fa-arrow-right-to-file:before {
  content: "";
}

.fa-square-arrow-up-right:before, .fa-external-link-square:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-location-pin-lock:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-hill-avalanche:before {
  content: "";
}

.fa-temperature-empty:before, .fa-temperature-0:before, .fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-address-card:before, .fa-contact-card:before, .fa-vcard:before {
  content: "";
}

.fa-scale-unbalanced-flip:before, .fa-balance-scale-right:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-diamond-turn-right:before, .fa-directions:before {
  content: "";
}

.fa-burst:before {
  content: "";
}

.fa-house-laptop:before, .fa-laptop-house:before {
  content: "";
}

.fa-face-tired:before, .fa-tired:before {
  content: "";
}

.fa-money-bills:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-cloud-arrow-up:before, .fa-cloud-upload:before, .fa-cloud-upload-alt:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-arrows-turn-right:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-ferry:before {
  content: "";
}

.fa-arrows-down-to-people:before {
  content: "";
}

.fa-seedling:before, .fa-sprout:before {
  content: "";
}

.fa-left-right:before, .fa-arrows-alt-h:before {
  content: "";
}

.fa-boxes-packing:before {
  content: "";
}

.fa-circle-arrow-left:before, .fa-arrow-circle-left:before {
  content: "";
}

.fa-group-arrows-rotate:before {
  content: "";
}

.fa-bowl-food:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-arrow-down-wide-short:before, .fa-sort-amount-asc:before, .fa-sort-amount-down:before {
  content: "";
}

.fa-cloud-bolt:before, .fa-thunderstorm:before {
  content: "";
}

.fa-text-slash:before, .fa-remove-format:before {
  content: "";
}

.fa-face-smile-wink:before, .fa-smile-wink:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-arrows-left-right:before, .fa-arrows-h:before {
  content: "";
}

.fa-house-lock:before {
  content: "";
}

.fa-cloud-arrow-down:before, .fa-cloud-download:before, .fa-cloud-download-alt:before {
  content: "";
}

.fa-children:before {
  content: "";
}

.fa-chalkboard:before, .fa-blackboard:before {
  content: "";
}

.fa-user-large-slash:before, .fa-user-alt-slash:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-handshake-simple-slash:before, .fa-handshake-alt-slash:before {
  content: "";
}

.fa-mattress-pillow:before {
  content: "";
}

.fa-guarani-sign:before {
  content: "";
}

.fa-arrows-rotate:before, .fa-refresh:before, .fa-sync:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-cruzeiro-sign:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-shield-halved:before, .fa-shield-alt:before {
  content: "";
}

.fa-book-atlas:before, .fa-atlas:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-envelope-circle-check:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-arrows-to-dot:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-heart-circle-check:before {
  content: "";
}

.fa-house-chimney-crack:before, .fa-house-damage:before {
  content: "";
}

.fa-file-zipper:before, .fa-file-archive:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-martini-glass-empty:before, .fa-glass-martini:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cedi-sign:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-table-cells-column-lock:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-z:before {
  content: "Z";
}

.fa-person-skiing:before, .fa-skiing:before {
  content: "";
}

.fa-road-lock:before {
  content: "";
}

.fa-a:before {
  content: "A";
}

.fa-temperature-arrow-down:before, .fa-temperature-down:before {
  content: "";
}

.fa-feather-pointed:before, .fa-feather-alt:before {
  content: "";
}

.fa-p:before {
  content: "P";
}

.fa-snowflake:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-rectangle-ad:before, .fa-ad:before {
  content: "";
}

.fa-circle-arrow-right:before, .fa-arrow-circle-right:before {
  content: "";
}

.fa-filter-circle-xmark:before {
  content: "";
}

.fa-locust:before {
  content: "";
}

.fa-sort:before, .fa-unsorted:before {
  content: "";
}

.fa-list-ol:before, .fa-list-1-2:before, .fa-list-numeric:before {
  content: "";
}

.fa-person-dress-burst:before {
  content: "";
}

.fa-money-check-dollar:before, .fa-money-check-alt:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-face-kiss-wink-heart:before, .fa-kiss-wink-heart:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-question:before {
  content: "?";
}

.fa-file-signature:before {
  content: "";
}

.fa-up-down-left-right:before, .fa-arrows-alt:before {
  content: "";
}

.fa-house-chimney-user:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-star-half-stroke:before, .fa-star-half-alt:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-whiskey-glass:before, .fa-glass-whiskey:before {
  content: "";
}

.fa-building-circle-exclamation:before {
  content: "";
}

.fa-magnifying-glass-chart:before {
  content: "";
}

.fa-arrow-up-right-from-square:before, .fa-external-link:before {
  content: "";
}

.fa-cubes-stacked:before {
  content: "";
}

.fa-won-sign:before, .fa-krw:before, .fa-won:before {
  content: "";
}

.fa-virus-covid:before {
  content: "";
}

.fa-austral-sign:before {
  content: "";
}

.fa-f:before {
  content: "F";
}

.fa-leaf:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-taxi:before, .fa-cab:before {
  content: "";
}

.fa-person-circle-plus:before {
  content: "";
}

.fa-chart-pie:before, .fa-pie-chart:before {
  content: "";
}

.fa-bolt-lightning:before {
  content: "";
}

.fa-sack-xmark:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-fish-fins:before {
  content: "";
}

.fa-building-flag:before {
  content: "";
}

.fa-face-grin-beam:before, .fa-grin-beam:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-location-pin:before, .fa-map-marker:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-helmet-safety:before, .fa-hard-hat:before, .fa-hat-hard:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-circle-right:before, .fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-plane-circle-check:before {
  content: "";
}

.fa-face-rolling-eyes:before, .fa-meh-rolling-eyes:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-chart-line:before, .fa-line-chart:before {
  content: "";
}

.fa-mask-ventilator:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-signs-post:before, .fa-map-signs:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-person-circle-question:before {
  content: "";
}

.fa-h:before {
  content: "H";
}

.fa-tarp:before {
  content: "";
}

.fa-screwdriver-wrench:before, .fa-tools:before {
  content: "";
}

.fa-arrows-to-eye:before {
  content: "";
}

.fa-plug-circle-bolt:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-mars-and-venus:before {
  content: "";
}

.fa-house-user:before, .fa-home-user:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-house-crack:before {
  content: "";
}

.fa-martini-glass-citrus:before, .fa-cocktail:before {
  content: "";
}

.fa-face-surprise:before, .fa-surprise:before {
  content: "";
}

.fa-bottle-water:before {
  content: "";
}

.fa-circle-pause:before, .fa-pause-circle:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-apple-whole:before, .fa-apple-alt:before {
  content: "";
}

.fa-kitchen-set:before {
  content: "";
}

.fa-r:before {
  content: "R";
}

.fa-temperature-quarter:before, .fa-temperature-1:before, .fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-bitcoin-sign:before {
  content: "";
}

.fa-shield-dog:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-elevator:before {
  content: "";
}

.fa-money-bill-transfer:before {
  content: "";
}

.fa-money-bill-trend-up:before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right:before {
  content: "";
}

.fa-square-poll-horizontal:before, .fa-poll-h:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-backward-fast:before, .fa-fast-backward:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-basketball:before, .fa-basketball-ball:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-circle-up:before, .fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-mobile-screen-button:before, .fa-mobile-alt:before {
  content: "";
}

.fa-volume-high:before, .fa-volume-up:before {
  content: "";
}

.fa-users-rays:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-burger:before, .fa-hamburger:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-bugs:before {
  content: "";
}

.fa-rupee-sign:before, .fa-rupee:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-circle-question:before, .fa-question-circle:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-book-bookmark:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-bridge:before {
  content: "";
}

.fa-phone-flip:before, .fa-phone-alt:before {
  content: "";
}

.fa-truck-front:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-anchor-circle-exclamation:before {
  content: "";
}

.fa-truck-field:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-clipboard-question:before {
  content: "";
}

.fa-panorama:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-file-circle-minus:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-forward-fast:before, .fa-fast-forward:before {
  content: "";
}

.fa-face-meh-blank:before, .fa-meh-blank:before {
  content: "";
}

.fa-square-parking:before, .fa-parking:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-bars-progress:before, .fa-tasks-alt:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-cart-flatbed:before, .fa-dolly-flatbed:before {
  content: "";
}

.fa-ban-smoking:before, .fa-smoking-ban:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-mobile-button:before {
  content: "";
}

.fa-house-medical-flag:before {
  content: "";
}

.fa-basket-shopping:before, .fa-shopping-basket:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-bus-simple:before, .fa-bus-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-face-sad-cry:before, .fa-sad-cry:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-person-military-to-person:before {
  content: "";
}

.fa-file-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-tower-observation:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-signal:before, .fa-signal-5:before, .fa-signal-perfect:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-heart-circle-xmark:before {
  content: "";
}

.fa-house-chimney:before, .fa-home-lg:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-face-frown:before, .fa-frown:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-shop:before, .fa-store-alt:before {
  content: "";
}

.fa-floppy-disk:before, .fa-save:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-scale-unbalanced:before, .fa-balance-scale-left:before {
  content: "";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "";
}

.fa-comment-dots:before, .fa-commenting:before {
  content: "";
}

.fa-plant-wilt:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-face-grin-squint:before, .fa-grin-squint:before {
  content: "";
}

.fa-hand-holding-dollar:before, .fa-hand-holding-usd:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hands-praying:before, .fa-praying-hands:before {
  content: "";
}

.fa-arrow-rotate-right:before, .fa-arrow-right-rotate:before, .fa-arrow-rotate-forward:before, .fa-redo:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-location-crosshairs:before, .fa-location:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-child-dress:before {
  content: "";
}

.fa-users-between-lines:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-face-grin-tears:before, .fa-grin-tears:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-calendar-xmark:before, .fa-calendar-times:before {
  content: "";
}

.fa-child-reaching:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-user-gear:before, .fa-user-cog:before {
  content: "";
}

.fa-arrow-up-1-9:before, .fa-sort-numeric-up:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-mosquito-net:before {
  content: "";
}

.fa-bridge-water:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-person-digging:before, .fa-digging:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-gauge-simple:before, .fa-gauge-simple-med:before, .fa-tachometer-average:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-quote-right:before, .fa-quote-right-alt:before {
  content: "";
}

.fa-shirt:before, .fa-t-shirt:before, .fa-tshirt:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-tenge-sign:before, .fa-tenge:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-hands-holding:before {
  content: "";
}

.fa-hands-clapping:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-person-circle-xmark:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-j:before {
  content: "J";
}

.fa-users-viewfinder:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-up-right-from-square:before, .fa-external-link-alt:before {
  content: "";
}

.fa-table-cells:before, .fa-th:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-book-bible:before, .fa-bible:before {
  content: "";
}

.fa-o:before {
  content: "O";
}

.fa-suitcase-medical:before, .fa-medkit:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-person-dress:before, .fa-female:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-business-time:before, .fa-briefcase-clock:before {
  content: "";
}

.fa-table-cells-large:before, .fa-th-large:before {
  content: "";
}

.fa-book-tanakh:before, .fa-tanakh:before {
  content: "";
}

.fa-phone-volume:before, .fa-volume-control-phone:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-plane-lock:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-cake-candles:before, .fa-birthday-cake:before, .fa-cake:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-angles-up:before, .fa-angle-double-up:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-arrow-right-to-city:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-arrow-up-9-1:before, .fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-litecoin-sign:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-circle-nodes:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-truck-field-un:before {
  content: "";
}

.fa-hourglass:before, .fa-hourglass-empty:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-user-doctor:before, .fa-user-md:before {
  content: "";
}

.fa-circle-info:before, .fa-info-circle:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-camera:before, .fa-camera-alt:before {
  content: "";
}

.fa-square-virus:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-car-on:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-arrow-down-1-9:before, .fa-sort-numeric-asc:before, .fa-sort-numeric-down:before {
  content: "";
}

.fa-hand-holding-droplet:before, .fa-hand-holding-water:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-prescription-bottle-medical:before, .fa-prescription-bottle-alt:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-person-cane:before {
  content: "";
}

.fa-tent:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-arrow-down-a-z:before, .fa-sort-alpha-asc:before, .fa-sort-alpha-down:before {
  content: "";
}

.fa-money-bill-wheat:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-arrow-rotate-left:before, .fa-arrow-left-rotate:before, .fa-arrow-rotate-back:before, .fa-arrow-rotate-backward:before, .fa-undo:before {
  content: "";
}

.fa-hard-drive:before, .fa-hdd:before {
  content: "";
}

.fa-face-grin-squint-tears:before, .fa-grin-squint-tears:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-rectangle-list:before, .fa-list-alt:before {
  content: "";
}

.fa-tarp-droplet:before {
  content: "";
}

.fa-house-medical-circle-check:before {
  content: "";
}

.fa-person-skiing-nordic:before, .fa-skiing-nordic:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-circle-left:before, .fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-train-subway:before, .fa-subway:before {
  content: "";
}

.fa-chart-gantt:before {
  content: "";
}

.fa-indian-rupee-sign:before, .fa-indian-rupee:before, .fa-inr:before {
  content: "";
}

.fa-crop-simple:before, .fa-crop-alt:before {
  content: "";
}

.fa-money-bill-1:before, .fa-money-bill-alt:before {
  content: "";
}

.fa-left-long:before, .fa-long-arrow-alt-left:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-minus:before, .fa-subtract:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-arrow-left-long:before, .fa-long-arrow-left:before {
  content: "";
}

.fa-plug-circle-check:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-franc-sign:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-hands-asl-interpreting:before, .fa-american-sign-language-interpreting:before, .fa-asl-interpreting:before, .fa-hands-american-sign-language-interpreting:before {
  content: "";
}

.fa-gear:before, .fa-cog:before {
  content: "";
}

.fa-droplet-slash:before, .fa-tint-slash:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-mosquito:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-person-military-rifle:before {
  content: "";
}

.fa-cart-shopping:before, .fa-shopping-cart:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-plug-circle-plus:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-arrow-turn-up:before, .fa-level-up:before {
  content: "";
}

.fa-u:before {
  content: "U";
}

.fa-square-root-variable:before, .fa-square-root-alt:before {
  content: "";
}

.fa-clock:before, .fa-clock-four:before {
  content: "";
}

.fa-backward-step:before, .fa-step-backward:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-baseball-bat-ball:before {
  content: "";
}

.fa-s:before {
  content: "S";
}

.fa-timeline:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-house-chimney-medical:before, .fa-clinic-medical:before {
  content: "";
}

.fa-temperature-three-quarters:before, .fa-temperature-3:before, .fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-mobile-screen:before, .fa-mobile-android-alt:before {
  content: "";
}

.fa-plane-up:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-battery-half:before, .fa-battery-3:before {
  content: "";
}

.fa-mountain-city:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-sliders:before, .fa-sliders-h:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-cent-sign:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-person-pregnant:before {
  content: "";
}

.fa-wand-sparkles:before {
  content: "";
}

.fa-ellipsis-vertical:before, .fa-ellipsis-v:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-right-long:before, .fa-long-arrow-alt-right:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-laptop-file:before {
  content: "";
}

.fa-tty:before, .fa-teletype:before {
  content: "";
}

.fa-diagram-next:before {
  content: "";
}

.fa-person-rifle:before {
  content: "";
}

.fa-house-medical-circle-exclamation:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-person-hiking:before, .fa-hiking:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-people-pulling:before {
  content: "";
}

.fa-n:before {
  content: "N";
}

.fa-cable-car:before, .fa-tram:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-building-circle-xmark:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-arrows-down-to-line:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-face-grin:before, .fa-grin:before {
  content: "";
}

.fa-delete-left:before, .fa-backspace:before {
  content: "";
}

.fa-eye-dropper:before, .fa-eye-dropper-empty:before, .fa-eyedropper:before {
  content: "";
}

.fa-file-circle-check:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-mobile:before, .fa-mobile-android:before, .fa-mobile-phone:before {
  content: "";
}

.fa-face-meh:before, .fa-meh:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-book-skull:before, .fa-book-dead:before {
  content: "";
}

.fa-id-card:before, .fa-drivers-license:before {
  content: "";
}

.fa-outdent:before, .fa-dedent:before {
  content: "";
}

.fa-heart-circle-exclamation:before {
  content: "";
}

.fa-house:before, .fa-home:before, .fa-home-alt:before, .fa-home-lg-alt:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-b:before {
  content: "B";
}

.fa-file-medical:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-arrow-right-arrow-left:before, .fa-exchange:before {
  content: "";
}

.fa-rotate-right:before, .fa-redo-alt:before, .fa-rotate-forward:before {
  content: "";
}

.fa-utensils:before, .fa-cutlery:before {
  content: "";
}

.fa-arrow-up-wide-short:before, .fa-sort-amount-up:before {
  content: "";
}

.fa-mill-sign:before {
  content: "";
}

.fa-bowl-rice:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-tower-broadcast:before, .fa-broadcast-tower:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-up-long:before, .fa-long-arrow-alt-up:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-mound:before {
  content: "";
}

.fa-toilet-portable:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-file-arrow-down:before, .fa-file-download:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-shield-cat:before {
  content: "";
}

.fa-bolt:before, .fa-zap:before {
  content: "";
}

.fa-glass-water:before {
  content: "";
}

.fa-oil-well:before {
  content: "";
}

.fa-vault:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-plane-circle-xmark:before {
  content: "";
}

.fa-yen-sign:before, .fa-cny:before, .fa-jpy:before, .fa-rmb:before, .fa-yen:before {
  content: "";
}

.fa-ruble-sign:before, .fa-rouble:before, .fa-rub:before, .fa-ruble:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-face-laugh-wink:before, .fa-laugh-wink:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-bore-hole:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-circle-down:before, .fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrows-turn-to-dots:before {
  content: "";
}

.fa-florin-sign:before {
  content: "";
}

.fa-arrow-down-short-wide:before, .fa-sort-amount-desc:before, .fa-sort-amount-down-alt:before {
  content: "";
}

.fa-less-than:before {
  content: "<";
}

.fa-angle-down:before {
  content: "";
}

.fa-car-tunnel:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-arrow-right-long:before, .fa-long-arrow-right:before {
  content: "";
}

.fa-anchor-circle-xmark:before {
  content: "";
}

.fa-ellipsis:before, .fa-ellipsis-h:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-kit-medical:before, .fa-first-aid:before {
  content: "";
}

.fa-person-through-window:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-hands-holding-circle:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-credit-card:before, .fa-credit-card-alt:before {
  content: "";
}

.fa-car:before, .fa-automobile:before {
  content: "";
}

.fa-hand-holding-hand:before {
  content: "";
}

.fa-book-open-reader:before, .fa-book-reader:before {
  content: "";
}

.fa-mountain-sun:before {
  content: "";
}

.fa-arrows-left-right-to-line:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-truck-droplet:before {
  content: "";
}

.fa-file-circle-xmark:before {
  content: "";
}

.fa-temperature-arrow-up:before, .fa-temperature-up:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-square-h:before, .fa-h-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-temperature-full:before, .fa-temperature-4:before, .fa-thermometer-4:before, .fa-thermometer-full:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-plug-circle-xmark:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-handshake-angle:before, .fa-hands-helping:before {
  content: "";
}

.fa-location-dot:before, .fa-map-marker-alt:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-greater-than:before {
  content: ">";
}

.fa-person-swimming:before, .fa-swimmer:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-droplet:before, .fa-tint:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-earth-americas:before, .fa-earth:before, .fa-earth-america:before, .fa-globe-americas:before {
  content: "";
}

.fa-person-burst:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-battery-empty:before, .fa-battery-0:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-section:before {
  content: "";
}

.fa-gauge-high:before, .fa-tachometer-alt:before, .fa-tachometer-alt-fast:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-hospital:before, .fa-hospital-alt:before, .fa-hospital-wide:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-bars-staggered:before, .fa-reorder:before, .fa-stream:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-person-walking-with-cane:before, .fa-blind:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-heart-circle-bolt:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-check-to-slot:before, .fa-vote-yea:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-boxes-stacked:before, .fa-boxes:before, .fa-boxes-alt:before {
  content: "";
}

.fa-link:before, .fa-chain:before {
  content: "";
}

.fa-ear-listen:before, .fa-assistive-listening-systems:before {
  content: "";
}

.fa-tree-city:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-table-cells-row-lock:before {
  content: "";
}

.fa-rupiah-sign:before {
  content: "";
}

.fa-magnifying-glass:before, .fa-search:before {
  content: "";
}

.fa-table-tennis-paddle-ball:before, .fa-ping-pong-paddle-ball:before, .fa-table-tennis:before {
  content: "";
}

.fa-person-dots-from-line:before, .fa-diagnoses:before {
  content: "";
}

.fa-trash-can-arrow-up:before, .fa-trash-restore-alt:before {
  content: "";
}

.fa-naira-sign:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-file-pen:before, .fa-file-edit:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-square-pen:before, .fa-pen-square:before, .fa-pencil-square:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-person-circle-exclamation:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-battery-full:before, .fa-battery:before, .fa-battery-5:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-code-compare:before {
  content: "";
}

.fa-list-ul:before, .fa-list-dots:before {
  content: "";
}

.fa-school-lock:before {
  content: "";
}

.fa-tower-cell:before {
  content: "";
}

.fa-down-long:before, .fa-long-arrow-alt-down:before {
  content: "";
}

.fa-ranking-star:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-person-harassing:before {
  content: "";
}

.fa-brazilian-real-sign:before {
  content: "";
}

.fa-landmark-dome:before, .fa-landmark-alt:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-tv:before, .fa-television:before, .fa-tv-alt:before {
  content: "";
}

.fa-shrimp:before {
  content: "";
}

.fa-list-check:before, .fa-tasks:before {
  content: "";
}

.fa-jug-detergent:before {
  content: "";
}

.fa-circle-user:before, .fa-user-circle:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-car-burst:before, .fa-car-crash:before {
  content: "";
}

.fa-y:before {
  content: "Y";
}

.fa-person-snowboarding:before, .fa-snowboarding:before {
  content: "";
}

.fa-truck-fast:before, .fa-shipping-fast:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-circle-half-stroke:before, .fa-adjust:before {
  content: "";
}

.fa-clapperboard:before {
  content: "";
}

.fa-circle-radiation:before, .fa-radiation-alt:before {
  content: "";
}

.fa-baseball:before, .fa-baseball-ball:before {
  content: "";
}

.fa-jet-fighter-up:before {
  content: "";
}

.fa-diagram-project:before, .fa-project-diagram:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-volume-xmark:before, .fa-volume-mute:before, .fa-volume-times:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-grip:before, .fa-grip-horizontal:before {
  content: "";
}

.fa-share-from-square:before, .fa-share-square:before {
  content: "";
}

.fa-child-combatant:before, .fa-child-rifle:before {
  content: "";
}

.fa-gun:before {
  content: "";
}

.fa-square-phone:before, .fa-phone-square:before {
  content: "";
}

.fa-plus:before, .fa-add:before {
  content: "+";
}

.fa-expand:before {
  content: "";
}

.fa-computer:before {
  content: "";
}

.fa-xmark:before, .fa-close:before, .fa-multiply:before, .fa-remove:before, .fa-times:before {
  content: "";
}

.fa-arrows-up-down-left-right:before, .fa-arrows:before {
  content: "";
}

.fa-chalkboard-user:before, .fa-chalkboard-teacher:before {
  content: "";
}

.fa-peso-sign:before {
  content: "";
}

.fa-building-shield:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-users-line:before {
  content: "";
}

.fa-quote-left:before, .fa-quote-left-alt:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trash-arrow-up:before, .fa-trash-restore:before {
  content: "";
}

.fa-arrow-down-up-lock:before {
  content: "";
}

.fa-lines-leaning:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-equals:before {
  content: "=";
}

.fa-blender:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-shekel-sign:before, .fa-ils:before, .fa-shekel:before, .fa-sheqel:before, .fa-sheqel-sign:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-photo-film:before, .fa-photo-video:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-arrow-trend-up:before {
  content: "";
}

.fa-plug-circle-minus:before {
  content: "";
}

.fa-sign-hanging:before, .fa-sign:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-tablet:before, .fa-tablet-android:before {
  content: "";
}

.fa-school-flag:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-notdef:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-car-rear:before, .fa-car-alt:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-battery-quarter:before, .fa-battery-2:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-baby-carriage:before, .fa-carriage-baby:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-percent:before, .fa-percentage:before {
  content: "%";
}

.fa-truck-moving:before {
  content: "";
}

.fa-glass-water-droplet:before {
  content: "";
}

.fa-display:before {
  content: "";
}

.fa-face-smile:before, .fa-smile:before {
  content: "";
}

.fa-thumbtack:before, .fa-thumb-tack:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-person-praying:before, .fa-pray:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-rotate:before, .fa-sync-alt:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-gears:before, .fa-cogs:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-arrow-up-right-dots:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-face-grin-hearts:before, .fa-grin-hearts:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-transgender:before, .fa-transgender-alt:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-arrow-turn-down:before, .fa-level-down:before {
  content: "";
}

.fa-person-falling-burst:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-ticket-simple:before, .fa-ticket-alt:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-angles-left:before, .fa-angle-double-left:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-clock-rotate-left:before, .fa-history:before {
  content: "";
}

.fa-face-grin-beam-sweat:before, .fa-grin-beam-sweat:before {
  content: "";
}

.fa-file-export:before, .fa-arrow-right-from-file:before {
  content: "";
}

.fa-shield:before, .fa-shield-blank:before {
  content: "";
}

.fa-arrow-up-short-wide:before, .fa-sort-amount-up-alt:before {
  content: "";
}

.fa-house-medical:before {
  content: "";
}

.fa-golf-ball-tee:before, .fa-golf-ball:before {
  content: "";
}

.fa-circle-chevron-left:before, .fa-chevron-circle-left:before {
  content: "";
}

.fa-house-chimney-window:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-tent-arrow-turn-left:before {
  content: "";
}

.fa-tents:before {
  content: "";
}

.fa-wand-magic:before, .fa-magic:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-wine-glass-empty:before, .fa-wine-glass-alt:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-person-biking:before, .fa-biking:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-shield-heart:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-earth-oceania:before, .fa-globe-oceania:before {
  content: "";
}

.fa-square-xmark:before, .fa-times-square:before, .fa-xmark-square:before {
  content: "";
}

.fa-hashtag:before {
  content: "#";
}

.fa-up-right-and-down-left-from-center:before, .fa-expand-alt:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-t:before {
  content: "T";
}

.fa-hippo:before {
  content: "";
}

.fa-chart-column:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-vial-circle-check:before {
  content: "";
}

.fa-person-arrow-down-to-line:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-person-walking-luggage:before {
  content: "";
}

.fa-up-down:before, .fa-arrows-alt-v:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-bahai:before, .fa-haykal:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-circle-plus:before, .fa-plus-circle:before {
  content: "";
}

.fa-face-grin-tongue-wink:before, .fa-grin-tongue-wink:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-plug-circle-exclamation:before {
  content: "";
}

.fa-link-slash:before, .fa-chain-broken:before, .fa-chain-slash:before, .fa-unlink:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-person-walking-arrow-loop-left:before {
  content: "";
}

.fa-arrow-up-z-a:before, .fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-fire-flame-curved:before, .fa-fire-alt:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-file-circle-plus:before {
  content: "";
}

.fa-book-quran:before, .fa-quran:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-face-angry:before, .fa-angry:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-arrow-trend-down:before {
  content: "";
}

.fa-rss:before, .fa-feed:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-scale-balanced:before, .fa-balance-scale:before {
  content: "";
}

.fa-gauge-simple-high:before, .fa-tachometer:before, .fa-tachometer-fast:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-desktop:before, .fa-desktop-alt:before {
  content: "";
}

.fa-m:before {
  content: "M";
}

.fa-table-list:before, .fa-th-list:before {
  content: "";
}

.fa-comment-sms:before, .fa-sms:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-battery-three-quarters:before, .fa-battery-4:before {
  content: "";
}

.fa-house-circle-check:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-diagram-successor:before {
  content: "";
}

.fa-truck-arrow-right:before {
  content: "";
}

.fa-arrows-split-up-and-left:before {
  content: "";
}

.fa-hand-fist:before, .fa-fist-raised:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-person-falling:before {
  content: "";
}

.fa-image-portrait:before, .fa-portrait:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-rug:before {
  content: "";
}

.fa-earth-europe:before, .fa-globe-europe:before {
  content: "";
}

.fa-cart-flatbed-suitcase:before, .fa-luggage-cart:before {
  content: "";
}

.fa-rectangle-xmark:before, .fa-rectangle-times:before, .fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-baht-sign:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-journal-whills:before, .fa-journal-whills:before {
  content: "";
}

.fa-handcuffs:before {
  content: "";
}

.fa-triangle-exclamation:before, .fa-exclamation-triangle:before, .fa-warning:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-share:before, .fa-mail-forward:before {
  content: "";
}

.fa-bottle-droplet:before {
  content: "";
}

.fa-mask-face:before {
  content: "";
}

.fa-hill-rockslide:before {
  content: "";
}

.fa-right-left:before, .fa-exchange-alt:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-road-circle-exclamation:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-money-bill-1-wave:before, .fa-money-bill-wave-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-hands:before, .fa-sign-language:before, .fa-signing:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-water-ladder:before, .fa-ladder-water:before, .fa-swimming-pool:before {
  content: "";
}

.fa-arrows-up-down:before, .fa-arrows-v:before {
  content: "";
}

.fa-face-grimace:before, .fa-grimace:before {
  content: "";
}

.fa-wheelchair-move:before, .fa-wheelchair-alt:before {
  content: "";
}

.fa-turn-down:before, .fa-level-down-alt:before {
  content: "";
}

.fa-person-walking-arrow-right:before {
  content: "";
}

.fa-square-envelope:before, .fa-envelope-square:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bandage:before, .fa-band-aid:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-circle-xmark:before, .fa-times-circle:before, .fa-xmark-circle:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-earth-asia:before, .fa-globe-asia:before {
  content: "";
}

.fa-id-card-clip:before, .fa-id-card-alt:before {
  content: "";
}

.fa-magnifying-glass-plus:before, .fa-search-plus:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-hand-dots:before, .fa-allergies:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-mug-saucer:before, .fa-coffee:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-magnifying-glass-minus:before, .fa-search-minus:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-user-large:before, .fa-user-alt:before {
  content: "";
}

.fa-train-tram:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-jar:before {
  content: "";
}

.fa-note-sticky:before, .fa-sticky-note:before {
  content: "";
}

.fa-bug-slash:before {
  content: "";
}

.fa-arrow-up-from-water-pump:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-face-sad-tear:before, .fa-sad-tear:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-tent-arrows-down:before {
  content: "";
}

.fa-exclamation:before {
  content: "!";
}

.fa-arrows-spin:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-turkish-lira-sign:before, .fa-try:before, .fa-turkish-lira:before {
  content: "";
}

.fa-dollar-sign:before, .fa-dollar:before, .fa-usd:before {
  content: "$";
}

.fa-x:before {
  content: "X";
}

.fa-magnifying-glass-dollar:before, .fa-search-dollar:before {
  content: "";
}

.fa-users-gear:before, .fa-users-cog:before {
  content: "";
}

.fa-person-military-pointing:before {
  content: "";
}

.fa-building-columns:before, .fa-bank:before, .fa-institution:before, .fa-museum:before, .fa-university:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-trowel:before {
  content: "";
}

.fa-d:before {
  content: "D";
}

.fa-stapler:before {
  content: "";
}

.fa-masks-theater:before, .fa-theater-masks:before {
  content: "";
}

.fa-kip-sign:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-handshake-simple:before, .fa-handshake-alt:before {
  content: "";
}

.fa-jet-fighter:before, .fa-fighter-jet:before {
  content: "";
}

.fa-square-share-nodes:before, .fa-share-alt-square:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-plus-minus:before {
  content: "";
}

.fa-video:before, .fa-video-camera:before {
  content: "";
}

.fa-graduation-cap:before, .fa-mortar-board:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-person-circle-check:before {
  content: "";
}

.fa-turn-up:before, .fa-level-up-alt:before {
  content: "";
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.f98d5910.woff2") format("woff2"), url("fa-solid-900.6ccff4a6.ttf") format("truetype");
}

.fas, .fa-solid {
  font-weight: 900;
}

:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.04afe7a1.woff2") format("woff2"), url("fa-brands-400.c5c61dc7.ttf") format("truetype");
}

.fab, .fa-brands {
  font-weight: 400;
}

.fa-monero:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-pixiv:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-square-js:before, .fa-js-square:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-jxl:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-square-reddit:before, .fa-reddit-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-square-font-awesome:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-brave:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-square-instagram:before, .fa-instagram-square:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-square-hacker-news:before, .fa-hacker-news-square:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-threads:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-square-snapchat:before, .fa-snapchat-square:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-square-font-awesome-stroke:before, .fa-font-awesome-alt:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-square-viadeo:before, .fa-viadeo-square:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-opensuse:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-square-dribbble:before, .fa-dribbble-square:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-debian:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-square-twitter:before, .fa-twitter-square:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-golang:before {
  content: "";
}

.fa-kickstarter:before, .fa-square-kickstarter:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-square-youtube:before, .fa-youtube-square:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wpressr:before, .fa-rendact:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-nfc-directional:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-meta:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-square-letterboxd:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-shoelace:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-square-steam:before, .fa-steam-square:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-square-vimeo:before, .fa-vimeo-square:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-font-awesome:before, .fa-font-awesome-flag:before, .fa-font-awesome-logo-full:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-padlet:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-square-github:before, .fa-github-square:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-square-threads:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-google-scholar:before {
  content: "";
}

.fa-square-gitlab:before, .fa-gitlab-square:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-square-odnoklassniki:before, .fa-odnoklassniki-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-hashnode:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-square-pinterest:before, .fa-pinterest-square:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-signal-messenger:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-nfc-symbol:before {
  content: "";
}

.fa-mintbit:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-brave-reverse:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-square-google-plus:before, .fa-google-plus-square:before {
  content: "";
}

.fa-web-awesome:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-square-xing:before, .fa-xing-square:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-letterboxd:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-bilibili:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-x-twitter:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-42-group:before, .fa-innosoft:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-square-pied-piper:before, .fa-pied-piper-square:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-square-web-awesome-stroke:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-square-facebook:before, .fa-facebook-square:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-square-lastfm:before, .fa-lastfm-square:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-cmplid:before {
  content: "";
}

.fa-upwork:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-square-upwork:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-screenpal:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-square-web-awesome:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-wirsindhandwerk:before, .fa-wsh:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-bluesky:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-snapchat:before, .fa-snapchat-ghost:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-square-behance:before, .fa-behance-square:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-webflow:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-space-awesome:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-square-git:before, .fa-git-square:before {
  content: "";
}

.fa-square-tumblr:before, .fa-tumblr-square:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-bots:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-stubber:before {
  content: "";
}

.fa-telegram:before, .fa-telegram-plane:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-odysee:before {
  content: "";
}

.fa-square-whatsapp:before, .fa-whatsapp-square:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-slack:before, .fa-slack-hash:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-square-x-twitter:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-medium:before, .fa-medium-m:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-sitrox:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-pix:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

footer {
  margin-top: auto;
}

html {
  scroll-behavior: smooth;
}

.custom-menu, .custom-menu a:link, .custom-menu a:visited {
  color: #fff;
}

.custom-menu a:hover {
  color: #b5b5b5;
}

.custom-menu a:active {
  color: #fff;
}

.nice-list {
  list-style-position: inside;
}

.council-extended {
  background-image: image-set("beige-strip.044c0c96.jpeg" 1x);
  background-position: center;
  background-size: cover;
}

.contact-us {
  background-image: image-set("green-strip.ce7c9dde.jpeg" 1x);
  background-position: center;
  background-size: cover;
}

.fullpage {
  min-height: 72vh;
}

.rempad {
  padding: 0;
}

.sheikh-pic {
  flex: none;
  align-items: center;
  display: flex;
}

.villages {
  background-image: image-set("bg-villages.9d2ec94c.jpeg" 1x);
  background-position: top;
  background-size: cover;
}

@media screen and (width <= 1023px) {
  .villages {
    padding-right: 3rem;
    font-size: 2em;
  }
}

.villages-logo {
  width: 50%;
  height: 50%;
}

@media screen and (width <= 1023px) {
  .villages-logo {
    width: 90%;
    height: 90%;
  }
}

.objectives {
  background-image: image-set("brown-strip.255a3483.jpeg" 1x);
  background-position: center;
  background-size: cover;
}

@media screen and (width <= 1023px) {
  .objectives {
    padding-right: 3rem;
    font-size: 2em;
  }
}

.objectives-item {
  border-right: 1px solid #fff;
}

@media screen and (width <= 1023px) {
  .objectives-item {
    border-right: 3px solid #fff;
    margin-top: 20px;
    padding-right: 3rem;
  }
}

.council {
  background-image: image-set("bg-generic.e506c465.jpeg" 1x);
  background-position: center;
  background-size: cover;
}

@media screen and (width <= 1023px) {
  .council {
    padding-right: 3rem;
    font-size: 2em;
  }
}

.fields {
  background-color: #755d4a80;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
}

@media screen and (width <= 1023px) {
  .fields {
    padding-left: 3rem;
    font-size: 2em;
  }
}
/*# sourceMappingURL=index.8815a020.css.map */
