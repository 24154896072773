@charset "utf-8";

@import "./custom.scss";

@import "../node_modules/bulma/bulma.sass";
@import "./common.scss";
.villages {
    background-image:
      image-set(url("./images/bg-villages.jpg"));
    background-position: top center;
    background-size: cover;
  
    @include touch {
      font-size: 2em;
      padding-right: 3rem;
    }
  }
  
  .villages-logo {
    width: 50%;
    height: 50%;
  
    @include touch {
      width: 90%;
      height: 90%;
    }
  }
  
  // Objectives
  .objectives {
    background-image:
      image-set(url("./images/brown-strip.jpg"));
    background-position: center center;
    background-size: cover;
  
    @include touch {
      font-size: 2em;
      padding-right: 3rem;
    }
  }
  
  .objectives-item {
    border-right: 1px solid white;
  
    @include touch {
      border-right: 3px solid white;
      margin-top: 20px;
      padding-right: 3rem;
    }
  }
  
  .council {
    background-image:
      image-set(url("./images/bg-generic.jpg"));
    background-position: center center;
    background-size: cover;
  
    @include touch {
      font-size: 2em;
      padding-right: 3rem;
    }
  }
  
  .fields {
    background-color: rgba($color: #755d4a, $alpha: 0.5);
    background-position: center center;
    background-size: cover;
    border-radius: 8px;

    @include touch {
      font-size: 2em;
      padding-left: 3rem;
    }
  }
