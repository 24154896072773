// Initial variables
$body-size: 20px;

$navbar-height: 6rem;
$navbar-item-img-max-height: 4rem;
$black: #000;
$footer-background-color: $black;
$footer-color: white;

//Fonts

@font-face {
    font-family: "Favorit";
    src: url("/src/fonts/favorit/ABCFavoritArabicVariable.woff2") format("woff2"), url("/src/fonts/favorit/ABCFavoritArabicVariable.woff") format("woff");
}

$family-serif: "Favorit",
serif;
$family-primary: $family-serif;