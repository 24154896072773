// Font Awesome
$fa-font-path: "/node_modules/@fortawesome/fontawesome-free/webfonts";
// importing core styling file
@import "/node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";

// our project needs Solid + Brands
@import "/node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "/node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

footer {
  margin-top: auto;
}

html {
  scroll-behavior: smooth;
}

.custom-menu {
  color: white;

  /* unvisited link */
  a:link {
    color: white;
  }

  /* visited link */
  a:visited {
    color: white;
  }

  /* mouse over link */
  a:hover {
    color: $grey-light;
  }

  /* selected link */
  a:active {
    color: white;
  }
}

.nice-list {
  list-style-position: inside;
}


.council-extended {
  background-image: image-set(url("/src/images/beige-strip.jpg"));
  background-position: center center;
  background-size: cover;
}

.contact-us {
  background-image:
    image-set(url("/src/images/green-strip.jpg"));
  background-position: center center;
  background-size: cover;
}

.fullpage {
  min-height: 72vh;
}

.rempad {
  padding: 0;
}

.sheikh-pic {
  display: flex;
  flex: none;
  align-items: center;
}